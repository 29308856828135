
import ArrowForward from '@/components/icons/ArrowForward.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CardButton.vue',
  components: { ArrowForward },
  props: {
    btnText: String,
    home: { type: Boolean, default: true }
  }
})
