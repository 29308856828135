import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-10 body-2" }
const _hoisted_2 = { class: "w-full sm:w-1/2 lg:w-1/3 m-auto" }
const _hoisted_3 = {
  key: 0,
  class: "p-4 text-left text-text-alt"
}
const _hoisted_4 = {
  key: 1,
  class: "p-4 text-right text-text-prim font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.characteristics, (characteristic) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: characteristic,
          class: "border-b border-bg-3"
        }, [
          characteristic
            ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(characteristic.name), 1))
            : _createCommentVNode("", true),
          characteristic
            ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(characteristic.value), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}