
import { defineComponent } from 'vue'
import Star from '@/components/icons/Star.vue'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'
import manageFavourite from '@/core/composables/favourite.composable'

export default defineComponent({
  name: 'FavouriteButton',
  components: { Star, LoaderIcon },
  props: {
    isFav: Boolean,
    strokeFav: String,
    fillFav: String,
    stroke: String,
    fill: String,
    loading: Boolean
  },
  setup (props) {
    const manageFav = manageFavourite(0, 0, false, !props.isFav)
    const changeFavStatus = manageFav.emitFavourite
    return { changeFavStatus }
  }
})
