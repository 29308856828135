import { CategoriesListModel } from '@/core/model/category.model'
import { ServiceModel } from '@/core/model/service.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  list (): Promise<ApiResult<CategoriesListModel>> {
    return http.request<CategoriesListModel>('get', 'Services')
      .then((rep: ApiResult<CategoriesListModel>) => rep)
      .catch(err => err)
  },
  detail (id: number): Promise<ApiResult<ServiceModel>> {
    return http.request<ServiceModel>('get', `Services/${id}`)
      .then((rep: ApiResult<ServiceModel>) => rep)
      .catch(err => err)
  }
}
