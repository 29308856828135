
import ListReportByService from '@/components/reports/categories/services/ServiceList.Report.vue'
import { ReportServiceCategory } from '@/core/model/report.model'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    ListReportByService
  },
  props: {
    category: { type: Object as PropType<ReportServiceCategory>, required: true },
    assets: { type: Array as PropType<any> },
    isInReports: { type: Boolean, default: false }
  },
  methods: {
    requestUpload () {
      console.log('requestUpload')
    }
  }
})
