import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "14",
  viewBox: "0 0 14 14"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "_029-chevron",
      "data-name": "029-chevron",
      d: "M7,14A7,7,0,0,1,2.05,2.05a7,7,0,0,1,9.9,9.9A6.954,6.954,0,0,1,7,14ZM7,1.094A5.906,5.906,0,1,0,12.906,7,5.913,5.913,0,0,0,7,1.094ZM10.781,7.93,7,4.148,3.219,7.93l.773.773L7,5.7,10.008,8.7Zm0,0",
      fill: _ctx.btt ? '#E3E8F3' : 'currentColor'
    }, null, 8, _hoisted_2)
  ]))
}