
import { defineComponent, inject } from 'vue'
import { IEntryManager } from '@/core/managers/entry/entryManager'
import { IEntry } from '@/core/managers/entry/entryModel'

export default defineComponent({
  name: 'periodExpand',
  props: {
    entryId: {
      type: Number,
      required: true
    }

  },
  setup () {
    const controller = new AbortController()
    const entryManager = inject('entryManager') as IEntryManager
    return { entryManager, controller }
  },
  data () {
    return { entry: {} as IEntry, loading: false }
  },
  async mounted () {
    this.loading = true
    this.entry = this.entryManager.init
    try {
      this.entry = await this.entryManager.find(this.$route.params.contractId.toString(), this.$route.params.assetId.toString(), this.entryId.toString(), this.controller.signal)
    } catch (e) {
      console.warn(e)
    } finally {
      this.loading = false
    }
  },
  beforeUnmount () {
    this.controller.abort()
  }
})
