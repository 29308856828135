import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-6 pb-4 mb-4" }
const _hoisted_3 = { class: "-mx-6 border-b mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportGroupHeader = _resolveComponent("ReportGroupHeader")!
  const _component_AssetReport = _resolveComponent("AssetReport")!
  const _component_ServiceReport = _resolveComponent("ServiceReport")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_ctx.service.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.groups, (report, j) => {
          return (_openBlock(), _createBlock(_component_GenericCardShell, {
            key: j,
            class: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ReportGroupHeader, {
                    title: report.title,
                    subtitle: _ctx.subtitle,
                    onRequestUpload: ($event: any) => (report.showForm = true),
                    id: report.id
                  }, null, 8, ["title", "subtitle", "onRequestUpload", "id"])
                ]),
                (report.isAssetRelated)
                  ? (_openBlock(), _createBlock(_component_AssetReport, {
                      key: 0,
                      "report-title": report.title,
                      id: report.id,
                      assets: _ctx.assets,
                      isInReports: _ctx.isInReports,
                      showForm: report.showForm,
                      onCloseForm: ($event: any) => (report.showForm = false)
                    }, null, 8, ["report-title", "id", "assets", "isInReports", "showForm", "onCloseForm"]))
                  : (_openBlock(), _createBlock(_component_ServiceReport, {
                      key: 1,
                      id: report.id,
                      "report-title": report.title,
                      showForm: report.showForm,
                      onCloseForm: ($event: any) => (report.showForm = false)
                    }, null, 8, ["id", "report-title", "showForm", "onCloseForm"]))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}