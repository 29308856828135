
import { defineComponent, PropType } from 'vue'
import { MapKpiModel } from '@/map/core/bll/kpi/kpi.model'
import ServicesCardPreview from '@/components/cards/ServicesCardPreview.vue'
export default defineComponent({
  components: {
    ServicesCardPreview
  },
  props: {
    current: Number,
    assetId: Number,
    assetFilter: String,
    periodFilter: String,
    direction: String,
    sources: {
      type: Array as PropType<MapKpiModel[]>,
      require: true
    }
  }
})
