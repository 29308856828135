
import ServicesCardChart from '@/components/cards/ServicesCardChart.vue'
import ServicesCardKpiShell from '@/components/cards/ServicesCardKpiShell.vue'
import ServicesCardPreview from '@/components/cards/ServicesCardPreview.vue'
import ServicesCardFleet from '@/components/cards/ServicesCardFleet.vue'
import Observer from '@/components/Observer.vue'
import { ChartModel } from '@/core/model/chart.model'
import { KpiModel } from '@/core/model/kpi.model'
import { Preview } from '@/core/model/preview.model'
import { defineComponent, PropType } from 'vue'

interface ServiceCardContentData {
  kpiDetails: KpiModel | undefined;
  graphDetails: ChartModel | undefined;
  customDetails: string;
  // loading: boolean;
  error: string;
  observed: boolean;
}

export default defineComponent({
  name: 'ServiceCardContent.vue',
  components: {
    Observer,
    ServicesCardFleet,
    ServicesCardPreview,
    ServicesCardKpiShell,
    ServicesCardChart
  },
  props: {
    id: Number,
    title: String,
    description: String,
    subtitle: String,
    previewType: {
      validator: (value: string) =>
        ['Kpi', 'Chart', 'Custom', 'ChartAndKPI'].includes(value)
    },
    previewInfos: [Object, String] as PropType<Preview | string | Preview[]>,
    size: {
      validator: (value: number) => [1, 2].includes(value)
    },
    fromList: { type: Boolean, default: true },
    fromFleet: { type: Boolean, default: false },
    type: String,
    isPreview: Boolean,
    period: String
  },
  data (): ServiceCardContentData {
    return {
      kpiDetails: {} as KpiModel,
      graphDetails: {} as ChartModel,
      customDetails: '',
      // loading: true,
      error: '',
      observed: true
    }
  },
  methods: {
    loadDetails (entry: IntersectionObserverEntry, unobserve: () => void) {
      if (entry.isIntersecting) {
        unobserve()
        this.observed = true
      }
    }
  },
  computed: {
    graphType (): string {
      return this.previewType === 'Chart' && this.previewInfos
        ? (this.previewInfos as Preview).type
        : this.previewType === 'ChartAndKPI' && this.previewInfos
          ? (this.previewInfos as Preview[])[0].type
          : ''
    },
    kpiTitle (): string {
      if (this.previewInfos) {
        return this.previewType === 'Kpi'
          ? (this.previewInfos as Preview).label
          : this.previewType === 'ChartAndKPI'
            ? (this.previewInfos as Preview[])[1].label
            : ''
      }
      return ''
    }
  }
})
