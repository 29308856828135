
import { defineComponent, inject } from 'vue'
import { IPlanningFilterManager } from '@/core/managers/planningFilter/planningFilterManager'
import { IPlanningFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { IPlanningFilterHelper } from '@/core/managers/planningFilter/planningFilterHelper'
import { IEntryManager } from '@/core/managers/entry/entryManager'
import { IEntry } from '@/core/managers/entry/entryModel'
import { IAppStore } from '@/core/appStore'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { Helper } from '@/core/managers/helper'

export default defineComponent({
  name: 'periodDetail',
  emits: [''],
  components: {},
  setup () {
    const controller = new AbortController()
    const appStore = inject('appStore') as IAppStore
    const planningFilterHelper = inject('planningFilterHelper') as IPlanningFilterHelper
    const planningFilterManager = inject('planningFilterManager') as IPlanningFilterManager
    const timeLineHelper = inject('timelineHelper') as ITimeLineHelper
    const entryManager = inject('entryManager') as IEntryManager
    return { planningFilterManager, planningFilterHelper, entryManager, controller, appStore, timeLineHelper }
  },
  data () {
    return { planningFilter: {} as IPlanningFilter, toggleStartTime: false, toggleEndTime: false, item: {} as IEntry, loading: false, pending: false }
  },
  async created () {
    this.loading = true
    try {
      this.planningFilter = this.planningFilterManager.init
      this.planningFilter = await this.planningFilterManager.fetch(this.controller.signal)
      this.item = this.entryManager.init
      const t = await this.entryManager.find(this.$route.params.contractId as string, this.$route.params.assetId as string, this.$route.params.entryId as string, this.controller.signal)
      this.item = Helper.clone(t)
    } finally {
      this.loading = false
    }
  },
  beforeUnmount () {
    this.controller.abort()
  },
  methods: {
    reasonListFilter (list :{id: number, name : string, statusFilter: string}[], isOn:boolean):{id: number, name : string, statusFilter: string}[] {
      const filter = isOn ? 'on' : 'off'
      return this.item.reasonList.filter(x => x.statusFilter.toLowerCase().indexOf(filter) > -1)
    },
    async onSubmit () {
      try {
        this.pending = true
        if (this.$route.params.entryId === 'new') {
          await this.entryManager.post(this.$route.params.contractId as string, this.$route.params.assetId as string, this.item, this.controller.signal)
          this.appStore.action.toaster.show('Create period success', 'green')
        } else {
          await this.entryManager.update(this.$route.params.contractId as string, this.$route.params.assetId as string, this.$route.params.entryId as string, this.item, this.controller.signal)
          this.appStore.action.toaster.show('Update period success', 'green')
        }
        this.$router.push({ name: 'period', params: { contractId: this.$route.params.contractId, assetId: this.$route.params.assetId, periodId: this.$route.params.periodId } })
      } catch (e) {
        console.error(e)
      } finally {
        this.pending = false
      }
    },
    required (value:string | number) {
      return ((value && value !== '-1') || value === 0) || 'Champs obligatoire'
    },
    resetReason () {
      this.item.reasonId = null
    }
  }

})
