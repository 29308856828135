import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8c4a911c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex mr-2 w-5 flex-col h-7 justify-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "body-alt body-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createBlock(_component_GenericCardShell, {
    class: _normalizeClass(["min-h-24 flex relative h-[102px] p-4 w-fit min-w-[14rem]", { 'min-w-[13rem]': _ctx.loading }]),
    style: {"min-width":"14rem"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-3 h-3 rounded-full", { 'skeleton-dot': _ctx.loading }]),
          style: _normalizeStyle(
          !_ctx.loading
            ? `background-color: ${_ctx.getKpiColour(_ctx.title ? _ctx.title : _ctx.label)}`
            : ''
        )
        }, null, 6)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass({ 'w-5/6': _ctx.loading })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["body body-2 text-ellipsis relative table-cell align-middle h-7", [{ 'skeleton w-4/5': _ctx.loading }]])
        }, [
          (
            !_ctx.loading  && (_ctx.title|| _ctx.label)
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title || _ctx.label), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("h4", {
          class: _normalizeClass(["my-1.5", { 'skeleton w-4/5': _ctx.loading }])
        }, [
          (!_ctx.loading && _ctx.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.formatNumber(+_ctx.value)) + " " + _toDisplayString(_ctx.unit), 1))
            : _createCommentVNode("", true),
          (!_ctx.loading && !_ctx.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Unavailable"))
            : _createCommentVNode("", true)
        ], 2),
        (!_ctx.loading && _ctx.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}