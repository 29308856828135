
import AssetDetailTab from '@/components/buttons/AssetDetailTab.vue'
import FleetDetailTitleCard from '@/components/cards/FleetDetailTitleCard.vue'
import ServiceCardContent from '@/components/cards/ServiceCardContent.vue'
import FleetAssetDetails from '@/components/fleet/FleetAssetDetails.vue'
import FleetReportTab from '@/components/fleet/tabs/FleetReportTab.vue'
import { Manager } from '@/core/managers/fleet.manager'
import { FleetDetailModel } from '@/core/model/fleet.model'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import Tools from '@/core/services/tools'
import router, { PAGE_TITLE } from '@/router'
import { computed, defineComponent, inject, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import store from '@/store'
import FleetAssetTimeline from '@/components/fleet/FleetAssetTimeline.vue'
import { TimelineModel } from '@/core/model/timeline.model'
import FleetMap from '@/map/views/fleetMap.vue'
import { ILogger } from '@/core/services/loggers/ilogger.service'

export default defineComponent({
  name: 'FleetAssets',
  components: {
    FleetDetailTitleCard,
    AssetDetailTab,
    FleetAssetDetails,
    ServiceCardContent,
    FleetAssetTimeline,
    FleetReportTab,
    FleetMap
  },
  setup () {
    const route = useRoute()
    const toast = useToast()
    const loading = ref(true)
    const loadingTimeline = ref(true)
    const error = ref('')
    const { t } = useI18n()
    const selectedTab = ref(
      router.currentRoute.value.query.tab
        ? router.currentRoute.value.query.tab
        : t('fleet.tabs.history')
      // : 'fleet.tabs.history'
    )

    const tabs = [
      t('fleet.tabs.history'),
      t('fleet.tabs.documents'),
      t('fleet.tabs.details')
    ]

    const vesselDetails: FleetDetailModel|undefined = undefined as FleetDetailModel|undefined
    const assetFilter = computed(() => {
      return store.state.filtersSettings &&
      store.state.filtersSettings.id_AssetFilter
        ? store.state.filtersSettings.id_AssetFilter
        : ''
    })
    const periodFilter = computed(() => {
      return store.state.filtersSettings &&
      store.state.filtersSettings.id_PeriodFilter
        ? store.state.filtersSettings.id_PeriodFilter
        : ''
    })

    const imo = ref('')
    const mmsi = ref('')
    const length = ref('')
    const bridgeNumber = ref('')
    const bridgeEmail = ref('')

    const selectTab = (tab: string) => {
      router.replace({ query: { tab: encodeURI(tab) } })
      selectedTab.value = tab
    }

    const timeline: Ref<TimelineModel[]> = ref([])
    const periodSelected = ref('')
    const timeSheetError = ref('')

    return {
      periodFilter,
      assetFilter,
      vesselDetails,
      tabs,
      selectedTab,
      route,
      toast,
      loading,
      loadingTimeline,
      error,
      timeline,
      selectTab,
      length,
      imo,
      mmsi,
      periodSelected,
      timeSheetError,
      bridgeNumber,
      bridgeEmail,
      loginService: inject('ILoginService') as ILoginService,
      logger: inject('ILogger') as ILogger
    }
  },
  mounted () {
    Manager.detail(
      +this.route.params.categoryId,
      +this.route.params.assetId
    ).then((response) => {
      if (!response.isError && response.data) {
        this.vesselDetails = response.data
        if (this.vesselDetails === undefined) return
        const category =
          this.$route.params.categoryId === '1'
            ? 'Infrastructure'
            : this.$route.params.categoryId === '2'
              ? 'Installation'
              : 'Vessel'

        this.logger.logEvent({
          name: 'UserNavigation',
          properties: {
            contextName: 'Fleet',
            categoryName: category,
            subcategoryName: this.vesselDetails.name,
            ActionName: 'view'
          }
        })
        const imo = this.vesselDetails.properties.find(
          (property) => property.label === 'IMO'
        )
        const mmsi = this.vesselDetails.properties.find(
          (property) => property.label === 'MMSI'
        )
        const length = this.vesselDetails.properties.find(
          (property) => property.label === 'Length overall'
        )
        const phone = this.vesselDetails.properties.find(
          (property) => property.label === 'Bridge phone'
        )
        const email = this.vesselDetails.properties.find(
          (property) => property.label === 'Bridge email'
        )

        this.imo = imo?.value || ''
        this.length = length?.value || ''
        this.mmsi = mmsi?.value || ''
        this.bridgeNumber = phone?.value || ''
        this.bridgeEmail = email?.value || ''
        this.periodSelected = 'L1W'
        this.getTimeSheet(
          +this.route.params.categoryId,
          +this.route.params.assetId,
          7
        )
      } else {
        this.error = `${this.$t('fleet.error')}${response.Message}`
        this.toast.error(this.error)
      }
      this.loading = false
      Tools.updatePageName(
        PAGE_TITLE[this.$route.name as string],
        this.vesselDetails?.name
      )
    })
  },
  methods: {
    getTimeSheet (categoryId: number, assetId: number, period: number) {
      Manager.timesheet(categoryId, assetId, period).then((response) => {
        this.loadingTimeline = false
        if (!response.isError && response.data) {
          this.timeline = response.data.timeSheetItems
        } else {
          this.timeSheetError = `${this.$t('fleet.errorTimeSheet')}${
            response.Message
          }`
          this.toast.error(this.timeSheetError)
        }
      })
    },
    choosePeriod ($event: string) {
      this.periodSelected = $event
      const period = $event === 'L1W' ? 7 : 3
      this.getTimeSheet(
        +this.route.params.categoryId,
        +this.route.params.assetId,
        period
      )
    }
  }
})
