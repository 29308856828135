
import FavouriteButton from '@/components/buttons/FavouriteButton.vue'
import assets from '@/core/composables/assets.composable'
import manageFavourite from '@/core/composables/favourite.composable'
import { fleetCardProps } from '@/core/model/fleetCardProps.model'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'FleetCardCardView',
  components: {
    FavouriteButton
    // WifiIcon
  },
  props: fleetCardProps,
  setup (props) {
    const favouriteHandler = manageFavourite(
      props.id as number,
      props.categoryId as number,
      false,
      props.isFav
    )
    const assetsDetails = assets(
      props.type as string,
      props.country as string,
      props.categoryId,
      props.id
    )
    const icon = assetsDetails.icon
    const img = assetsDetails.img
    const flag = assetsDetails.flag
    const changeFavStatus = favouriteHandler.changeFavStatus
    const isFavourite = ref(favouriteHandler.isFavouriteRef)
    const seeAssetDetails = assetsDetails.seeAssetDetails
    const loading = ref(favouriteHandler.loadingRef)

    return {
      changeFavStatus,
      isFavourite,
      loading,
      seeAssetDetails,
      icon,
      img,
      flag
    }
  }
})
