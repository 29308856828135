import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { timeDay } from 'd3'
import { AssetRow, EntryAssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'
import { APIEntriesForAsset } from '@/core/managers/entriesForAsset/entriesForAssetApi'

export interface IEntriesForAssetMapper {
  fetchDecoupling: (item:APIEntriesForAsset)=> AssetRow[]
}

export const CreateEntriesForAssetMapper = function (timelineHelper : ITimeLineHelper): IEntriesForAssetMapper {
  // reset startDate if inf TimeLineFirstDay
  const _mapStartDate = function (periodStart:string) {
    const timeLineStart = timelineHelper.timelineInfo.firstDate
    const count = timeDay.count(timeLineStart, new Date(Date.parse(periodStart)))
    if (count > 0) {
      return new Date(Date.parse(periodStart))
    } else {
      return timelineHelper.timelineInfo.firstDate
    }
  }

  // reset endDate if sup TimeLineLastDay
  const _mapEndDate = function (periodEnd:string) {
    const timeLineEnd = timelineHelper.timelineInfo.lastDate
    const count = timeDay.count(timeLineEnd, new Date(Date.parse(periodEnd)))
    if (count > 0) {
      return timelineHelper.timelineInfo.lastDate
    } else {
      return new Date(Date.parse(periodEnd))
    }
  }

  // calc offset period
  const _offsetWidthPeriod = function (startPeriod : string): number {
    const _start = _mapStartDate(startPeriod)
    const width = timelineHelper.colWith
    return (timeDay.count(timelineHelper.timelineInfo.firstDate, _start) * width)
  }
  // call length period
  const _pixelWidthPeriod = function (startPeriod:string, endPeriod : string):number {
    const _start = _mapStartDate(startPeriod)
    const _end = _mapEndDate(endPeriod)
    const width = timelineHelper.colWith
    return (timeDay.count(_start, _end) * width)
  }
  // call length period
  const _periodTextLength = function (startPeriod:string, endPeriod : string):number {
    const _start = _mapStartDate(startPeriod)
    const _end = _mapEndDate(endPeriod)
    return (timeDay.count(_start, _end))
  }

  // todo remove helper parseDate
  return {
    fetchDecoupling: function (item:APIEntriesForAsset):AssetRow[] {
      return item.assets.reduce((list:AssetRow[], asset) => {
        list.push({
          id: asset.id,
          name: asset.name,
          entries: asset.entries.reduce((listEntry:EntryAssetRow[], entry) => {
            listEntry.push({
              daily_rate: entry.daily_rate,
              editable: entry.editable,
              deletable: entry.deletable,
              draw: {
                periodTextLength: _periodTextLength(entry.start_date, entry.end_date),
                isStartCropped: _mapStartDate(entry.start_date) === timelineHelper.timelineInfo.firstDate,
                isEndCropped: _mapEndDate(entry.end_date) === timelineHelper.timelineInfo.lastDate,
                offsetWidth: _offsetWidthPeriod(entry.start_date),
                lengthWidth: _pixelWidthPeriod(entry.start_date, entry.end_date)
              },
              start_date: new Date(entry.start_date.slice(0, 19)),
              end_date: new Date(entry.end_date.slice(0, 19)),
              reason: entry.reason,
              status: entry.status,
              isOff: entry.status.toLowerCase().indexOf('off') > -1,
              isOn: entry.status.toLowerCase().indexOf('on') > -1,
              isMissing: entry.status.toLowerCase().indexOf('missing') > -1,
              status_color: entry.status_color,
              id: entry.id
            } as EntryAssetRow)
            return listEntry
          }, [])
        })
        return list
      }, [])
    }
  }
}
