
import { DashboardService } from '@/core/model/dashboard.model'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'DashboardsListMenu',
  props: {
    dashboard: {
      type: Object as PropType<DashboardService>
    },
    loading: { type: Boolean, default: false },
    allActive: { type: Boolean, default: true },
    allActiveInCategory: { type: Boolean, default: true }
  },
  data () {
    return {
      open: false
    }
  }
})
