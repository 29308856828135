import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6df1cf18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-6 h-100" }
const _hoisted_2 = { class: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiceDetailTitle = _resolveComponent("ServiceDetailTitle")!
  const _component_TopButton = _resolveComponent("TopButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ServiceDetailTitle, {
      loading: _ctx.loading,
      title: _ctx.pageName,
      link: 'analyticsDetail',
      params: { analyticsId: _ctx.linkParams },
      description: _ctx.description,
      small: true
    }, null, 8, ["loading", "title", "params", "description"]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "text-center text-white p-3 relative",
          style: _normalizeStyle(`height: ${_ctx.dashboardHeight}px`)
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.error), 1)
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "relative dash rounded-lg bg-transparent",
      style: _normalizeStyle(`height: ${_ctx.dashboardHeight}px`)
    }, [
      (_ctx.mounted && !_ctx.error)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            ref: "dashboardContainer",
            class: "p-3 relative dash rounded-lg",
            style: _normalizeStyle(`height: ${_ctx.dashboardHeight}px`)
          }, null, 4))
        : _createCommentVNode("", true),
      (_ctx.mounted && !_ctx.error && !_ctx.loading && _ctx.dashboardLoaded)
        ? (_openBlock(), _createBlock(_component_TopButton, {
            key: 1,
            title: '',
            type: 'expand',
            primary: true,
            dash: true,
            class: "absolute z-10 top-6 right-6",
            onClick: _ctx.fullscreen
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}