import { Map } from 'leaflet'
import { computed, onUnmounted, watch } from 'vue'
import concessionStore from '@/map/core/bll/concession/concession.store'
import { groupConcessionLayers } from '@/map/core/bll/concession/concession.group'

export default {
  mounted: function (map: Map): void {
    const concessionInitialized = computed(function (): boolean {
      return concessionStore.state.initialized
    })

    watch(concessionInitialized, function (value : boolean): void {
      if (!value) {
        concessionStore.action.fetch()
      }
    }, {
      immediate: true,
      flush: 'post'
    })

    const concessionDisplay = computed(function () : boolean {
      return concessionStore.state.toggle
    })

    watch(concessionDisplay, function (value : boolean): void {
      if (value) {
        map.addLayer(groupConcessionLayers)
      } else {
        groupConcessionLayers.remove()
      }
    }, {
      immediate: true
    })

    onUnmounted(() => {
      groupConcessionLayers.remove()
    })
  }

}
