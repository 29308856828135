
import ServiceReport from '@/components/reports/categories/services/reports/ServiceReport.vue'
import AssetReport from '@/components/reports/categories/services/reports/AssetReport.vue'
import ReportGroupHeader from '@/components/reports/categories/services/reports/headers/ReportGroupHeader.vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import { ServiceReportGroup } from '@/core/model/report.model'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    GenericCardShell,
    ServiceReport,
    AssetReport,
    ReportGroupHeader
  },
  props: {
    service: { type: Object as PropType<ServiceReportGroup>, required: true },
    categoryName: { type: String },
    assets: { type: Array as PropType<any> },
    isInReports: { type: Boolean, default: false }
  },
  methods: {
    requestUpload () {
      console.log('requestUpload')
    }
  },
  computed: {
    subtitle () {
      if (this.categoryName) {
        return this.categoryName + ' | ' + this.service.name
      }
      return this.service.name
    }
  }
})
