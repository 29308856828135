

import { defineComponent, inject, PropType } from 'vue'
import Treeselect from 'vue3-treeselect'
import DropZone from '@/components/reports/categories/services/reports/form/DropZone.vue'
import FilePreview from '@/components/reports/categories/services/reports/form/FilePreview.vue'
import FormHeader from '@/components/reports/categories/services/reports/form/ServiceReportFormHeader.vue'
import CalendarIcon from '@/components/icons/CalendarIcon.vue'
import LoaderIcon from '@/components/icons/LoaderIcon.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import { ContextManager } from '@/core/managers/context.manager'
import ReportManager from '@/core/managers/report.manager'
import { UploadAssetReportModel, UploadableFile } from '@/core/model/report.model'
import { AssetApiModel } from '@/core/model/fleet.model'

export default defineComponent({
  components: {
    Treeselect,
    CalendarIcon,
    LoaderIcon,
    UploadIcon,
    FormHeader,
    DropZone,
    FilePreview
  },
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    assets: { type: Array as PropType<AssetApiModel[]>, required: true },
    assetId: { type: Number }
  },
  setup () {
    return {
      contextManager: inject(ContextManager.name) as ContextManager,
      reportManager: ReportManager
    }
  },
  data () {
    return {
      clientDepartmentContext: 'None',
      attributes: [] as Array<any>,
      file: null as UploadableFile|null,
      date: new Date(Date.now()),
      modelValue: null as AssetApiModel|null,
      // format: 'pdf',
      name: '',
      attributeId: -1,
      uploading: false
    }
  },
  async created () {
    const contextResponse = await this.contextManager.currentDepartment()
    if (contextResponse.data) {
      this.clientDepartmentContext = contextResponse.data
    }
    this.attributes = await this.reportManager.attributes()
    if (this.assetId && this.assets.length) {
      this.modelValue = this.assets.find(a => a.id === this.assetId) as AssetApiModel
    }
  },
  methods: {
    close () {
      if (this.uploading) return

      this.removeFile()
      this.$emit('close')
    },
    onInputChange (e: any) {
      this.addFiles(e.target.files)
      e.target.value = null // reset so that selecting the same file again will still cause it to fire this change
    },
    addFiles (newFiles: File[]) {
      const newUploadableFile = new UploadableFile(newFiles[0])
      this.file = newUploadableFile
    },
    removeFile () {
      this.file = null
    },
    async upload () {
      if (this.file === null || this.modelValue === null) return
      this.uploading = true
      let shouldBeClose = false
      try {
        const uploadModel = {
          assetId: this.modelValue.id,
          reportId: this.id,
          Title: this.name,
          attributeId: this.attributeId,
          ClientDepartmentCode: this.clientDepartmentContext,
          Date: this.date,
          // Format: this.format,
          File: this.file as UploadableFile
        } as UploadAssetReportModel
        await this.reportManager.uploadForAsset(uploadModel)
        shouldBeClose = true
      } finally {
        this.uploading = false
      }

      if (shouldBeClose) {
        this.close()
      }
    },
    normalizer (node: AssetApiModel) {
      return {
        id: node.id,
        label: node.name
      }
    },
    rotateArrow () {
      const TreeselectElement = document.getElementById(this.TreeselectId)
      if (TreeselectElement === null) return
      const arrowContainer = TreeselectElement.querySelectorAll('.vue-treeselect__control-arrow-container')
      arrowContainer[0].classList.toggle('rotate-x-180')
    }
  },
  computed: {
    TreeselectId () {
      return 'reportform-' + this.id.toString() + '-AssetTreeSelect'
    }
  }
})

