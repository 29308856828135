
import previewHandling, { previewHandlingProps } from '@/core/composables/preview.composable'
import { ChartModel } from '@/core/model/chart.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import store from '@/store'
import { Chart } from 'highcharts-vue'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ServicesCardChart',
  components: {
    Highcharts: Chart
  },
  props: {
    ...previewHandlingProps,
    id: Number,
    title: String,
    description: String,
    buttonText: String,
    linkUrl: String,
    customDetails: String,
    graphType: String,
    compatibleFilters: Object
  },
  setup (props) {
    const prop = {
      id: props.id,
      title: props.title,
      buttonText: props.buttonText,
      linkUrl: props.linkUrl,
      graphType: props.graphType,
      previewType: props.previewType,
      previewInfos: props.previewInfos,
      size: props.size,
      description: props.description,
      isPreview: props.isPreview,
      kpiTitle: props.kpiTitle
    }
    const preview = previewHandling(
      prop,
      '',
      '',
      {} as ChartModel,
      true,
      {} as KpiModel,
      true,
      [] as KpiExtended[]
    )
    const loading = ref(preview.loadingRef)
    const error = ref(preview.errorRef)
    const detailed = ref(preview.detailedRef)
    const chartOptions = preview.chartOptions

    return {
      loading,
      preview,
      error,
      detailed,
      chartOptions
    }
  },
  computed: {
    filters () {
      return store.state.filters
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler (currentValue, oldValue) {
        if (oldValue && JSON.stringify(oldValue) !== JSON.stringify(currentValue) && Object.keys(currentValue).length && this.compatibleFilters) {
          this.preview.handleChart(
           this.id as number,
           this.filters,
           Object.values(this.compatibleFilters)
          )
        }

      //  }
      }
    }
  }
})
