import { AssetModel, GetAssetModel } from '@/map/core/bll/category/categoryModel'
import { latLng } from 'leaflet'

export function CategoryMapper (asset : GetAssetModel, categoryCode :string): AssetModel {
  const isVessel = asset.assetCategoryId === 3
  const isUnderContract = asset.isUnderContract
  const color = isVessel ? isUnderContract ? 'yellow' : 'grey' : 'noColorForAsset'
  const assetIconMarker = isVessel ? require(`@/assets/img/vessel/vessel_${color}.png`) : (asset.typeIconUrl || require('@/assets/img/other/ROND.png'))

  return {
    assetId: asset.assetId, // for kpi
    assetCode: asset.code,
    markerDim: isVessel ? isUnderContract ? 20 : 12 : 14, // marker
    stroke: 2,
    svgDim: 70,
    radius: 20,
    assetName: asset.name.toUpperCase(),
    categoryCode, // filter on category panel
    isVessel,
    isUnderContract,
    query: {
      staticStep: undefined,
      assetPopoverAdd: undefined,
      stepPopoverAdd: undefined,
      flyToBounds: undefined,
      assetCircle: asset.code,
      panelDetail: isUnderContract ? asset.code : undefined,
      setBlue: isVessel && isUnderContract ? asset.code : undefined,
      fetch: isVessel && isUnderContract ? asset.code : undefined
    },
    checked: true,
    vesselColor: color, // marker
    categoryId: asset.assetCategoryId, // panel category
    assetImg: asset.imageUrl || asset.typeImageUrl || require('@/assets/img/noAsset.png'),
    assetTypeName: asset.typeName,
    assetMarkerUrl: assetIconMarker, // marker
    lastPosition: asset.assetLastPosition.utcPositionTime || '-1',
    heading: asset.assetLastPosition.heading,
    speed: asset.assetLastPosition.speed,
    latLng: latLng([asset.assetLastPosition.latitude, asset.assetLastPosition.longitude])
  }
}
