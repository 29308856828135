import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.routeName ? 'router-link' : 'div'), {
    to: { name: _ctx.routeName }
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["flex items-center py-2.5 px-4 shadow rounded-md border border-gray-50", [_ctx.specificClass, {'m-auto' : _ctx.type==='home'}]]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
          class: _normalizeClass(_ctx.svgClass)
        }, null, 8, ["class"])),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ], 2)
    ]),
    _: 1
  }, 8, ["to"]))
}