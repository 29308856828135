import { ILoginService } from '@/core/services/authentication/ILogin.service'
import { AuthenticationSettings } from '@/core/model/settings.model'
import { AccountInfo, AuthenticationResult, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'

export class LoginService implements ILoginService {
  private readonly _additionalScope: Array<string>
  private connectedUser: AccountInfo|undefined
  public get AccountInfo (): AccountInfo {
    return this.connectedUser ?? {} as AccountInfo
  }

  private readonly msalInstance: PublicClientApplication

  constructor (authSettings: AuthenticationSettings, metadata?: string) {
    this.msalInstance = this.GenerateMsalInstance(authSettings, metadata)
    this._additionalScope = authSettings.scopes ?? []
  }

  private GenerateMsalInstance (authSettings: AuthenticationSettings, metadata?: string) : PublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: authSettings.clientId,
        authority: metadata === undefined ? authSettings.authority : 'https://login.microsoftonline.com/common/',
        authorityMetadata: metadata,
        redirectUri: '/signin-oidc'
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    })
  }

  public SignIn () : Promise<void> {
    return this.msalInstance.loginRedirect({ ...this.loginRequest() } as RedirectRequest)
  }

  private async handleRedirectPromise (): Promise<AuthenticationResult|null> {
    return await this.msalInstance.handleRedirectPromise()
  }

  public async HandleUserAlreadyLogin () : Promise<void> {
    const response:AuthenticationResult | null = await this.handleRedirectPromise()
    this.connectedUser = response ? response.account as AccountInfo : this.msalInstance.getAllAccounts()[0]
    this.msalInstance.setActiveAccount(this.connectedUser)
  }

  public SignOut () : Promise<void> {
    if (this.connectedUser === undefined) throw new Error('No connected user.')
    const logoutRequest = {
      account: this.msalInstance.getAccountByHomeId(this.connectedUser.homeAccountId)
    }
    return this.msalInstance.logoutRedirect(logoutRequest)
  }

  public async IsConnected () : Promise<boolean> {
    if (this.connectedUser === undefined || this.connectedUser === null) {
      return false
    }
    const token = await this.callAuthority(false)
    return token.expiresOn !== null && token.expiresOn > new Date()
  }

  public async IsInternalUser (): Promise<boolean> {
    if (!await this.IsConnected()) return false
    if (this.AccountInfo.idTokenClaims === undefined) return false
    return this.AccountInfo.idTokenClaims.extension_ClientDepartmentCode === 'OPSL'
  }

  async GetToken () : Promise<AuthenticationResult> {
    let token = await this.callAuthority(false)
    const needRefresh: boolean = token === null || token.accessToken === null || this.isTokenExpired(token.accessToken)
    if (needRefresh) {
      token = await this.callAuthority(true)
    }
    if (token === null) console.error('Could not get access token from authority.')
    // const encrypt = CryptoJS.AES.encrypt(token.accessToken, 'Passphrase')
    // document.cookie = `accessToken = ${encrypt};path=/`
    return token
  }

  private isTokenExpired (accessToken: string): boolean {
    try {
      const splitedToken = accessToken.split('.')[1]
      const parsedToken = JSON.parse(atob(splitedToken))
      // Transform exp milisecond into date
      const dToken = new Date(parsedToken.exp * 1000)
      // Get date now + 5 minutes
      const dNow = new Date(Date.now() + (30 * 1000))
      // Refresh the token if the token expire in 5 minute or less
      return dToken < dNow
    } catch {
      return true
    }
  }

  private loginRequest () {
    return {
      scopes: ['openid', 'profile', ...this._additionalScope],
      account: this.msalInstance.getAllAccounts()[0] ?? undefined
    }
  }

  private async callAuthority (forceRefresh: boolean) : Promise<AuthenticationResult> {
    const tokenInfos = {
      ...this.loginRequest(),
      forceRefresh
    }
    return this.msalInstance.acquireTokenSilent(tokenInfos).then((response) => {
      return response
    }
    ).catch(err => {
      // this.msalInstance.acquireTokenRedirect(tokenInfos)
      return err
    })
  }
}
