
import { computed, defineComponent, inject, PropType, ref } from 'vue'
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import FilesIcon from '@/components/icons/FilesIcon.vue'
import FleetIcon from '@/components/icons/FleetIcon.vue'

import MapIcon from '@/components/icons/MapIcon.vue'
import AnalyticsIcon from '@/components/icons/AnalyticsIcon.vue'
import TimelineIcon from '@/components/icons/TimelineIcon.vue'

import { useRoute } from 'vue-router'
import { Manager as UserManager } from '@/core/managers/user.manager'

import { ILoginService } from '@/core/services/authentication/ILogin.service'

export default defineComponent({
  name: 'NavigationLinkPrimary',
  components: {
    DashboardIcon,
    TimelineIcon,
    FilesIcon,
    FleetIcon,
    MapIcon,
    AnalyticsIcon
  },
  props: {
    to: String,
    query: {
      type: Object as PropType<Record<string, string>>,
      required: false
    },
    params: {
      type: Object as PropType<Record<string, string>>,
      required: false
    }
  },
  setup (props) {
    const route = useRoute()
    const hideBar = ref(false)
    const stroke = ['']
    const fill = ['map']
    const activeLink = computed(() => {
      return (
        route.name === props.to ||
        (route.name === 'analyticsDetail' && props.to === 'analytics') ||
        (route.name === 'assetDetail' && props.to === 'fleet')
      )
    })
    const icon = computed(() => {
      switch (props.to) {
        case 'map':
          return 'MapIcon'
        case 'fleet':
          return 'FleetIcon'
        case 'analytics':
          return 'AnalyticsIcon'
        case 'allReports':
          return 'FilesIcon'
        case 'allDashboards':
          return 'DashboardIcon'
        case 'operations':
          return 'TimelineIcon'
        default:
          return ''
      }
    })
    const loginService = inject('ILoginService') as ILoginService
    return { hideBar, stroke, fill, activeLink, icon, _userManager: UserManager, loginService }
  },
  data () {
    return {
      isInternalUser: false
    }
  },
  async created () {
    this.isInternalUser = await this.loginService.IsInternalUser()
  }
})
