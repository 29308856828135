import { TimelineModel, TimelineModalContent, Activity } from './../model/timeline.model'
import ColoursService from '@/core/services/colours.service'
import { getCurrentInstance } from 'vue'

interface timelinePropsData {
    timeline: TimelineModel[]
    openModal: boolean
    modalContent: TimelineModalContent
    zoomLevel?: number
}

export default function timelineStyle (props: timelinePropsData): {
    getKpiColour: (label: string) => string
    lightenKpiColour: (colour: string, decimal: number) => string
    operationBackground: (operation: string, startTime: string) => string
    operationBorder: (operation: string) => string
    getHours: (date: string) => string
    showDetail: (stack: Activity) => void
} {
  const { emit } = getCurrentInstance() as NonNullable<ReturnType<typeof getCurrentInstance>>

  const showDetail = (stack: Activity) => {
    emit('show-detail', stack)
  }

  const getKpiColour = (label: string) => {
    return ColoursService.getColour(label)
  }
  const lightenKpiColour = (colour: string, decimal = 0.8) => {
    return ColoursService.colourLightness(colour, decimal)
  }
  const operationBackground = (operation: string, startTime: string) => {
    return (props.openModal &&
            props.modalContent.activity &&
            props.modalContent.activity.name === operation &&
            props.modalContent.activity.startTime === startTime) ||
            !props.openModal
      ? `background-color: ${getKpiColour(operation)}`
      : 'background-color: #4b5563; color: #1f2937'
  }
  const operationBorder = (operation: string) => {
    return `border: 1px solid ${lightenKpiColour(getKpiColour(operation))}`
  }

  const getHours = (date: string) => {
    const dateFormatted = new Date(date)
    return `${String(dateFormatted.getHours()).padStart(2, '0')}:${String(
            dateFormatted.getMinutes()
        ).padStart(2, '0')}`
  }

  return { showDetail, getKpiColour, lightenKpiColour, operationBackground, operationBorder, getHours }
}
