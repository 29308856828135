
import DashboardsListMenu from '@/components/buttons/DashboardsListMenu.vue'
import FilterIcon from '@/components/icons/FilterIcon.vue'
import manageReportsMenu from '@/core/composables/reportsMenu.composable'
import { DashboardService } from '@/core/model/dashboard.model'
import { defineComponent, PropType, ref } from 'vue'
import SearchInput from '@/components/SearchInput.vue'

export default defineComponent({
  name: 'DashboardsListMenuShell',
  components: { DashboardsListMenu, FilterIcon, SearchInput },
  props: {
    services: Array as PropType<DashboardService[]>,
    error: String,
    loading: Boolean
  },
  setup () {
    const manageMenu = manageReportsMenu()
    const filtersDisplayed = ref(manageMenu.filtersDisplayed)
    const openFilters = manageMenu.openFilters
    const closeFilters = manageMenu.closeFilters
    const allActive = ref(true)
    const allActiveInCategory = ref(true)
    const search = ref('')
    return { filtersDisplayed, openFilters, closeFilters, allActive, allActiveInCategory, search }
  },
  methods: {
    handleServiceDashboard ($e: string, dashboard: DashboardService) {
      let allActive = -1
      for (const service of this.services!) {
        if (service.active && service !== dashboard) {
          allActive += 1
        }
      }
      if (allActive > -1) {
        for (const service of this.services!) {
          service.active = false
        }

        dashboard.active = !dashboard.active
        for (const serv of dashboard.services) {
          serv.active = true
        }
        this.allActiveInCategory = true
        this.allActive = false
      } else {
        for (const service of this.services!) {
          service.active = true
          for (const serv of service.services) {
            serv.active = true
          }
        }
        this.allActiveInCategory = false
        this.allActive = true
      }
    },
    handleDetailDashboard ($e: string, dashboard: DashboardService) {
      let allActive = -1
      const serviceSelected = dashboard.services.find((t) => t.name === $e)
      for (const service of this.services!) {
        for (const serv of service.services) {
          if (serv.active && serv !== serviceSelected) {
            allActive += 1
          }
        }
      }

      if (allActive > -1) {
        for (const service of this.services!) {
          service.active = false
          for (const serv of service.services) {
            serv.active = false
          }
        }

        serviceSelected!.active = !serviceSelected!.active
        dashboard.active = !dashboard.active
        this.allActiveInCategory = false
        this.allActive = false
      } else {
        for (const service of this.services!) {
          service.active = true
          for (const serv of service.services) {
            serv.active = true
          }
        }
        this.allActive = true
        this.allActiveInCategory = true
      }
    }
  }
})
