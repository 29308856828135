import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-display text-white text-2xl my-4 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListReportByService = _resolveComponent("ListReportByService")!

  return (_openBlock(), _createBlock(_TransitionGroup, {
    name: "fade",
    tag: "div"
  }, {
    default: _withCtx(() => [
      (_ctx.category.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.category.name), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.category.groups, (service, i) => {
              return (_openBlock(), _createBlock(_component_ListReportByService, {
                key: i,
                service: service,
                categoryName: _ctx.category.name,
                assets: _ctx.assets,
                isInReports: _ctx.isInReports
              }, null, 8, ["service", "categoryName", "assets", "isInReports"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}