import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_viewport_slider = _resolveComponent("viewport-slider")!
  const _component_paginate_slider = _resolveComponent("paginate-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_viewport_slider, {
      sources: _ctx.kpiList,
      current: _ctx.position,
      direction: _ctx.direction,
      "asset-id": _ctx.assetId,
      "asset-filter": _ctx.assetFilter,
      periodFilter: _ctx.periodFilter
    }, null, 8, ["sources", "current", "direction", "asset-id", "asset-filter", "periodFilter"]),
    _createVNode(_component_paginate_slider, {
      count: _ctx.kpiList.length,
      current: _ctx.position,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onMouseOver())),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMouseLeave())),
      onCurrent: _ctx.onClick
    }, null, 8, ["count", "current", "onCurrent"])
  ]))
}