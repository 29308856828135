

/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import BackToTop from '@/components/buttons/BackToTop.vue'
import NavigationLinkPrimary from '@/components/buttons/NavigationLinkPrimary.vue'
import NavigationLinkSecondary from '@/components/buttons/NavigationLinkSecondary.vue'
import { defineComponent } from 'vue'
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import QuestionIcon from '@/components/icons/QuestionIcon.vue'
export default defineComponent({
  name: 'layout1',
  watch: {
    menuHelp: {
      handler (value) {
        const _click = () => {
          document.body.removeEventListener('click', _click)
          this.menuHelp = false
        }
        if (value) {
          setTimeout(function () {
            document.body.addEventListener('click', _click)
          }, 1)
        }
      }
    }/*
    displayFeedBackModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
          document.body.style.overflowY = 'hidden'
          if (scrollBarWidth > 0 && document.body.scrollHeight > document.body.clientHeight) {
            document.body.style.paddingRight = scrollBarWidth + 'px'
          }
        } else {
          document.body.style.overflowY = 'auto'
          document.body.style.paddingRight = '0px'
        }
      }
    } */
  },
  components: {
    NavigationLinkPrimary,
    BackToTop,
    ProfileIcon,
    QuestionIcon
  },
  data () {
    return {
      menuHelp: false,
      open: false,
      menuClass: 'translate-x-full',
      navigationLinks: [
        { name: 'analytics' },
        { name: 'map', query: { flyToBounds: 'true' } },
        { name: 'fleet' },
        { name: 'allReports' },
        { name: 'allDashboards' },
        {
          name: 'operations',
          params: {
            rangeTimeId: new Date().getFullYear() + '-' + (new Date().getMonth() + 1),
            contractId: -1
          }
        }
      ],
      secondaryNavigationLinks: ['helpCenter', 'profile']
    }
  },
  computed: {
    /*     displayFeedBackModal () {
      return this.$route.query.modal && this.$route.query.modal === 'feedback'
    } */
  },
  methods: {
    onClick (e: Event) {
      const target = e.currentTarget as HTMLLinkElement
      target.classList.add('pressed')
      setTimeout(function () {
        target.classList.remove('pressed')
      }, 301)
    },
    openMenu () {
      this.open = !this.open
    }
  }
})
