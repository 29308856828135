import { IAppStore } from '@/core/appStore'
import { ILoginService } from '@/core/services/authentication/ILogin.service'

export interface IResultError{
  errors : Record<string, string[]>
}
export interface IHttpProvider {
  get: <T>(url:string, signal: AbortSignal)=> Promise<T>
  post :<T, R> (url: string, body: T, signal: AbortSignal)=> Promise<R>
  put :<T> (url: string, body: T, signal: AbortSignal)=> Promise<void>
  delete: (url: string, signal: AbortSignal)=> Promise<void>,
}

export function CreateHttpProvider (appStore: IAppStore, tokenService : ILoginService, sessionId:string): IHttpProvider {
  const _response = async function <T> (type: string, response:Response):Promise<T> {
    let t
    if (response.ok) {
      if (type === 'json') {
        t = await response.json() as T
      } else {
        t = await response.text() as T
      }
      return t
    } else {
      if (response.status === 400) {
        const errors = []
        const resultError = await response.json()
        for (const d in resultError.errors) {
          for (let i = 0; i < resultError.errors[d].length; i++) {
            errors.push(resultError.errors[d][i])
          }
        }
        appStore.action.dialog.show(errors)
      }
      if (response.status === 500) {
        appStore.action.toaster.show('oups error...', 'red')
      }
      /*      console.log(result)
      const errorMsg = result.error.code + ': ' + result.error.message
      appStore.action.toaster.show(errorMsg, 'orange') */
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('oups erreur')
    }
  }

  const _getAuthorization = async function ():Promise<string> {
    let _token = 'empty token'
    if (process.env.VUE_APP_IS_FAKE === 'false') {
      const tokenResponse = await tokenService.GetToken()
      _token = tokenResponse.accessToken
    }
    return _token
  }

  return {
    async get<T> (url:string, signal: AbortSignal): Promise<T> {
      const _token = await _getAuthorization()
      // appStore.action.blockers.increment()
      const resp = await fetch(url, { headers: { authorization: `Bearer ${_token}`, 'x-sessionId': sessionId }, signal })
      return await _response<T>('json', resp)
    },
    async post <T, R> (url: string, body: T, signal: AbortSignal): Promise<R> {
      const _token = await _getAuthorization()
      // appStore.action.blockers.increment()
      const resp = await fetch(url, { method: 'POST', body: body ? JSON.stringify(body) : null, headers: { authorization: `Bearer ${_token}`, 'x-sessionId': sessionId }, signal })
      return await _response<R>('json', resp)
    },
    async put <T> (url: string, body: T, signal: AbortSignal): Promise<void> {
      const _token = await _getAuthorization()
      // appStore.action.blockers.increment()
      const resp = await fetch(url, { method: 'PUT', body: JSON.stringify(body), headers: { authorization: `Bearer ${_token}`, 'x-sessionId': sessionId }, signal })
      return await _response<void>('text', resp)
    },
    async delete (url: string, signal: AbortSignal): Promise<void> {
      const _token = await _getAuthorization()
      // appStore.action.blockers.increment()
      const resp = await fetch(url, { method: 'DELETE', headers: { authorization: `Bearer ${_token}`, 'x-sessionId': sessionId }, signal })
      return await _response<void>('text', resp)
    }

  }
}
