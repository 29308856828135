import { computed, ComputedRef } from 'vue'
import store from '@/store'
import { DOMEvent } from '@/core/model/event.model'
import router from '@/router'
import { FleetSettings } from '@/core/model/settings.model'

export default function assets (assetType: string, country?: string, categoryId?: number, id?: number) : {
    icon: ComputedRef<string>;
    img: ComputedRef<string>;
    flag: ComputedRef<string>;
    seeAssetDetails: (event: DOMEvent<HTMLElement>) => void
    } {
  const icon = computed((): string => {
    // const assetTypeSetting = (store.state.fleetSettings as FleetSettings).assetTypeSettings.find((type) => type.code === assetType)
    const assetTypeSetting = store.state.fleetSettings && store.state.fleetSettings?.assetTypeSettings && (store.state.fleetSettings as FleetSettings).assetTypeSettings.length ? (store.state.fleetSettings as FleetSettings).assetTypeSettings.find((type) => type.code === assetType) : undefined
    const icon = assetTypeSetting ? assetTypeSetting.icon_Url : ''
    if (icon) {
      return require(`@/assets/fleetIcons/${icon}.svg`)
    }
    const defaultIcon = store.state.fleetSettings && (store.state.fleetSettings as FleetSettings).defaultIcon_Url ? (store.state.fleetSettings as FleetSettings).defaultIcon_Url : 'unknown-vessel'
    return require(`@/assets/fleetIcons/${defaultIcon}.svg`)
  })
  const img = computed((): string => {
    const assetTypeSetting = store.state.fleetSettings && store.state.fleetSettings?.assetTypeSettings && (store.state.fleetSettings as FleetSettings).assetTypeSettings.length ? (store.state.fleetSettings as FleetSettings).assetTypeSettings.find((type) => type.code === assetType) : undefined
    const img = assetTypeSetting ? assetTypeSetting.image_Url : ''

    if (img) {
      return require(`@/assets/fleetAvatars/${img}.svg`)
    }
    const defaultImg = store.state.fleetSettings && store.state.fleetSettings.defaultImage_Url ? store.state.fleetSettings.defaultImage_Url : 'Avatar-Default-NoVessel'
    return require(`@/assets/fleetAvatars/${defaultImg}.svg`)
  })
  const flag = computed((): string => {
    return country ? country.toLowerCase() : ''
  })

  function seeAssetDetails (event: DOMEvent<HTMLElement>) {
    if (!event.target.matches('svg') && !event.target.matches('path')) {
      router.push({
        name: 'assetDetail',
        params: { categoryId, assetId: id }
      })
    }
  }

  return { icon, img, flag, seeAssetDetails }
}
