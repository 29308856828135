import { FilterFormatted, FilterModel } from '@/core/model/filter.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  detail (id: number, filters: FilterFormatted[], compatibleFilters? : number[]): Promise<ApiResult<FilterModel>> {
    const filtersSorted = (filters || []).filter(filter => (compatibleFilters || []).find((compFilter) => compFilter === filter.id))

    const body = {
      filters: filtersSorted
    }
    return http.request<FilterModel>('post', `Filters/${id}`, body)
      .then((rep: ApiResult<FilterModel>) => rep)
      .catch(err => err)
  }
}
