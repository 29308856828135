
import CustomMultiSelect from '@/components/dropdowns/CustomMultiSelect.vue'
import * as FilterManager from '@/core/managers/filter.manager'
import { Filter, FilterFormatted, FilterMember, FilterModel } from '@/core/model/filter.model'
import { FilterInServiceModel } from '@/core/model/service.model'
import Tools from '@/core/services/tools'
import store from '@/store'
import { defineComponent, PropType } from 'vue'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'

type FilterMemberMapped = Omit<FilterMember, 'members'> & {
  options: string[];
};

interface FilterData {
  selectedFilter: FilterFormatted[];
  multiSelected: number[][];
  multiOptions: Partial<FilterMemberMapped>[][];
  monoOptions: FilterMember[][];
  error: string[];
  flattenedFilters: Filter[];
  defaultFilter: FilterMember[];
  firstHandle: boolean;
  openMulti: boolean[];
  loading: boolean[];
  oneLoading: boolean;

  debounce?: () => void;
}

export default defineComponent({
  name: 'Filters',
  components: {
    CustomMultiSelect,
    Treeselect
  },
  props: {
    filters: Array as PropType<FilterInServiceModel[]>
  },
  data (): FilterData {
    return {
      selectedFilter: [],
      multiSelected: [],
      error: [],
      flattenedFilters: [],
      defaultFilter: [],
      debounce: undefined,
      monoOptions: [],
      multiOptions: [],
      firstHandle: true,
      openMulti: [],
      loading: [],
      oneLoading: false
    }
  },
  created () {
    this.debounce = Tools.debounce(() => {
      this.dispatchFilters()
    }, 1000)
    this.firstHandleFilters()
  },
  mounted () {
    if (this.filters) {
      // eslint-disable-next-line
      for (const [index, filter] of this.filters.entries()) {
        this.$watch(
          ['selectedFilter', index, 'members'].join('.'),
          () => {
            if (index === 0) {
              this.dispatchFilters()
            } else {
              if (this.debounce) {
                this.debounce()
              }
            }
          },
          { deep: true }
        )
      }
    }

    this.$nextTick(() => {
      for (const container of document.querySelectorAll(
        '.vue-treeselect__control-arrow-container'
      )) {
        container.classList.add('rotate-x-180')
        container.classList.add('origin-center')
      }
    })
  },
  watch: {
    loading: {
      deep: true,
      // eslint-disable-next-line
      handler (oldValue, newValue) {
        if (this.loading.includes(true)) {
          this.oneLoading = true
        } else {
          this.oneLoading = false
        }
      }
    }
  },
  methods: {
    rotateArrow (index: number) {
      const arrowContainer = document.querySelectorAll(
        '.vue-treeselect__control-arrow-container'
      )
      arrowContainer[index - 1].classList.toggle('rotate-x-180')
    },
    firstHandleFilters () {
      let filterIndex = 0
      for (const filter of this.filters as FilterInServiceModel[]) {
        this.displayFilters(filter, filterIndex)
        this.loading.push(false)
        this.multiSelected.push([])
        this.openMulti.push(false)
        this.error.push('')
        if (this.$route.query.filter) {
          for (const [index, fil] of JSON.parse(
            decodeURI(this.$route.query.filter as string)
          ).entries()) {
            if (filterIndex + 1 === fil.id && index !== 0) {
              const members = []
              for (const member of fil.members) {
                members.push(member)
              }
              this.multiSelected[filterIndex + 1] = members
            } else if (filterIndex + 1 === fil.id && index === 0) {
              if (this.defaultFilter[filterIndex]) {
                this.defaultFilter = fil.members
              }
            }
          }
        }
        filterIndex++
      }
    },
    displayFilters (filter: FilterInServiceModel, index: number) {
      this.loading[index] = true
      FilterManager.Manager.detail(
        filter.id,
        this.selectedFilter,
        filter.compatibleFilters
      )
        .then((response) => {
          if (response.isError) {
            this.error[index] = `${this.$t('analytics.error.filters')} ${
              response.Message
            }`
            this.$emit('filter-error')
          } else {
            if (
              (filter.type === 'multi' ||
                filter.type === 2 ||
                filter.type === 'Multi') &&
              response.data &&
              response.data.members
            ) {
              // Map filter members to be compliant with vue-treeselect
              const mappedMembers = response.data.members.map(
                ({ members: children, value: id, ...rest }) => ({
                  children,
                  id,
                  ...rest
                })
              )
              const deepMappedMembers = JSON.parse(
                JSON.stringify(mappedMembers).replace(/"value":/g, '"id":')
              )
              this.multiOptions[index] = []
              for (const member of deepMappedMembers) {
                // Add id to vessels category to avoid vue treeselect error
                if (!member.id) {
                  // https://github.com/denoland/deno/issues/12754
                  member.id = (crypto as any).randomUUID()
                }
                this.multiOptions[index].push(
                  Object.fromEntries(
                    // eslint-disable-next-line
                    Object.entries(member).filter(([_, v]) => v != null)
                  )
                )
              }
            } else {
              if (response.data && response.data.members) {
                this.monoOptions[index] = response.data.members
                if (response.data && response.data.defaultSelected) {
                  const defaultOption = this.monoOptions[index].find(
                    (option) =>
                      option.value ===
                      (response.data as FilterModel).defaultSelected
                  )
                  if (this.$route.query.filter) {
                    const defaultOptionFromQuery = this.monoOptions[index].find(
                      (option) => {
                        return (
                          option.value ===
                          JSON.parse(
                            decodeURI(this.$route.query.filter as string)
                          )[index].members
                        )
                      }
                    )
                    if (defaultOption) {
                      if (defaultOptionFromQuery) {
                        this.defaultFilter[index] = defaultOptionFromQuery
                      } else {
                        this.defaultFilter[index] = defaultOption
                      }
                    }
                  } else if (defaultOption && !this.$route.query.filter) {
                    this.defaultFilter[index] = defaultOption
                  }
                }
              }
            }
          }
        })
        .finally(() => {
          this.loading[index] = false
        })
    },
    updateFiltersList (
      index: number,
      id: number,
      type: string,
      val: FilterMember | Filter
    ) {
      if (type === 'mono') {
        this.selectedFilter[index] = {
          id,
          members: (val as FilterMember).value
        }
      } else {
        this.$nextTick(() => {
          this.selectedFilter[index] = {
            id,
            members: Object.values(this.multiSelected[index]) as number[]
          }
          for (const element of document.getElementsByClassName(
            'vue-treeselect__x-container'
          )) {
            (element as HTMLElement).title = 'Lave Vessel'
          }
        })
      }
    },
    dispatchFilters () {
      if (
        this.$route.query.filter &&
        typeof this.$route.query.filter === 'string' &&
        this.$route.query.filter !==
          encodeURI(JSON.stringify(this.selectedFilter)) &&
        this.firstHandle
      ) {
        this.selectedFilter = JSON.parse(decodeURI(this.$route.query.filter))
      }
      this.firstHandle = false
      this.$router.replace({
        query: { filter: encodeURI(JSON.stringify(this.selectedFilter)) }
      })
      store.dispatch('setFiltersList', this.selectedFilter)
      let filterIndex = 0
      for (const filter of this.filters as FilterInServiceModel[]) {
        if (filter.compatibleFilters.length) {
          this.displayFilters(filter, filterIndex)
        }
        filterIndex++
      }
    }
  },
  computed: {
    filtersSelected () {
      return store.state.filters
    }
  }
})
