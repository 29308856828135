import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex justify-start gap-4 mt-4"
}
const _hoisted_2 = { class: "page-item" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center gap-4"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "flex items-center gap-4"
}
const _hoisted_6 = { class: "page-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftArrowIcon = _resolveComponent("LeftArrowIcon")!
  const _component_RightArrowIcon = _resolveComponent("RightArrowIcon")!

  return ( _ctx.totalPages > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.currentPage !== 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: _normalizeClass(_ctx.paginationButton),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click(_ctx.currentPage - 1)))
              }, [
                _createVNode(_component_LeftArrowIcon, { class: "w-5 h-5 stroke-text-alt" })
              ], 2))
            : _createCommentVNode("", true)
        ]),
        (_ctx.currentPage > 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(_ctx.paginationButton),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click(1)))
              }, "1", 2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.paginationButton)
              }, "...", 2)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerPagination, (pageNumber, j) => {
          return (_openBlock(), _createElementBlock("button", {
            key: j,
            onClick: ($event: any) => (_ctx.click(pageNumber)),
            class: _normalizeClass([{ 'bg-semantic-activate text-bg-0': _ctx.currentPage === pageNumber }, _ctx.paginationButton,])
          }, _toDisplayString(pageNumber), 11, _hoisted_4))
        }), 128)),
        (_ctx.currentPage < _ctx.totalPages - _ctx.numberOfButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.paginationButton)
              }, "...", 2),
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(_ctx.paginationButton),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.click(_ctx.totalPages)))
              }, _toDisplayString(_ctx.totalPages), 3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.currentPage < _ctx.totalPages)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.click(_ctx.currentPage + 1))),
                class: _normalizeClass(_ctx.paginationButton)
              }, [
                _createVNode(_component_RightArrowIcon, { class: "w-5 h-5 stroke-text-alt" })
              ], 2))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}