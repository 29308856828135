import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b16b238"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "position-relative",
  style: {"height":"40px","padding":"4px 0"}
}
const _hoisted_2 = ["data-popover", "data-asset-id", "data-period-id"]
const _hoisted_3 = ["data-popover", "data-asset-id", "data-period-id"]
const _hoisted_4 = ["data-popover", "data-asset-id", "data-period-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entries, (period, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        "data-popover": !period.isMissing,
        "data-asset-id": _ctx.asset_id,
        "data-period-id": period.id,
        class: "periodElement d-flex flex-column ps-1 text-no-wrap",
        style: _normalizeStyle([{"position":"absolute","top":"0","height":"38px","cursor":"pointer","border":"solid 1px white"}, [
               { left : period.draw.offsetWidth+'px' },
               { width : period.draw.lengthWidth +'px'},
               { borderTopLeftRadius: period.draw.isStartCropped ? 'none' : '5px'},
               { borderBottomLeftRadius: period.draw.isStartCropped ? 'none' : '5px'},
               { borderTopRightRadius: period.draw.isEndCropped ? '0px' : '5px'},
               { borderBottomRightRadius: period.draw.isEndCropped ? '0px' : '5px'},
               { borderLeft: period.draw.isStartCropped ? 'none' : 'solid 1px'},
               { borderRight: period.draw.isEndCropped ? 'none' : 'solid 1px'},
               {borderColor: period.status_color },
               {'--statusColor' :  period.status_color}
             ]])
      }, [
        _createElementVNode("small", {
          "data-popover": !period.isMissing,
          "data-asset-id": _ctx.asset_id,
          "data-period-id": period.id
        }, _toDisplayString(period.status), 9, _hoisted_3),
        (period.draw.periodTextLength > 3 && period.isOn )
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              "data-popover": !period.isMissing,
              "data-asset-id": _ctx.asset_id,
              "data-period-id": period.id
            }, "Daily rate:" + _toDisplayString(period.daily_rate) + "%", 9, _hoisted_4))
          : _createCommentVNode("", true)
      ], 12, _hoisted_2))
    }), 128))
  ]))
}