
import { defineComponent, PropType } from 'vue'
import { ReportServiceCategory } from '@/core/model/report.model'

export default defineComponent({
  name: 'DashboardsListMenu',
  props: {
    category: {
      type: Object as PropType<ReportServiceCategory>
    },
    loading: { type: Boolean, default: false },
    allActive: { type: Boolean, default: true },
    allActiveInCategory: { type: Boolean, default: true }
  },
  data () {
    return {
      open: false
    }
  }
})
