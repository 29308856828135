import { watchPostEffect } from 'vue'
import { Map } from 'leaflet'
import { Router } from 'vue-router'
import categoryStore from '@/map/core/bll/category/category.store'

export default {
  mounted: function (map: Map, router: Router): void {
    const setBlue = function (value :string) {
      const lastElement = document.querySelector('[data-asset-selected]') as HTMLImageElement
      if (lastElement) {
        lastElement.src = require(`@/assets/img/vessel/vessel_${lastElement.dataset.originColor}.png`)
        lastElement.removeAttribute('data-asset-selected')
      }
      const newElement = document.querySelector(`[data-asset-code='${value}']`) as HTMLImageElement

      if (newElement) {
        newElement.setAttribute('data-asset-selected', 'true')
        newElement.src = require('@/assets/img/vessel/vessel_blue.png')
      }
    }

    watchPostEffect(function () {
      const querySetBlue = router.currentRoute.value.query.setBlue as string
      if (categoryStore.state.initialized && querySetBlue) {
        console.log(querySetBlue)
        setBlue(querySetBlue)
      }
    })
  }
}
