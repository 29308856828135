import { BaseChartGenerator } from '@/core/charts/baseChart.service'
import { Content } from '@/core/charts/content.model'
import { IChartGenerator, IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { ChartOptionsModel, LegendOptions, PlotOptions, SerieData, SerieOptions } from '@/core/model/chart.model'
import { textAlt } from '@/core/services/colours.service'

class PieChart extends BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return 'pie' }

  public createChart (content: Content, options: IGeneratorOption): Partial<ChartOptionsModel> | Record<string, never> {
    const model = super.createChart(content, options)
    return model
  }

  protected createSeries (content: Content) : Partial<SerieOptions>[] {
    const series = []
    // let categoryIndex = 0
    for (const category of content.categories) {
      for (const serie of category.series) {
        const values: SerieData[] = []
        for (const value of serie.values) {
          values.push({
            name: value.label,
            y: parseFloat(value.value ?? '0'),
            color: this.getColor(value.label)
          })
        }
        // const color = this.getColor(serie.name ?? '')
        series.push({
          name: serie.name ?? '',
          type: this.Type,
          data: values,
          borderColor: 'transparent', // ColoursService.colourLightness(color, 1.8),
          borderWidth: 2
        })
      }
      // categoryIndex++
    }
    return series
  }

  // eslint-disable-next-line
  protected plotOptions (detailed: boolean, previewType?: string): PlotOptions {
    return {
      series: {
        dataSorting: {
          matchByName: false,
          enabled: false
        },
        enableMouseTracking: true,
        fillOpacity: 0.2,
        stacking: 'normal',
        opacity: 1,
        marker: { enabled: false, fillColor: 'transparent', symbol: 'circle', lineWidth: 2, lineColor: undefined },
        states: {
          inactive: { opacity: 1 }
        },
        pointPlacement: 'on'
      },
      pie: {
        dataLabels: { enabled: false, color: textAlt, style: { textOutline: 'none' } },
        innerSize: '85%',
        showInLegend: true // detailed && previewType !== 'ChartAndKPI'
      }
    }
  }

  /**
   * @override
  */
  // eslint-disable-next-line
  protected tooltipHeader (detailed: boolean): string {
    return '<table style="position: relative; z-index: 10;"><div style="display:flex; align-items:center;"><div style="background:{point.color}; height: 8px; width: 8px; min-width: 8px; border-radius: 50%; margin-right: 4px"></div><span style="margin-right: 8px">{point.key}</span><b>{point.y}</b></div>'
  }

  /**
   * @override
  */
  // eslint-disable-next-line
  protected tooltipPoint (detailed: boolean): string {
    return ''
  }

  protected chartLegend (detailed: boolean, previewType?: string): LegendOptions {
    let model = super.chartLegend(detailed, previewType)
    if (previewType !== 'ChartAndKPI' && !detailed) {
      model.enabled = true
      model = {
        ...model,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: detailed ? -20 : 0,
        y: 0,
        floating: false,
        itemWidth: detailed ? undefined : 150,
        navigation: {
          enabled: detailed
        },
        // useHTML: true, //
        labelFormat: '<span style="z-index: 1">{name} : {y}</span>'
      }
    } else if (previewType !== 'ChartAndKPI' && detailed) {
      model = {
        ...model,
        layout: 'horizontal',
        itemWidth: 210,
        width: '100%'
      }
    }
    return model
  }
}

export default new PieChart()
