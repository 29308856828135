import { reactive } from 'vue'

const state = reactive({ url: process.env.VUE_APP_MAP_TILE_LAYER ?? '' })

const mutation = {
  toggle: function (url: string) :void {
    state.url = url
  }
}
const action = {
  toggle: function (url: string):void {
    mutation.toggle(url)
  }
}
export default {
  action,
  state
}
