
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ServiceDetailTitle',
  components: {
    LeftArrowIcon
  },
  props: {
    loading: Boolean,
    error: String,
    title: String,
    description: String,
    link: String,
    params: Object,
    small: { type: Boolean, default: false }
  },
  computed: {
    formattedLink () {
      if (this.params) {
        return {
          name: this.link,
          params: this.params
        }
      }
      return { name: this.link }
    }
  },
  methods: {
    goTo () {
      if (this.$router.options.history.state.back === '/dashboards') {
        this.$router.go(-1)
      } else {
        if (this.params && Object.values(this.params!)[0] !== '0') {
          this.$router.push({
            name: this.link,
            params: this.params
          })
        } else if (this.params && Object.values(this.params!)[0] === '0') {
          this.$router.go(-1)
        } else {
          this.$router.push({ name: this.link })
        }
      }
    }
  }
})
