import { Map } from 'leaflet'
import { computed, onUnmounted, watch } from 'vue'
import categoryStore from '@/map/core/bll/category/category.store'
import { Router } from 'vue-router'
import stepStore from '@/map/core/bll/step/step.store'
import { groupStepLayers } from '@/map/core/bll/step/step.group'
import categoryManager from '@/map/core/bll/category/category.manager'
import { groupAssetLayer } from '@/map/core/bll/category/category.group'
import stepManager from '@/map/core/bll/step/step.manager'

export default {

  mounted: function (map: Map, router : Router): void {
    map.addLayer(groupStepLayers)

    const subject = computed(function () {
      return categoryStore.state.initialized && router.currentRoute.value.query.fetch ? router.currentRoute.value.query.fetch as string : '-1'
    })
    watch(subject, function (newVal:string, oldVal:string) {
      // toggle Marker, cause no marker under first step circle
      categoryManager.disabledLayer(groupAssetLayer, newVal, false)
      categoryManager.disabledLayer(groupAssetLayer, oldVal, true)

      if (newVal !== '-1') {
        stepStore.action.fetch(newVal)
      } else {
        stepStore.action.reset()
        stepManager.reset()
      }
    }, {
      flush: 'post'
    })

    onUnmounted(() => {
      groupStepLayers.remove()
    })
  }

}
