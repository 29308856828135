import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5ab62e02"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "skeleton"
}
const _hoisted_2 = {
  key: 1,
  style: {"max-width":"220px","min-width":"220px","width":"220px","height":"124px"},
  class: "overflow-hidden rounded-md flex justify-center items-center relative"
}
const _hoisted_3 = { class: "absolute font-bold text-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "absolute flex items-center bottom-1 right-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SmartphoneIcon = _resolveComponent("SmartphoneIcon")!
  const _component_TabletIcon = _resolveComponent("TabletIcon")!
  const _component_MonitorIcon = _resolveComponent("MonitorIcon")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("img", {
          src: _ctx.previewImageUrl ? _ctx.previewImageUrl : require('../../assets/img/card-dashboard.jpg'),
          alt: _ctx.title,
          class: "rounded-md"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.smartphone)
            ? (_openBlock(), _createBlock(_component_SmartphoneIcon, {
                key: 0,
                class: "w-4 h-4 fill-gray-100 mr-0.5"
              }))
            : _createCommentVNode("", true),
          (_ctx.tablet)
            ? (_openBlock(), _createBlock(_component_TabletIcon, {
                key: 1,
                class: "w-4 h-4 fill-gray-100 mr-0.5"
              }))
            : _createCommentVNode("", true),
          (_ctx.laptop)
            ? (_openBlock(), _createBlock(_component_MonitorIcon, {
                key: 2,
                class: "w-4 h-4 fill-gray-100"
              }))
            : _createCommentVNode("", true)
        ])
      ]))
}