import { ApiClient } from '@/map/core/dal/apiClient'
import { GlobalStore } from '@/main'
import { GetMapKPIs, MapKpiModel } from '@/map/core/bll/kpi/kpi.model'
import { MapKpiMapper } from '@/map/core/bll/kpi/kpi.mapper'

export const KpiManager = {
  list (categoryId: number, assetId: number): Promise<MapKpiModel[]> {
    GlobalStore.mapError = ''
    return new Promise((resolve, reject) => {
      ApiClient.list<GetMapKPIs>(`Fleet/categories/${categoryId}/assets/${assetId}`).then(result => {
        resolve(MapKpiMapper(result))
      }).catch((err:string) => {
        GlobalStore.mapError = err
        reject(err)
      })
    })
  }
}
