import { GetMapKPI, GetMapKPIs, MapKpiModel } from '@/map/core/bll/kpi/kpi.model'

function previewsInfo (kpi: GetMapKPI): any {
  return kpi.previewType === 'Chart'

    ? kpi.chart

    : kpi.previewType === 'Kpi' ||

    kpi.previewType === 'KPI'

      ? kpi.kpi

      : kpi.previewType === 'ChartAndKPI'

        ? [kpi.chart, kpi.kpi]

        : kpi.previewType === 'Custom'

          ? kpi.customHtml

          : {}
}

export function MapKpiMapper (kpiList: GetMapKPIs) : MapKpiModel[] {
  return kpiList.services.map(kpi => {
    return {
      id: kpi.id,
      title: kpi.title,
      subTitle: kpi.text,
      graphType: kpi.chart ? kpi.chart.type : null,
      previewType: kpi.previewType,
      previewInfos: previewsInfo(kpi),
      desc: kpi.text,
      kpiTitle: kpi.kpi && kpi.previewType.toLowerCase().indexOf('kpi') > -1 ? kpi.kpi.label : ''
    }
  })
}
