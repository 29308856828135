
import DashboardCard from '@/components/cards/DashboardCard.vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ReportGroupHeader from '@/components/reports/categories/services/reports/headers/ReportGroupHeader.vue'
import ServiceReport from '@/components/reports/categories/services/reports/ServiceReport.vue'
import ServiceCardContent from '@/components/cards/ServiceCardContent.vue'
import Filters from '@/components/Filters/ServiceFilters.vue'
import ServiceDetailReportsAndDashboards from '@/components/titles/ServiceDetailReportsAndDashboards.vue'
import ServiceDetailTitle from '@/components/titles/ServiceDetailTitle.vue'
import * as CategoryManager from '@/core/managers/category.manager'
import { ServiceModel } from '@/core/model/service.model'
import Tools from '@/core/services/tools'
import { PAGE_TITLE } from '@/router'
import { defineComponent, inject, reactive, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { ILogger } from '@/core/services/loggers/ilogger.service'
import { CategoriesListModel } from '@/core/model/category.model'

export default defineComponent({
  components: {
    Filters,
    ServiceCardContent,
    ServiceDetailTitle,
    GenericCardShell,
    DashboardCard,
    ServiceDetailReportsAndDashboards,
    ReportGroupHeader,
    ServiceReport
    // AssetReport
  },
  name: 'Service.detail.vue',
  setup () {
    const toast = useToast()
    const loading = ref(true)
    const detail: ServiceModel = reactive({} as ServiceModel)
    const error = ref('')
    return {
      toast,
      loading,
      detail,
      error,
      logger: inject('ILogger') as ILogger
    }
  },
  mounted () {
    if (!this.$store.state.list.length) {
      CategoryManager.Manager.list().then((response) => {
        if (response.isError) {
          this.error =
            'No services available at the moment. Please try again later'
          this.toast.error(this.error)
        } else {
          this.$store.dispatch(
            'setServiceList',
            (response.data as CategoriesListModel).categories
          )
        }
      })
    }
    this.$nextTick(() => {
      const serviceId = parseInt(this.$route.params.analyticsId as string)

      CategoryManager.Manager.detail(serviceId).then((response) => {
        this.loading = false
        if (response.isError) {
          this.error = this.$t('analytics.error.detail')
          this.toast.error(this.error)
        } else {
          this.detail = response.data as ServiceModel
          Tools.updatePageName(
            PAGE_TITLE[this.$route.name as string],
            this.detail.title
          )

          this.logger.logEvent({
            name: 'UserNavigation',
            properties: {
              contextName: 'Analytics',
              categoryName: '',
              subcategoryName: this.detail.title,
              ActionName: 'view'
            }
          })
        }
      })
    })
  },
  computed: {
    analyticsCategory () {
      for (const category of this.$store.state.list) {
        const cat = category.services.find(
          (category) =>
            category.id === parseInt(this.$route.params.analyticsId as string)
        )
        if (cat) {
          return category.name
        }
      }
      return ''
    }
  }
})
