import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center p-1 bg-bg-0 rounded-md w-fit body-3 select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoomInIcon = _resolveComponent("ZoomInIcon")!
  const _component_ZoomOutIcon = _resolveComponent("ZoomOutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ZoomInIcon, {
      class: "cursor-pointer text-text-alt mr-2 rounded-md p-1 active:bg-semantic-activate focus:bg-semantic-activate",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('zoom', 'in')))
    }),
    _createVNode(_component_ZoomOutIcon, {
      class: _normalizeClass(["text-gray-500 rounded-md p-1", {'cursor-pointer !text-text-alt active:bg-semantic-activate focus:bg-semantic-activate' : _ctx.zoomLevel > 40}]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('zoom', 'out')))
    }, null, 8, ["class"])
  ]))
}