import { GetStepModel, StepModel } from '@/map/core/bll/step/step.model'
import { ApiClient } from '@/map/core/dal/apiClient'
import { divIcon, LatLng, latLng, marker, polyline } from 'leaflet'
import { DateTime } from 'luxon'
import { groupStepLayers } from '@/map/core/bll/step/step.group'

const fetch = function (assetCode : string): Promise<StepModel[]> {
  return new Promise((resolve, reject) => {
    // api/Position/history/:id
    ApiClient.list<GetStepModel[]>('map/Position/history/' + assetCode).then(result => {
      const list : StepModel[] = []
      const coordinates : LatLng[] = [] // for making Polyline
      groupStepLayers.clearLayers()
      const markerDim = 6
      result.forEach((step, index) => {
        const _latLng = latLng([step.latitude, step.longitude])
        const anchor = marker(_latLng, {
          riseOnHover: true,
          icon: divIcon({
            html: `<img data-step-index="${index}" data-popover-type="step" src="${require('@/assets/img/step_white.png')}" alt="step_${index}" style="width: ${markerDim + 'px'} ; height: ${markerDim + 'px'}" >`,
            className: 'bg-transparent ',
            iconAnchor: [markerDim / 2, markerDim / 2]
          })
        })
        groupStepLayers.addLayer(anchor)
        coordinates.push(_latLng)
        list.push({
          markerCode: assetCode,
          query: {
            assetPopoverAdd: undefined,
            stepPopoverAdd: undefined,
            flyToBounds: undefined,
            staticStep: index.toString()
          },
          index, // popover arrow
          utcTime: DateTime.fromISO(step.utcPositionTime, { zone: 'UTC' }).toFormat('LLL dd, yyyy, HH:mm ') + '(UTC)', // popover
          speed: step.speed, // popover
          heading: step.heading, // popover
          latLng: _latLng, // popover
          stroke: 2,
          svgDim: 50,
          markerDim,
          radius: index === 0 ? 20 : 6,
          first: index === 0, // popover arrow
          last: index === result.length - 1 // popover arrow
        })
      })
      groupStepLayers.addLayer(polyline(coordinates, { color: 'white', weight: 1, interactive: false }))
      resolve(list)
    }).catch((err:string) => {
      reject(err)
    })
  })
}
const reset = function () {
  groupStepLayers.clearLayers()
}
export default {
  fetch,
  reset
}
