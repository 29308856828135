import { BaseChartGenerator } from '@/core/charts/baseChart.service'
import { Category, Content } from '@/core/charts/content.model'
import { IChartGenerator, IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { ChartOptionsModel, PlotOptions, SerieOptions, yAxisOptions } from '@/core/model/chart.model'

class HorizontalBarChart extends BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return 'bar' }

  public createChart (content: Content, options: IGeneratorOption): Partial<ChartOptionsModel> | Record<string, never> {
    const model = super.createChart(content, options)
    if (model.chart) {
      model.chart.type = 'bar'
    }
    return model
  }

  // eslint-disable-next-line
  protected createYOptions (series: Partial<SerieOptions>[], categories: Category[], detailed: boolean): Partial<yAxisOptions>[] {
    return [{
      min: 0
    }]
  }

  protected plotOptions (detailed: boolean, previewType?: string) : PlotOptions {
    const model = super.plotOptions(detailed, previewType)
    if (model.series) {
      model.series.stacking = 'normal'
    }
    return model
  }
}

export default new HorizontalBarChart()
