
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ServiceDetailReportsAndDashboards',
  props: {
    title: String,
    subtitle: String
  }
})
