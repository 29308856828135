
import { defineComponent, inject, PropType } from 'vue'

import { IAssetFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { EntryAssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'

export default defineComponent({
  name: 'timeLineRowLeft',
  components: {
    // timelineCol
  },
  props: {
    asset_id: {
      type: Number,
      required: true
    },
    entries: {
      type: Array as PropType<EntryAssetRow[]>,
      required: true
    }
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  }
})
