import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IEntriesForAssetMapper } from '@/core/managers/entriesForAsset/entriesForAssetMapper'
import { AssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'
import { APIEntriesForAsset } from '@/core/managers/entriesForAsset/entriesForAssetApi'

export interface IEntriesForAssetManager {
  fetch:(contractId: string, signal : AbortSignal)=> Promise<AssetRow[]>
}
// todo test UI with no data into array
export const CreateEntriesForAssetManager = function (httpProvider: IHttpProvider, periodMapper: IEntriesForAssetMapper): IEntriesForAssetManager {
  return {
    fetch: async function (contractId: string, signal : AbortSignal): Promise<AssetRow[]> {
      const str = process.env.VUE_APP_URL + `planning/contracts/${contractId}/periods`
      const result = await httpProvider.get<APIEntriesForAsset>(str, signal)
      return periodMapper.fetchDecoupling(result)
    }
  }
}
