
import * as FleetManager from '@/core/managers/fleet.manager'
import { FleetCategoryModel } from '@/core/model/fleet.model'
import { defineComponent } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'FleetTabs',
  setup () {
    const toast = useToast()
    return { toast }
  },
  data (): { tabs: FleetCategoryModel[]; error: string } {
    return {
      tabs: [],
      error: ''
    }
  },
  created () {
    FleetManager.Manager.categories().then((response) => {
      if (!response.isError && response.data) {
        this.tabs = response.data.categories
        this.tabs = this.tabs.map((tab) => ({ ...tab, displayed: false }))
        if (this.$route.params && this.$route.params.categoryId) {
          const indexInTabs = this.tabs.findIndex((tab) => tab.id === +this.$route.params.categoryId)
          this.selectTab(indexInTabs || 0)
        } else {
          this.selectTab(0)
        }
      } else {
        this.error = `${this.$t('fleet.errorCategory')}${response.Message}`
        this.toast.error(this.error)
      }
    })
  },
  computed: {
    tabGroupClass () {
      return 'flex w-full pt-3 text-gray-100 font-bold overflow-x-scroll no-scroll relative'
    },
    tabBottomClass () {
      return 'absolute w-full h-0.5 bg-bg-0 bottom-0 z-1'
    },
    tabClass () {
      return 'px-4 pb-3 border-b-2 border-bg-0 relative z-50'
    }
  },
  methods: {
    selectTab (index: number) {
      for (const tab of this.tabs) {
        tab.displayed = false
      }
      this.tabs[index].displayed = true
      sessionStorage.setItem('fleetTab', index.toString())
      this.$router.replace({ params: { categoryId: `${this.tabs[index].id}` } })
      this.$emit('tab-selected', this.tabs[index])
    }
  }
})
