
import { duration } from '@/map/core/utilities'
import { defineComponent } from 'vue'
import categoryStore from '@/map/core/bll/category/category.store'
import wifiIcon from '@/components/icons/WifiIcon.vue'
import router from '@/router'
import { AssetModel } from '@/map/core/bll/category/categoryModel'

export default defineComponent({
  watch: {
    list: {
      immediate: true,
      handler (list:AssetModel[]) {
        if (list && list.length > 0) {
          // todo check why this implementation ?
          this.trackList = JSON.parse(JSON.stringify(list))
        }
      }
    }
  },
  components: {
    wifiIcon
  },

  computed: {
    list () { return categoryStore.state.list },
    loading () { return categoryStore.state.loading }
  },
  data () {
    return { trackList: [] as AssetModel[] }
  },
  mounted () {
    document.addEventListener('keydown', this.onEnterKeyPress)
  },

  methods: {
    onSearch (e:Event) {
      const input = (e.target as HTMLInputElement).value
      if (input !== '') {
        this.trackList = this.list.filter(item => item.assetName.toLowerCase().indexOf((e.target as HTMLInputElement).value.toLowerCase()) > -1)
      } else {
        this.trackList = this.list.filter(item => item)
      }
    },
    durationStr (lastPosition: string): string {
      return duration(lastPosition)
    },
    select (assetCode:string) {
      const asset = this.trackList.find(item => item.assetCode === assetCode)

      if (asset) {
        if (!asset.checked) {
          categoryStore.action.update(assetCode, true)
        }
        router.push({ query: { ...asset.query, flyTo: assetCode } })
      }
    },
    onClick (e:Event) {
      const elementLI = (e.currentTarget as HTMLElement)
      this.select(elementLI.dataset.itemId as string)
    },
    onEnterKeyPress (e:KeyboardEvent) {
      if (e.key === 'Enter') {
        const elementUL = (((e.target as HTMLElement).parentElement as HTMLElement).parentElement as HTMLElement).querySelector('ul')
        const elementLI = (elementUL as HTMLElement).querySelector('.selectedAsset > a') as HTMLElement
        if (elementLI) {
          this.select(elementLI.dataset.itemId as string)
        }
      }
      if (e.key === 'Escape') {
        this.$router.push({ query: { ...this.$route.query, popover: undefined } })
      }
      if (e.key === 'Tab') {
        e.preventDefault()
      }
    }
  }
})

