import { reactive } from 'vue'
import { CategoryZoneModel, ZoneModel } from '@/map/core/bll/zone/zone.model'
import zoneManager from '@/map/core/bll/zone/zone.manager'
import { LatLng } from 'leaflet'

export const state = reactive({ list: [] as ZoneModel[], group: [] as CategoryZoneModel[], initialized: false, loading: false, error: '' })

const mutation = {

  loading: (value : boolean) => {
    state.loading = value
  },
  success: (result : { list :ZoneModel[], group : CategoryZoneModel[] }) => {
    state.list = result.list
    state.group = result.group
    state.initialized = true
  },
  error: function (value : string) {
    state.error = value
  },
  update: (zoneCode : string, value : boolean) => {
    state.list = state.list.map(o => {
      return { ...o, checked: o.code === zoneCode ? value : o.checked }
    })
  }
}

const action = {
  fetch: function (): void {
    mutation.error('')
    mutation.loading(true)
    zoneManager.fetch().then(result => {
      mutation.success(result)
    }).catch(err => {
      mutation.error(err)
    }).finally(() => {
      mutation.loading(false)
    })
  },
  update: function (args: { code: string, value : boolean }): void {
    mutation.update(args.code, args.value)
  }
}
const getter = {
  noCategory: (): boolean => {
    return state.group.length === 0
  },
  atLeastOneCategory: (): boolean => {
    return state.group.length > 1
  },

  zoneActives: (): ZoneModel[] => {
    return state.list.filter(x => x.checked)
  },
  activeBound: (): LatLng[] => {
    return getter.zoneActives().reduce((x: LatLng[], y: ZoneModel) => x.concat(y.coordinates), [])
  }
}

export default {
  action,
  getter,
  state
}
