import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cardView ? 'FleetCardCardView' : 'FleetCardListView'), {
    onSeeAssetDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.seeAssetDetails(_ctx.$e))),
    onChangeFavStatus: _ctx.changeFavStatus,
    key: _ctx.id,
    id: _ctx.id,
    name: _ctx.name,
    type: _ctx.type,
    course: _ctx.course,
    lastCourseUpdate: _ctx.lastCourseUpdate,
    length: _ctx.length,
    speed: _ctx.speed,
    imo: _ctx.imo,
    mmsi: _ctx.mmsi,
    isFav: _ctx.isFav,
    categoryId: _ctx.categoryId,
    loadingCard: _ctx.loadingCard,
    imgUrl: _ctx.imgUrl
  }, null, 40, ["onChangeFavStatus", "id", "name", "type", "course", "lastCourseUpdate", "length", "speed", "imo", "mmsi", "isFav", "categoryId", "loadingCard", "imgUrl"]))
}