import Tools from '@/core/services/tools'
import { CategoryModel } from '@/core/model/category.model'
export default function handleFilters (): {
  isVisible: (category: CategoryModel, categories: CategoryModel[]) => void;
  isOnceSelected: (categories: CategoryModel[]) => void
  } {
  function isVisible (category: CategoryModel, categories: CategoryModel[]): boolean {
    return (
      !Tools.isOnceSelected(categories) ||
      (Tools.isOnceSelected(categories) && category.active)
    )
  }
  function isOnceSelected (categories: CategoryModel[]) {
    return Tools.isOnceSelected(categories)
  }
  return { isVisible, isOnceSelected }
}
