import { APIEntriesForMonth } from '@/core/managers/entriesForMonth/entriesForMonthApi'
import { ICalendarEntry, IEntriesForMonth } from '@/core/managers/entriesForMonth/entriesForMonthModel'

export interface IEntriesForMonthMapper {
  fetchDecoupling: (item:APIEntriesForMonth)=> IEntriesForMonth
}

export const CreateEntriesForMonthMapper = function (): IEntriesForMonthMapper {
  // todo remove helper parseDate
  return {
    fetchDecoupling: function (item:APIEntriesForMonth):IEntriesForMonth {
      return {
        contractId: item.contractId,
        assetId: item.assetId,
        entries: item.entries.reduce((list:ICalendarEntry[], entry) => {
          list.push({
            daily_rate: entry.daily_rate,
            editable: entry.editable,
            deletable: entry.deletable,
            start_date: new Date(entry.start_date.slice(0, 19)),
            end_date: new Date(entry.end_date.slice(0, 19)),
            reason: entry.reason,
            status: entry.status,
            isOff: entry.status.toLowerCase().indexOf('off') > -1,
            isOn: entry.status.toLowerCase().indexOf('on') > -1,
            isMissing: entry.status.toLowerCase().indexOf('missing') > -1,
            status_color: entry.status_color,
            id: entry.id
          } as ICalendarEntry)
          return list.sort((a, b) => b.start_date.getTime() - a.start_date.getTime())
        }, [])
      } as IEntriesForMonth
    }
  }
}
