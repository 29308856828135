import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "block cursor-pointer my-2" }
const _hoisted_2 = { class: "flex items-center text-gray-200" }
const _hoisted_3 = {
  key: 0,
  class: "fa fa-spin fa-spinner lr-2"
}
const _hoisted_4 = { class: "flex flex-col ml-2" }
const _hoisted_5 = {
  class: "text-md text-ellipsis overflow-hidden inline-block whitespace-nowrap",
  style: {"width":"150px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = event => _ctx.onChange(_ctx.asset,!event.target.checked)),
      style: {"display":"none"}
    }, null, 32),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.asset.pending)
        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["fa-lg", {' fa-solid  fa-square-check' : _ctx.asset.checked, 'fa-regular text-slate-200 fa-square' : !_ctx.asset.checked}])
          }, null, 2)),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.asset.assetName), 1)
      ])
    ])
  ]))
}