import { Category, Content } from '@/core/charts/content.model'
import { IChartGenerator, IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { ChartOptionsModel, ExportingOptions, LegendOptions, NavigationOptions, PlotOptions, SerieOptions, TooltipOptions, xAxisOptions, yAxisOptions } from '@/core/model/chart.model'
import ColoursService, { bg0, bg2, textAlt, textGray400, textGray600, textPrim, textWhite, semanticActivate } from '@/core/services/colours.service'
import router from '@/router'
import FormatService from '../services/format.service'

export const chartTypes = {
  bar: 'column',
  line: 'spline',
  pie: 'pie',
  LINE: 'spline',
  PIE: 'pie',
  BAR: 'column',
  'H-BAR': 'column',
  BARXLINE: 'BARXLINE'

}
// const columnCharts = [chartTypes.bar, chartTypes['H-BAR'], chartTypes.BAR]

export const lineCharts = [chartTypes.line, chartTypes.LINE]

export const pieCharts = [chartTypes.pie, chartTypes.PIE]

export abstract class BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return 'column' }
  protected get OverviewType (): string { return this.Type }
  protected get DefaultFont (): string { return 'Arial' }

  public createChart (content: Content, options: IGeneratorOption): Partial<ChartOptionsModel> | Record<string, never> | any {
    const data = this.createSeries(content, options.isDetailed)
    const yAxisOptions = this.createYOptions(data, content.categories, options.isDetailed)
    return {
      chart: {
        zoomType: options.isDetailed ? 'x' : null,
        panning: true,
        panKey: 'shift',
        resetZoomButton: {
          theme: {
            fill: semanticActivate,
            stroke: semanticActivate,
            style: {
              color: bg0,
              fontWeight: 'bold'
            },
            r: 5,
            states: {
              hover: {
                fill: '#afd2fc',
                stroke: '#afd2fc',
                style: {
                  color: bg0
                }
              }
            }
          }
        },
        height: options.height,
        backgroundColor: 'transparent',
        style: {
          fontFamily: this.DefaultFont
        },
        margin: this.defineMargins(options.isDetailed, options.previewType)
      },
      lang: { noData: 'No Data Available' },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: textAlt
        }
      },
      title: {
        text: options.isDetailed ? options.title : null,
        style: { color: textPrim, fontWeight: 'bold' },
        margin: 50
      },
      subtitle: {
        text: `${options.isDetailed ? options.description : ''}<br/>${options.isDetailed && !pieCharts.includes(chartTypes[this.Type as keyof typeof chartTypes]) ? (document.ontouchstart === undefined ? 'Click and drag in the plot area to zoom in. Hold down shift key to pan.' : 'Pinch the chart to zoom in') : ''}`,
        style: { color: textAlt }
      },
      tooltip: this.tooltip(options.isDetailed),
      plotOptions: this.plotOptions(options.isDetailed, options.previewType),
      legend: this.chartLegend(options.isDetailed, options.previewType),
      dataLabels: { enabled: options.isDetailed },
      xAxis: this.xAxis(content.xAxis, options.isDetailed, options.isDetailed ? options.title : undefined),
      series: data,
      yAxis: yAxisOptions,
      credits: {
        enabled: false
      },
      exporting: this.exporting(options.isDetailed),
      navigation: this.navigation()
    }
  }

  public createGaugeFromKpi (title: string, value: string, unit: string, type: string, detailed: boolean): Partial<ChartOptionsModel> {
    const gaugeOptions = {
      chart: {
        type: type === 'SOLID_GAUGE' ? 'solidgauge' : 'gauge',
        backgroundColor: 'transparent',
        height: type === 'SOLID_GAUGE' ? '125px' : '195px',
        margin: [0, 0, 0, 0]
      },
      title: false,
      pane: {
        startAngle: type === 'SOLID_GAUGE' ? 0 : -90,
        endAngle: type === 'SOLID_GAUGE' ? 360 : 90,
        background: {
          backgroundColor: `${ColoursService.getColour(title)}4D`,
          innerRadius: type === 'SOLID_GAUGE' ? '60%' : '95%',
          outerRadius: '100%',
          shape: type === 'SOLID_GAUGE' ? 'circle' : 'arc',
          borderWidth: 0
        }
      },
      exporting: { enabled: false },
      tooltip: this.tooltip(detailed, true),
      plotOptions: {
        dataLabels: {
          allowOverlap: true
        },
        solidgauge: {
          dataLabels: {
            y: -16,
            x: 0,
            style: { fontSize: '16px', color: textAlt },
            z: 6
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        },
        gauge: {
          dial: {
            backgroundColor: '#fff',
            baseWidth: 4,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: '#fff',
            radius: 1
          },
          dataLabels: {
            y: 10,
            x: 0,
            style: { fontSize: '12px', color: textAlt },
            z: 6
          }
        }
      },
      yAxis: {
        min: 0,
        max: type === 'SOLID_GAUGE' ? 100 : 20,
        title: {
          text: type === 'SOLID_GAUGE' ? '' : type === 'GAUGE' ? 'Kn' : unit,
          y: 1,
          style: { fontSize: '10px', color: textAlt, fontWeight: 'bold' }
        },
        stops: type === 'SOLID_GAUGE'
          ? undefined
          : [
              [0.33, '#B9E7E2'],
              [0.66, '#339C8F'],
              [1.0, '#22685F']
            ],
        lineWidth: 0,
        tickWidth: type === 'SOLID_GAUGE' ? 0 : 1,
        minorTickWidth: type === 'SOLID_GAUGE' ? 0 : 1,
        minorTickInterval: type === 'SOLID_GAUGE' ? null : 5,
        tickAmount: 0,
        labels: {
          enabled: false
        },
        plotBands: type === 'SOLID_GAUGE'
          ? { enabled: false }
          : [{
              from: 0,
              to: 6.6,
              color: '#B9E7E2',
              thickness: '10%'
            }, {
              from: 6.6,
              to: 13.2,
              color: '#339C8F',
              thickness: '10%'
            }, {
              from: 13.1,
              to: 20,
              color: '#22685F',
              thickness: '10%'
            }]
      },
      credits: {
        enabled: false
      },
      series: [{
        name: title,
        data: [{ color: ColoursService.getColour(title), y: parseFloat(value) }],
        dataLabels: {
          format: unit ? `{y} ${unit}` : '{y}',
          borderWidth: 0,
          color: textPrim,
          style: {
            fontFamily: this.DefaultFont,
            textOutline: 'none'
          },
          enabled: type === 'SOLID_GAUGE'
        },
        tooltip: {
          valueSuffix: ` ${unit}`
        }
      }]
    }
    return gaugeOptions
  }

  protected createSeries (content: Content, detailed?: boolean): Partial<SerieOptions>[] {
    const series = []
    const finalType = detailed ? this.Type : this.OverviewType
    let categoryIndex = 0
    for (const category of content.categories) {
      for (const serie of category.series) {
        const values: any[] = []
        for (const label of content.xAxis) {
          const value = serie.values.find(v => v.label === label)?.value
          values.push({
            y: value ? parseFloat(value) : null
          })
        }
        const color = ColoursService.getColour(serie.name ?? '')
        series.push({
          name: serie.name ?? '',
          type: finalType,
          data: values,
          yAxis: categoryIndex,
          color,
          borderColor: ColoursService.colourLightness(color, 1.8),
          borderWidth: 1
        })
      }
      categoryIndex++
    }
    return series
  }

  protected getColor (label: string): string {
    return ColoursService.getColour(label)
  }

  protected tooltip (detailed: boolean, gauge = false): TooltipOptions {
    return {
      enabled: true,
      crosshairs: !gauge,
      shared: true,
      backgroundColor: bg0,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: textGray600,
      padding: detailed ? 15 : !detailed && router.currentRoute.value.name !== 'assetDetail' ? 5 : 1,
      style: { color: textWhite, fontFamily: this.DefaultFont, fontSize: detailed ? '12px' : '12px' },
      useHTML: true,
      headerFormat: this.tooltipHeader(detailed),
      pointFormat: this.tooltipPoint(detailed),
      footerFormat: '</table>'
    }
  }

  protected tooltipHeader (detailed: boolean, gauge = false): string {
    return `<table style="position: relative; z-index: 10"><tr><th colspan="2" style="text-align:center ; color: ${textAlt}; padding-bottom: ${detailed ? '12px' : !detailed && gauge ? '2px' : '6px'}">{point.key}</th></tr>`
  }

  protected tooltipPoint (detailed: boolean, gauge = false): string {
    return `<tr><td style="display: flex; align-items: center; padding-right: ${detailed ? '12px' : !detailed && gauge ? '2px' : '6px'}; overflow: hidden; whitespace: nowrap;"><div style="background:{point.color}; height: 8px; width: 8px; min-width: 8px; border-radius: 50%; margin-right: 4px"></div> {series.name} </td>` +
    '<td style="text-align: right"><b>{point.y}</b></td></tr>'
  }

  // eslint-disable-next-line
  protected plotOptions (detailed: boolean, previewType?: string): PlotOptions {
    return {
      series: {
        dataSorting: {
          matchByName: false,
          enabled: false
        },
        enableMouseTracking: true,
        fillOpacity: 0.2,
        stacking: '',
        opacity: 1,
        marker: { enabled: false, fillColor: 'transparent', symbol: 'circle', lineWidth: 2, lineColor: undefined },
        states: {
          inactive: { opacity: 1 }
        },
        pointPlacement: detailed ? undefined : 'on'
      }
    }
  }

  protected chartLegend (detailed: boolean, previewType?: string): LegendOptions {
    const genericLegend = {
      enabled: (detailed && previewType !== 'ChartAndKPI'),
      itemHoverStyle: { color: textWhite },
      itemStyle: { color: textPrim, lineHeight: !detailed ? '15px' : '15px', fontFamily: this.DefaultFont, fontSize: detailed ? '12px' : '10px', fontWeight: !detailed ? 'lighter' : 'bold' },
      maxHeight: detailed ? 60 : 40,
      itemWidth: detailed ? 210 : undefined
    }
    return genericLegend
  }

  // eslint-disable-next-line
  protected xAxis (XAxis: string[], detailed: boolean, title?: string): xAxisOptions | any {
    const noPadding =
    detailed
      ? {}
      : {
          minPadding: 0,
          maxPadding: 0
        }

    const formattedXAxis = XAxis.map((value) => FormatService.formatDate(value))

    return {
      ...noPadding,
      categories: !pieCharts.includes(chartTypes[this.Type as keyof typeof chartTypes]) ? formattedXAxis : XAxis,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      lineWidth: detailed ? 1 : 0,
      tickPixelInterval: 20,
      labels: {
        allowOverlap: true,
        enabled: detailed,
        style: { color: textAlt }
      },
      crosshair: { width: 1 }
    }
  }

  protected createYOptions (series: Partial<SerieOptions>[], categories: Category[], detailed: boolean): Partial<yAxisOptions>[] {
    const yAxisOptions = []
    let yAxisOptionIndex = 0
    for (const serie of series) {
      const yAxisOption = {
        title: { enabled: false },
        lineWidth: 0,
        minorGridLineWidth: detailed ? 1 : 0,
        gridLineWidth: (detailed && categories.length > 1) || (detailed && categories.length === 1 && yAxisOptionIndex === 0) ? 1 : 0,
        gridLineColor: 'rgba(255,255,255,0.2)',
        labels: {
          enabled: detailed && categories.length > 0,
          format: categories.length > 1 ? `{value} ${serie.name}` : '',
          showLastLabel: true,
          style: { color: textAlt },
          autoRotation: true
        },
        type: serie.type,
        opposite: categories.length > 1 ? yAxisOptionIndex !== 0 : false,
        yAxis: (categories.length === 1) ? 0 : yAxisOptionIndex,
        stackedLabels: {
          enabled: true
        },
        min: 0,
        minRange: 2
      }
      yAxisOptions.push(yAxisOption)
      yAxisOptionIndex++
    }
    return yAxisOptions
  }

  protected exporting (detailed: boolean): ExportingOptions {
    const buttonList = ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
    return {
      enabled: detailed,
      style: { fontFamily: this.DefaultFont },
      chartOptions: {
        chart: {
          backgroundColor: bg2,
          plotBackgroundImage: ''
        }
      },
      buttons: {
        contextButton: {
          menuItems: buttonList
        }
      }
    }
  }

  protected navigation (): NavigationOptions {
    return {
      buttonOptions: {
        theme: {
          backgroundColor: 'transparent',
          fill: 'transparent',
          states: {
            hover: {
              stroke: textGray400,
              fill: 'transparent'
            },
            select: {
              stroke: textGray400,
              fill: 'transparent'
            }
          },
          fontFamily: this.DefaultFont
        }
      }
    }
  }

  protected defineMargins (detailed: boolean, previewType?: string): number[] | undefined {
    return (detailed || (!detailed && pieCharts.includes(chartTypes[this.Type as keyof typeof chartTypes]) && previewType !== 'ChartAndKPI'))
      ? undefined
      : [0, 0, 0, 0]
  }
}
