import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IEntriesForMonth } from '@/core/managers/entriesForMonth/entriesForMonthModel'
import { IEntriesForMonthMapper } from '@/core/managers/entriesForMonth/entriesForMonthMapper'
import { APIEntriesForMonth } from '@/core/managers/entriesForMonth/entriesForMonthApi'

export interface IEntriesForMonthManager {
  init:IEntriesForMonth
  fetch:(contractId: string, assetId: string, signal : AbortSignal)=> Promise<IEntriesForMonth>
}
// todo test UI with no data into array
export const CreateEntriesForMonthManager = function (httpProvider: IHttpProvider, periodMapper: IEntriesForMonthMapper): IEntriesForMonthManager {
  return {
    init: {
      contractId: -1,
      assetId: -1,
      entries: []
    },
    fetch: async function (contractId: string, assetId: string, signal : AbortSignal): Promise<IEntriesForMonth> {
      const str = process.env.VUE_APP_URL + `planning/contracts/${contractId}/assets/${assetId}/periods`
      const result = await httpProvider.get<APIEntriesForMonth>(str, signal)
      return periodMapper.fetchDecoupling(result)
    }
  }
}
