import { Map, tileLayer } from 'leaflet'
import { computed, onUnmounted, watch } from 'vue'
import tileStore from '@/map/core/bll/map/tile.store'

const tile = tileLayer(tileStore.state.url) // no need immediate on watcher

export default {
  mounted: function (map: Map): void {
    map.addLayer(tile)
    const subject = computed(function ():string {
      return tileStore.state.url
    })

    watch(subject, function (value:string) {
      tile.setUrl(value)
    })
    onUnmounted(() => tile.remove())
  }
}
