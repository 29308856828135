import { ApiClient } from '@/map/core/dal/apiClient'
import { GlobalStore } from '@/main'

export interface ReportSubscriptionRowAssetModel {
  assetId: number
  assetName: number
  checked: boolean
  pending?: boolean
}
export interface ReportSubscriptionRowModel {
  attributeId: number
  order: number
  checked?: boolean
  pending?: boolean
  assets?: ReportSubscriptionRowAssetModel[]
}
export interface IdNameModel{
  id: number
  name :string
}

export const Report2Manager = {
  getAttributeAll (): Promise<{id: number, name : string}[]> {
    GlobalStore.msgError = ''
    return new Promise((resolve, reject) => {
      ApiClient.list<IdNameModel[]>('/services/Reports/Attributes/All').then(result => {
        resolve(result)
      }).catch((err:string) => {
        GlobalStore.msgError = err
        reject(err)
      })
    })
  },
  getSubscription (reportId: number): Promise<ReportSubscriptionRowModel[]> {
    GlobalStore.msgError = ''
    return new Promise((resolve, reject) => {
      ApiClient.list<ReportSubscriptionRowModel[]>(`/reportSubscription/${reportId}`).then(result => {
        resolve(result)
      }).catch((err:string) => {
        GlobalStore.msgError = err
        reject(err)
      })
    })
  },
  updateSubscription (params: string, body: { checked: boolean}): Promise<void> {
    GlobalStore.msgError = ''
    return new Promise((resolve, reject) => {
      ApiClient.put(`/reportSubscription/${params}`, body).then(() => {
        resolve()
      }).catch((err:string) => {
        GlobalStore.msgError = err
        reject(err)
      })
    })
  },
  deleteReport (reportId: string, instanceId: number): Promise<void> {
    GlobalStore.msgError = ''
    return new Promise((resolve, reject) => {
      // /api/Asset/details/:id
      ApiClient.delete(`services/reports/${reportId}/instance/${instanceId}`).then(() => {
        resolve()
      }).catch((err:string) => {
        GlobalStore.msgError = err
        reject(err)
      })
    })
  }
}
