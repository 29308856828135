
import { defineComponent, PropType } from 'vue'
import { Attribute } from '@/core/model/report.model'

export default defineComponent({
  name: 'ReportAttributeTab',
  props: {
    attributes: { type: Array as PropType<Attribute[]>, required: true }
  },
  data () {
    return {
      selectedAttributeTabIndex: 0,
      attributeId: 0 as number|undefined
    }
  },
  created () {
    /*   if (this.attributes.length > 1) {
      this.selectAll()
    } else  */
    if (this.attributes.length > 0) {
      this.selectAttribute(0, this.attributes[0].id)
    }
    /**/
  },
  methods: {
    selectAttribute (index: number, attributeId: number) {
      this.selectedAttributeTabIndex = index
      this.attributeId = attributeId
      this.updateSelectedAttribute()
    },
    /* selectAll () {
      this.selectedAttributeTabIndex = -1
      this.attributeId = undefined
      this.updateSelectedAttribute()
    }, */
    updateSelectedAttribute () {
      console.log(this.attributeId)
      this.$emit('updateSelectedAttribute', this.attributeId)
    }
  }
})
