<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 96">
    <path
      class="st-0"
      d="M0 2v92c0 1.1.9 2 2 2h68c1.1 0 2-.9 2-2V28H50c-1.1 0-2-.9-2-2V0H2C.9 0 0 .9 0 2z"
    />
    <path class="st-0" d="M71.9 24c-.1-.4-.2-.7-.4-1L52 1.2V24h19.9z" />
    <path
      d="M6 41h60v49H6zm.4-34.6h3.3c2.9 0 4.7 2.6 4.7 7.1s-1.8 7.2-4.7 7.2H6.4V6.4zm3.3 11.4c1.3 0 2.1-1.5 2.1-4.3S11 9.3 9.7 9.3H9v8.5h.7zM20 6.1c3 0 4.7 3.1 4.7 7.3 0 4.2-1.7 7.5-4.7 7.5s-4.7-3.2-4.7-7.5c0-4.1 1.7-7.3 4.7-7.3zm0 11.7c1.4 0 2-1.8 2-4.3s-.6-4.2-2-4.2-2 1.7-2 4.2.6 4.3 2 4.3zM30.7 6.1c1 0 2.1.4 2.9 1.2l-1.1 2.8c-.3-.5-1-.9-1.7-.9-1.7 0-2.6 1.9-2.6 4.2 0 2.3.9 4.4 2.5 4.4.8 0 1.3-.5 1.8-1l1.1 2.8c-.7.7-1.6 1.3-3.2 1.3-3.2 0-5-3.1-5-7.4.1-4.2 2-7.4 5.3-7.4zm5.9 7.5l-2.8-7.2h2.9l.9 2.9c.3.9.4 1.8.4 1.8s.2-.9.5-1.8l.9-2.9h2.9l-2.8 7.2 2.7 7.1h-2.8l-.9-3c-.3-.9-.5-1.7-.5-1.7s-.2.8-.4 1.7l-.9 3h-2.8l2.7-7.1z"
      fill="#fff"
    />
    <path
      class="st-0"
      d="M19.5 67.1l-4.8-14.8c-.2-.6-.3-.8-.4-.8-.1-.1-.2-.1-.3-.2l-1.7-.5-.1-2.1h9l.2 2.1-1.7.5v.2c0 .2.1.6.3 1l1.8 5.7 3.2-9.5 2.4-.1 2.9 9.5 2-5.7c.1-.4.3-.8.3-1v-.1l-1.5-.4-.1-2.2h7l.2 2.1-1.9.6c-.1 0-.2.1-.3.1 0 .1-.2.2-.3.7l-5.3 14.7-2.5.2-2.7-9.1-3.2 8.9-2.5.2z"
    />
    <path
      d="M42.4 48.6H60v2.8H42.4zm0 7.4H60v2.8H42.4zm0 7.7H60v2.8H42.4zM13 71.5h47v2.8H13zm0 7.6h47v2.8H13z"
      class="st-0"
      stroke="currentColor"
      stroke-width=".5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
<style>
.st0 {
  fill: currentColor;
}
</style>
