import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportListByServiceCategory = _resolveComponent("ReportListByServiceCategory")!
  const _component_ReportListByServiceCategoryLoading = _resolveComponent("ReportListByServiceCategoryLoading")!
  const _component_ReportsListMenuShell = _resolveComponent("ReportsListMenuShell")!

  return (_openBlock(), _createBlock(_component_ReportsListMenuShell, {
    categories: _ctx.categories,
    assetOnlyServices: [],
    error: _ctx.error,
    loading: _ctx.loading,
    isDashboard: false,
    assetCategories: _ctx.assetCategories,
    onShowReport: _ctx.showReportById
  }, {
    default: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_ReportListByServiceCategory, {
                  category: category,
                  assets: _ctx.assets,
                  isInReports: true
                }, null, 8, ["category", "assets"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ReportListByServiceCategoryLoading, { loading: _ctx.loading }, null, 8, ["loading"])
          ]))
    ]),
    _: 1
  }, 8, ["categories", "error", "loading", "assetCategories", "onShowReport"]))
}