
import { defineComponent, PropType } from 'vue'
import PaginateSlider from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider-pagination.vue'
import ViewportSlider from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider-viewport.vue'
import { MapKpiModel } from '@/map/core/bll/kpi/kpi.model'
import store from '@/store'
import * as SettingsManager from '@/core/managers/settings.manager'
import sliderComposable from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider.composable'

export default defineComponent({
  components: {
    PaginateSlider,
    ViewportSlider
  },
  props: {
    assetId: Number,
    kpiList: {
      type: Array as PropType<MapKpiModel[]>,
      require: true
    }
  },
  watch: {
    position: {
      handler (newVal, oldVal) {
        sliderComposable.updateDirection(newVal, oldVal)
      }
    }
  },
  setup () {
    return {
      position: sliderComposable.position,
      direction: sliderComposable.direction
    }
  },
  created () {
    if (!store.state.filtersSettings) {
      SettingsManager.Manager.filtersSettings()
    }
  },
  mounted () {
    sliderComposable.initEvent((this.kpiList as MapKpiModel[]).length)
  },
  unmounted () {
    sliderComposable.removeEvent()
  },
  methods: {
    onMouseOver () {
      sliderComposable.stopEvent()
    },
    onMouseLeave () {
      sliderComposable.nextEvent()
    },
    onClick (value : number) {
      sliderComposable.changePosition(value)
    }
  },
  computed: {
    slidesCount () {
      return (this.kpiList as MapKpiModel[]).length
    },
    assetFilter () {
      return store.state.filtersSettings &&
      store.state.filtersSettings.id_AssetFilter
        ? store.state.filtersSettings.id_AssetFilter
        : ''
    },
    periodFilter () {
      return store.state.filtersSettings &&
      store.state.filtersSettings.id_PeriodFilter
        ? store.state.filtersSettings.id_PeriodFilter
        : ''
    }

  }

})
