

import FilesIcon from '@/components/icons/FilesIcon.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  components: {
    FilesIcon,
    UploadIcon
  },
  props: {
    title: { type: String, required: true },
    isAssetReport: { type: Boolean, required: true },
    subtitle: { type: String },
    id: { type: Number }
  },
  setup () {
    return {
      loginService: inject('ILoginService') as ILoginService
    }
  },
  data () {
    return {
      isInternalUser: false
    }
  },
  async created () {
    this.isInternalUser = await this.loginService.IsInternalUser()
  },
  methods: {
    requestDocumentUpload () {
      this.$emit('requestUpload')
    }
  }
})
