
import { defineComponent } from 'vue'
import FleetCardCardView from '@/components/cards/FleetCardCardView.vue'
import FleetCardListView from '@/components/cards/FleetCardListView.vue'
import { fleetCardProps } from '@/core/model/fleetCardProps.model'

export default defineComponent({
  name: 'FleetCard',
  components: {
    FleetCardCardView,
    FleetCardListView
  },
  props: fleetCardProps
})
