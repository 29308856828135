import { apiClient } from '@/core/axios.plugin'
import { AxiosInstance, AxiosPromise } from 'axios'
// import { Settings } from 'luxon'

type EmptyObject = Record<string, never>

type Method =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK';

interface Error{
    field: string;
    values: string[];
}

class Errors {
  values: Error[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor (code:string, raw:any) {
    if (code !== '500') {
      for (const key in raw) {
        this.values.push({ field: key, values: raw[key] })
      }
    } else {
      this.values.push({ field: 'technical error', values: raw.split(/\r?\n/).splice(0, 2) })
    }
  }

  reset (): void {
    this.values = []
  }
}

export class ApiResult<T> {
  isError = false
  data?: T
  Message = ''
  rawError: EmptyObject | string = {}
  errors:Errors = new Errors('400', this.rawError)
  code?: string
  status?: number
}

export class RequestHandler {
  private get httpRequest (): AxiosInstance {
    return apiClient
  }

  request<T> (method: Method, url: string, data?: unknown, options?: any): Promise<ApiResult<T>> {
    const res = new ApiResult<T>()
    return this.httpRequest({
      method,
      url,
      data,
      ...options
    })
      .then((response) => {
        res.isError = false
        res.data = response.data
        res.Message = 'OK'
        res.status = 200
        return res
      })
      .catch(err => {
        res.isError = true
        res.Message = err.message
        res.rawError = err.response ? err.response.data : ''
        res.errors = new Errors(err.response ? err.response.status : '', res.rawError)
        res.code = err.code
        res.status = err.response.status
        return res
      })
  }

  emptyResponseRequest (method: Method, url: string) : AxiosPromise<void> {
    return this.httpRequest({ method, url })
  }

  download (url: string): Promise<ApiResult<any>> {
    const res = new ApiResult<any>()

    return apiClient.get(url, { responseType: 'blob' })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition']
        let fileName = 'unknown'
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/(filename\*=UTF-8''(?<without>.+))/i)
          if (fileNameMatch.groups.without) {
            fileName = decodeURIComponent(fileNameMatch.groups.without)
          }
        }
        res.isError = false

        res.data = response.data
        res.Message = fileName
        res.status = 200
        return res
      })
      .catch(err => {
        res.isError = true
        res.Message = err.message
        res.rawError = err.response ? err.response.data : ''
        res.errors = new Errors(err.response ? err.response.status : '', res.rawError)
        res.code = err.code
        res.status = err.response ? err.response.status : 500
        return res
      })
  }

  postFormData<T> (url: string, data: unknown): Promise<ApiResult<T>> {
    const res = new ApiResult<T>()
    return this.httpRequest.post(url, data, {
      headers: {
        'Content-Type': 'form-data'
      }
    })
      .then((response) => {
        res.isError = false
        res.data = response.data
        res.Message = 'OK'
        res.status = 200
        return res
      })
      .catch(err => {
        res.isError = true
        res.Message = err.message
        res.rawError = err.response ? err.response.data : ''
        res.errors = new Errors(err.response ? err.response.status : '', res.rawError)
        res.code = err.code
        res.status = err.response.status
        return res
      })
  }
}

export const http = new RequestHandler()
