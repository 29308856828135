const _sticky = function ():void {
  // only one directive once so only one data-element-id
  const element = document.querySelector('[data-element-id]') as HTMLElement
  const sourceId = element.dataset.elementId
  const target = document.querySelector(`[data-target-id="${sourceId}"]`) as HTMLElement
  if (target && element) {
    const targetId = element.dataset.stickyParent as string
    const parentTarget = (document.getElementById(targetId) as HTMLElement)
    const posY = `top:${target.getBoundingClientRect().top - parentTarget.getBoundingClientRect().top}px`
    const posX = 'right:70px'
    element.setAttribute('style', `${element.getAttribute('style')};${posX};${posY}`)
  }
}
export const StickyDirective = {
  mounted ():void {
    window.addEventListener('resize', _sticky)
    _sticky()
  },
  unmounted ():void {
    window.removeEventListener('resize', _sticky)
  }
}
