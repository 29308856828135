import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrossIcon = _resolveComponent("CrossIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "p-2.5 rounded-full bg-bg-2 absolute right-0 top-0 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal')))
  }, [
    _createVNode(_component_CrossIcon, { class: "stroke-text-alt h-5 w-5 border-2 border-text-alt rounded-full" })
  ]))
}