import { AssetDetailModel, GetAssetDetailModel } from '@/map/core/bll/asset/assetDetail.model'

export function AssetDetailMapper (asset: GetAssetDetailModel) : AssetDetailModel {
  return {
    assetId: asset.assetId, // panel detail - KPI
    assetCode: asset.asset.code, // use FleetMap
    assetName: asset.asset.name.toUpperCase(),
    categoryId: asset.asset.assetCategoryId, // panel detail - KPI
    assetImg: asset.asset.imageUrl || asset.asset.typeImageUrl || require('@/assets/img/noAsset.png'), // panel detail
    assetTypeName: asset.asset.typeName, // panel detail
    lastPosition: asset.asset.assetLastPosition.utcPositionTime, // panel detail
    heading: asset.asset.assetLastPosition.heading, // panel detail
    speed: asset.asset.assetLastPosition.speed, // panel detail
    assetLength: asset.mappedProperties.assetLengthOverall, // panel detail
    assetMsFleetUrl: asset.assetMsFleetUrl// panel detail
  }
}
