import { FilterFormatted } from '@/core/model/filter.model'
import { KpiModel } from '@/core/model/kpi.model'
import { ApiResult, http } from '@/core/services/request.service'
import router from '@/router'

export const Manager = {
  detail (id: number, filters?: FilterFormatted[], compatibleFilters?: number[]): Promise<ApiResult<KpiModel>> {
    const filtersSorted = (filters || []).filter(filter => (compatibleFilters || []).find((compFilter) => compFilter === filter.id))
    const filtersUniq = Array.from(new Set(filtersSorted.map(a => a.id))).map(id => {
      return filtersSorted.find(a => a.id === id)
    })
    const body = {
      filters: (router.currentRoute.value.name !== 'assetDetail' && router.currentRoute.value.name !== 'map') ? filtersUniq || [] : filters
    }
    return http.request<KpiModel>('post', `Kpis/${id}`, body)
      .then((rep: ApiResult<KpiModel>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }
}
