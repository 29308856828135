import { ApiResult, RequestHandler } from '@/core/services/request.service'
import { DepartmentModel } from '@/core/model/context.model'
import { ILogger } from '@/core/services/loggers/ilogger.service'

export class ContextManager {
  private _http: RequestHandler
  private _logger: ILogger

  constructor (http: RequestHandler, logger: ILogger) {
    this._http = http
    this._logger = logger

    this.currentDepartment().then(code => this._logger.updateClientDepartmentContext(code.data as string))
  }

  clear (): Promise<ApiResult<void>> {
    return this._http.request<void>('put', 'Context/Clear')
      .then((rep: ApiResult<void>) => rep)
      .catch(err => err)
  }

  async declareDepartmentCode (clientDepartmentCode: string): Promise<ApiResult<void>> {
    const response = await this._http.request<void>('put', `Context/ClientDepartment/Current/${clientDepartmentCode}`)
    // .then((rep: ApiResult<void>) => rep)
    // .catch(err => err)
    console.log(response)
    if (response.status === 200) {
      this._logger.updateClientDepartmentContext(clientDepartmentCode)
    }
    return response
  }

  departmentList (): Promise<ApiResult<DepartmentModel[]>> {
    return this._http.request<DepartmentModel[]>('get', 'Context/ClientDepartment')
      .then((rep: ApiResult<DepartmentModel[]>) => rep)
      .catch(err => err)
  }

  currentDepartment (): Promise<ApiResult<string>> {
    return this._http.request<string>('get', 'Context/ClientDepartment/Current')
      .then((rep: ApiResult<string>) => rep)
      .catch(err => err)
  }

  activitySectorsList (): Promise<ApiResult<string[]>> {
    return this._http.request<string[]>('get', 'Context/ActivitySectors')
      .then((rep: ApiResult<string[]>) => rep)
      .catch(err => err)
  }

  currentActivitySectors (): Promise<ApiResult<string[]>> {
    return this._http.request<string[]>('get', 'Context/ActivitySectors/Current')
      .then((rep: ApiResult<string[]>) => rep)
      .catch(err => err)
  }

  declareActivitySectors (activitySectors: string[]): Promise<ApiResult<void>> {
    return this._http.request<void>('put', 'Context/ActivitySectors/Current', activitySectors)
      .then((rep: ApiResult<void>) => rep)
      .catch(err => err)
  }
}
