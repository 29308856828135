import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bfb78804"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "viewport bg-bg-2 rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_card_preview = _resolveComponent("services-card-preview")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (item, index) => {
      return (_openBlock(), _createBlock(_Transition, {
        key: index,
        name: `slide-${_ctx.direction}`
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_router_link, {
            to: { name: 'analyticsDetail', params: { analyticsId: item.id }, query: { filter: encodeURI(JSON.stringify([{ id: _ctx.periodFilter, members: 'L1W'},{ id: _ctx.assetFilter, members: [_ctx.assetId] }]))}}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_services_card_preview, {
                id: item.id,
                title: item.title,
                "preview-type": item.previewType,
                description: item.desc,
                "graph-type": item.graphType,
                "preview-infos": item.previewInfos,
                "kpi-title": item.kpiTitle,
                "asset-id": _ctx.assetId,
                period: 'L1W'
              }, null, 8, ["id", "title", "preview-type", "description", "graph-type", "preview-infos", "kpi-title", "asset-id"])
            ]),
            _: 2
          }, 1032, ["to"]), [
            [_vShow, index === _ctx.current]
          ])
        ]),
        _: 2
      }, 1032, ["name"]))
    }), 128))
  ]))
}