<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="m469.077 437.043c-24.997-97.175-112.617-165.043-213.075-165.043s-188.078 67.868-213.075 165.042c-4.589 17.836-.588 37.174 10.701 51.728 11.285 14.546 29.017 23.23 47.435 23.23h309.877c18.68 0 35.962-8.468 47.414-23.232 11.299-14.564 15.308-33.9 10.723-51.725zm-42.327 27.207c-3.82 4.925-9.583 7.75-15.81 7.75h-309.877c-6.148 0-12.065-2.896-15.829-7.748-3.82-4.925-5.12-11.21-3.568-17.245 20.448-79.49 92.137-135.007 174.336-135.007s153.889 55.517 174.336 135.007c1.526 5.933.185 12.379-3.588 17.243zm-170.748-224.25c66.168 0 120-53.832 120-120s-53.832-120-120-120-120 53.832-120 120 53.832 120 120 120zm0-200c44.112 0 80 35.888 80 80s-35.888 80-80 80-80-35.888-80-80 35.887-80 80-80z"
        />
      </g>
    </g>
  </svg>
</template>
