import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-46cae5fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-h-[800px] overflow-y-auto overflow-x-hidden custom-scrollbar pt-2" }
const _hoisted_2 = { class: "body-1 text-bg-0 bg-text-prim !font-bold p-3" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "text-center body body-1 !font-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center body body-2"
}
const _hoisted_6 = {
  key: 2,
  class: "opacity-0 body body-2"
}
const _hoisted_7 = { class: "w-8/12 xs:w-4/5 ml-16 sm:ml-20 h-fit flex justify-center relative z-[3]" }
const _hoisted_8 = { class: "w-3/4 flex" }
const _hoisted_9 = {
  key: 1,
  class: "absolute rounded-full w-3 h-3 -top-1.5 -left-1.5 bg-text-alt"
}
const _hoisted_10 = {
  key: 2,
  class: "absolute -top-2.5 -left-14 body-1"
}
const _hoisted_11 = {
  key: 3,
  class: "absolute -bottom-2.5 -left-14 body-1"
}
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intervals, (day, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(["w-full pt-2 border-t", { 'mt-6': i !== 0 }])
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formatDate(_ctx.timeline[i].date)), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day, (interval, j) => {
            return (_openBlock(), _createElementBlock("div", {
              key: j,
              class: "w-full"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass({
              'my-3':
                j === 0 ||
                (day[j - 1] && interval.location !== day[j - 1].location) ||
                (day[j - 1] && interval.context !== day[j - 1].context),
            })
              }, [
                (_ctx.displayLocation(i, j))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(day[j - 1] ? day[j - 1].location : ''), 1))
                  : _createCommentVNode("", true),
                (_ctx.displayContext(i, j))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(day[j - 1] ? day[j - 1].context : ''), 1))
                  : _createCommentVNode("", true),
                (!_ctx.displayContext(i, j) && !_ctx.displayLocation(i, j) && j === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Empty line "))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interval.events, (operation, k) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: k,
                      class: "w-full grow-0 shrink-0",
                      style: _normalizeStyle(`height: ${operation.height * 60}px;
                margin-top: ${
                  (operation.heightIndex === -1 ? 0 : operation.heightIndex) *
                  60
                }px; width: ${100 / interval.events.length}%;
                `)
                    }, [
                      _createElementVNode("div", {
                        class: "absolute w-full z-[-1] left-0 box",
                        style: _normalizeStyle(`height: ${operation.height * 60}px; top: ${
                    (operation.heightIndex === -1 ? 0 : operation.heightIndex) *
                    60
                  }px; border-top: ${
                    _ctx.displayBorderTop(i, j, k) ? '1px solid' : ''
                  }; border-bottom: ${
                    _ctx.displayBorderBottom(i, j, k) ? '1px solid' : ''
                  }; border-bottom-color: ${_ctx.topColour()}; border-top-color: #A9B8DB;`)
                      }, [
                        (_ctx.displayBorderBottom(i, j, k))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "absolute rounded-full w-3 h-3 -bottom-1.5 -left-1.5",
                              style: _normalizeStyle(`background: ${_ctx.topColour()}`)
                            }, null, 4))
                          : _createCommentVNode("", true),
                        (_ctx.displayBorderTop(i, j, k))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                          : _createCommentVNode("", true),
                        (_ctx.displayBorderTop(i, j, k))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.getHours(operation.endTime)), 1))
                          : _createCommentVNode("", true),
                        (_ctx.displayBorderBottom(i, j, k))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.getHours(operation.startTime)), 1))
                          : _createCommentVNode("", true)
                      ], 4),
                      _createElementVNode("div", {
                        class: "h-full p-2 whitespace-nowrap overflow-hidden max-w-full text-ellipsis cursor-pointer",
                        style: _normalizeStyle(`${_ctx.operationBackground(
                    operation.name,
                    operation.startTime
                  )}; ${_ctx.operationBorder(operation.name)}; `),
                        onClick: ($event: any) => (_ctx.showDetail(operation))
                      }, _toDisplayString(operation.name), 13, _hoisted_12)
                    ], 4))
                  }), 128))
                ])
              ])
            ]))
          }), 128))
        ])
      ], 2))
    }), 128))
  ]))
}