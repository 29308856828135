
import previewHandling, { previewHandlingProps } from '@/core/composables/preview.composable'
import { ChartModel } from '@/core/model/chart.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import { defineComponent, onMounted, ref, watch } from 'vue'
import AnalyticsIcon from '@/components/icons/AnalyticsIcon.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'
import { useRouter } from 'vue-router'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export default defineComponent({
  name: 'ServicesCardPreview.vue',
  components: {
    Highcharts: Chart,
    RightArrowIcon,
    AnalyticsIcon
  },
  props: previewHandlingProps,
  setup (props) {
    const router = useRouter()
    const prop = {
      id: props.id,
      title: props.title,
      linkUrl: props.linkUrl,
      graphType: props.graphType,
      previewType: props.previewType,
      previewInfos: props.previewInfos,
      size: props.size,
      description: props.description,
      isPreview: props.isPreview,
      kpiTitle: props.kpiTitle,
      period: props.period
    }
    const preview = previewHandling(
      prop,
      '',
      '',
      {} as ChartModel,
      true,
      {} as KpiModel,
      false,
      [] as KpiExtended[]
    )

    const getKpiColour = (label: string) => {
      return ColoursService.getColour(label)
    }
    const loading = ref(preview.loadingRef)
    const customDetails = ref(preview.customDetailsRef)
    const error = ref(preview.errorRef)
    const kpiDetails = ref(preview.kpiDetailsRef)
    const detailed = ref(preview.detailedRef)
    const kpiList = ref(preview.kpiListRef)
    const chartOptions = preview.chartOptions
    const kpiToGauge = preview.kpiToGauge
    const mounted = onMounted(() => {
      detailed.value = false
      if (router.currentRoute.value.name === 'map') {
        preview.displayPreview(props.period, props.assetId)
      } else {
        preview.displayPreview()
      }
    })

    watch(
      () => props.period,
      (current) => {
        preview.displayPreview(current)
      }
    )

    const formatNumber = (num: number) => FormatService.formatNumber(num)

    onMounted(() => {
      setTimeout(() => {
        if (props.graphType === 'PIE') {
          const element = document.querySelectorAll('.highcharts-legend-item')
          for (const iterator of element) {
            const acceptedClasses = [
              'highcharts-color-0',
              'highcharts-color-1',
              'highcharts-color-2',
              'highcharts-color-3',
              'highcharts-color-4'
            ]
            if (
              !iterator.classList.contains(acceptedClasses[0]) &&
              !iterator.classList.contains(acceptedClasses[1]) &&
              !iterator.classList.contains(acceptedClasses[2]) &&
              !iterator.classList.contains(acceptedClasses[3]) &&
              !iterator.classList.contains(acceptedClasses[4])
            ) {
              iterator.remove()
            }
          }
        }
      }, 400)
    })

    return {
      getKpiColour,
      loading,
      customDetails,
      mounted,
      error,
      kpiDetails,
      detailed,
      kpiList,
      chartOptions,
      kpiToGauge,
      formatNumber
    }
  }
})
