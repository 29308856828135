import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-14 mb-6 text-center font-bold" }
const _hoisted_2 = { class: "gap-4 text-2xl text-gray-100 mb-2" }
const _hoisted_3 = { class: "text-sm text-gray-400 font-normal" }
const _hoisted_4 = { class: "flex flex-col gap-4 justify-center xl:justify-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}