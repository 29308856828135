export default class Tools {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static copyArray <T> (array: T[]): T[] {
    return JSON.parse(JSON.stringify(array))
  }

  public static getObjectFromStorage (storage: Storage, item: string): { [key: string]: string } {
    return JSON.parse(storage.getItem(item) as string)
  }

  public static flattenArray<T> (arr: T[][]): T[] {
    return ([] as T[]).concat(...arr)
  }

  public static debounce<T> (fn: T, wait: number): () => void {
    let timer: ReturnType<typeof setTimeout>
    return () => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        if (typeof fn === 'function') {
          fn()
        }
      }, wait)
    }
  }

  public static isEmpty <T> (obj: T): boolean {
    return obj &&
      Object.keys(obj).length === 0 &&
      Object.getPrototypeOf(obj) === Object.prototype
  }

  public static updatePageName (name: string, title?: string): void {
    const titleAddition = title ? ` - ${title}` : ''
    window.document.title = `Marinsights - ${name}${titleAddition}`
  }

  public static formatDate (date: string, hours = false): string {
    const day = new Date(date).toLocaleDateString('en-us', { day: '2-digit' })
    const month = new Date(date).toLocaleDateString('en-us', { month: '2-digit' })
    const year = new Date(date).toLocaleDateString('en-us', { year: 'numeric' })
    if (hours) {
      const hour = new Date(date).getHours()
      const minute = new Date(date).getMinutes()
      return `${day}-${month}-${year} ${hour}:${minute}`
    }
    return `${day}-${month}-${year}`
  }

  public static isOnceSelected <T extends {active: boolean}> (categories : T[]) : boolean {
    let rep = false
    for (const category of categories) {
      if (category.active) {
        rep = true
      }
    }
    return rep
  }
}
