import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6b82ba58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-text-alt" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex items-center justify-between font-normal cursor-pointer py-1",
      onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('show-list'); _ctx.open = !_ctx.open})
    }, [
      (_ctx.dashboard && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 0,
            class: _normalizeClass(_ctx.dashboard.active ? 'font-bold' : '!font-normal !text-text-alt'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('show-service', _ctx.dashboard.name)))
          }, _toDisplayString(_ctx.dashboard.name), 3))
        : _createCommentVNode("", true),
      (!_ctx.dashboard && _ctx.loading)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 1,
            class: _normalizeClass(_ctx.loading ? 'skeleton' : '')
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.dashboard && _ctx.dashboard.services.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mt-1.5 mb-2 w-5/6 ml-4", {'border-l-2' : _ctx.allActiveInCategory}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dashboard.services, (category, index) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: index,
              class: "body-2 py-3"
            }, [
              _createElementVNode("div", {
                onClick: ($event: any) => (_ctx.$emit('show-detail', category.name)),
                class: _normalizeClass([{'font-bold border-l-2 border-semantic-activate': category.active && !_ctx.allActiveInCategory}, "w-fit cursor-pointer px-4"])
              }, _toDisplayString(category.name), 11, _hoisted_2)
            ])), [
              [_vShow, _ctx.dashboard.active && !_ctx.allActive]
            ])
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}