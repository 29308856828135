

import { defineComponent } from 'vue'
import tileStore from '@/map/core/bll/map/tile.store'

export default defineComponent({

  setup () {
    return {
      cards: [
        { name: 'https://api.maptiler.com/maps/50300a58-72e8-4c8e-a71e-d5e395b00152/{z}/{x}/{y}.png?key=nrAD16PdiepKDbSBhR8U', img: `url(${require('@/assets/img/thumbTile/dark.png')}`, title: 'dark' },
        { name: 'https://api.maptiler.com/maps/c58ae4bb-5295-43b8-a414-eb3065d66e30/{z}/{x}/{y}.png?key=nrAD16PdiepKDbSBhR8U', img: `url(${require('@/assets/img/thumbTile/basic.png')}`, title: 'light' },
        { name: 'https://api.maptiler.com/maps/c9427de4-f1fb-45ac-828b-7db14101e3c1/{z}/{x}/{y}.png?key=nrAD16PdiepKDbSBhR8U', img: `url(${require('@/assets/img/thumbTile/satellite.png')}`, title: 'satellite' }
      ]
    }
  },
  methods: {
    onChangeUrl  (url :string):void {
      tileStore.action.toggle(url)
    }
  }
})

