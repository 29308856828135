
import { defineComponent } from 'vue'
import concessionStore from '@/map/core/bll/concession/concession.store'

export default defineComponent({
  setup () {
    return {
      state: concessionStore.state
    }
  },
  methods: {
    onToggle (value: boolean): void {
      concessionStore.action.toggle(value)
    }
  }
})
