import axios, { AxiosError } from 'axios'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import router from '@/router'

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const dlClient = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: false,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/octet-stream'
  }
})

export function AddHttpInterceptor (tokenService: ILoginService, sessionId: string): any {
  apiClient.interceptors.request.use(async function (option: any) {
    if (option.headers && tokenService !== undefined) {
      const tokenResponse = await tokenService.GetToken()
      option.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
      option.headers['x-sessionId'] = sessionId
    }
    return option
  })

  dlClient.interceptors.request.use(async function (option: any) {
    if (option.headers && tokenService !== undefined) {
      const tokenResponse = await tokenService.GetToken()
      option.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
      option.headers['x-sessionId'] = sessionId
    }
    return option
  })

  // Add a response interceptor
  apiClient.interceptors.response.use(function (response: any) {
    return response
  }, (error: AxiosError) => {
    if (error.response && error.response.status === 403) {
      router.push('/unauthorized')
    }

    return Promise.reject(error)
  })
}
