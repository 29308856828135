import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1f9e573f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "h-full w-full pt-6"
}
const _hoisted_2 = {
  key: 2,
  class: "flex items-center w-full border-b border-b-bg-2 pt-4 justify-evenly"
}
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "pt-3 pb-5 px-3.5" }
const _hoisted_5 = { class: "grid grid-cols-1 lg:grid-cols-3 mt-10 gap-4" }
const _hoisted_6 = { class: "w-full col-span-1 lg:col-span-2" }
const _hoisted_7 = {
  key: 0,
  class: "border border-[#1F2C47] rounded-xl skeleton-bg h-[400px]"
}
const _hoisted_8 = {
  key: 1,
  class: "border border-[#1F2C47] rounded-xl h-[400px] w-full"
}
const _hoisted_9 = {
  key: 2,
  class: "grid gap-4 w-full justify-center lg:justify-start my-4 grid-kpis"
}
const _hoisted_10 = { key: 4 }
const _hoisted_11 = { key: 5 }
const _hoisted_12 = {
  key: 1,
  class: "py-6 text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FleetDetailTitleCard = _resolveComponent("FleetDetailTitleCard")!
  const _component_AssetDetailTab = _resolveComponent("AssetDetailTab")!
  const _component_fleet_map = _resolveComponent("fleet-map")!
  const _component_ServiceCardContent = _resolveComponent("ServiceCardContent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FleetAssetTimeline = _resolveComponent("FleetAssetTimeline")!
  const _component_FleetReportTab = _resolveComponent("FleetReportTab")!
  const _component_FleetAssetDetails = _resolveComponent("FleetAssetDetails")!

  return (!_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.vesselDetails)
          ? (_openBlock(), _createBlock(_component_FleetDetailTitleCard, {
              key: 0,
              isFav: _ctx.vesselDetails.isFavorite,
              name: _ctx.vesselDetails.name,
              type: _ctx.vesselDetails.type,
              country: _ctx.vesselDetails.country_alpha2,
              course: _ctx.vesselDetails.heading,
              lastCourseUpdate: _ctx.vesselDetails.lastPositionUpdate,
              speed: _ctx.vesselDetails.speed,
              length: _ctx.length,
              imo: _ctx.imo,
              mmsi: _ctx.mmsi,
              loading: _ctx.loading,
              bridgeNumber: _ctx.bridgeNumber,
              bridgeEmail: _ctx.bridgeEmail,
              imgUrl: _ctx.vesselDetails.imageUrl
            }, null, 8, ["isFav", "name", "type", "country", "course", "lastCourseUpdate", "speed", "length", "imo", "mmsi", "loading", "bridgeNumber", "bridgeEmail", "imgUrl"]))
          : (_openBlock(), _createBlock(_component_FleetDetailTitleCard, {
              key: 1,
              loading: _ctx.loading
            }, null, 8, ["loading"])),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                return (_openBlock(), _createBlock(_component_AssetDetailTab, {
                  key: i,
                  name: tab,
                  onClick: ($event: any) => (_ctx.selectTab(tab)),
                  class: _normalizeClass(["cursor-pointer body-1 body-alt sm:body-2 sm:body-alt border-b-2 border-transparent pb-4 sm:w-[calc(33.3%-30px)] font-bold", {
          '!text-semantic-activate border-b-2 border-b-semantic-activate !fill-semantic-activate shadow-tab':
            _ctx.selectedTab === tab,
        }])
                }, null, 8, ["name", "onClick", "class"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 'History')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_fleet_map, {
                            code: _ctx.vesselDetails.code
                          }, null, 8, ["code"])
                        ])),
                    (_ctx.vesselDetails && _ctx.vesselDetails.services && _ctx.vesselDetails.services.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vesselDetails.services, (service, i) => {
                            return (_openBlock(), _createBlock(_component_router_link, {
                              target: "_blank",
                              to: {
                  name: 'analyticsDetail',
                  params: { analyticsId: service.id },
                  query: {
                    filter: encodeURI(
                      JSON.stringify([
                        { id: _ctx.periodFilter, members: _ctx.periodSelected },
                        { id: _ctx.assetFilter, members: [+_ctx.$route.params.assetId] },
                      ])
                    ),
                  },
                },
                              class: "w-[310px] aspect-video min-w-[310px] rounded-xl bg-bg-2 m-auto",
                              key: i
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ServiceCardContent, {
                                  id: service.id,
                                  title: service.title,
                                  subtitle: service.text,
                                  previewType: service.previewType,
                                  previewInfos: 
                    service.previewType === 'Chart'
                      ? service.chart
                      : service.previewType === 'Kpi' ||
                        service.previewType === 'KPI'
                        ? service.kpi
                        : service.previewType === 'ChartAndKPI'
                          ? [service.chart, service.kpi]
                          : service.previewType === 'Custom'
                            ? service.customHtml
                            : {}
                  ,
                                  size: service.size,
                                  description: service.description,
                                  fromFleet: false,
                                  fromList: true,
                                  period: 'L6M'
                                }, null, 8, ["id", "title", "subtitle", "previewType", "previewInfos", "size", "description"])
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_FleetAssetTimeline, {
                    timeline: _ctx.timeline,
                    vesselDetails: _ctx.vesselDetails,
                    error: _ctx.timeSheetError,
                    class: "col-span-1 xs:min-w-[370px]",
                    onPeriodSelected: _ctx.choosePeriod,
                    loading: _ctx.loadingTimeline
                  }, null, 8, ["timeline", "vesselDetails", "error", "onPeriodSelected", "loading"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 'Reports' && _ctx.vesselDetails && _ctx.vesselDetails.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_FleetReportTab, { asset: _ctx.vesselDetails }, null, 8, ["asset"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === _ctx.$t('fleet.tabs.details'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_ctx.vesselDetails)
                ? (_openBlock(), _createBlock(_component_FleetAssetDetails, {
                    key: 0,
                    country: _ctx.vesselDetails.country_alpha2,
                    type: _ctx.vesselDetails.type,
                    loa: _ctx.vesselDetails.loa,
                    imo: _ctx.vesselDetails.imo,
                    mmsi: _ctx.vesselDetails.mmsi,
                    properties: _ctx.vesselDetails.properties
                  }, null, 8, ["country", "type", "loa", "imo", "mmsi", "properties"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.error), 1))
}