import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative block" }
const _hoisted_2 = { class: "absolute inset-y-0 left-0 flex items-center pl-2" }
const _hoisted_3 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_CrossIcon = _resolveComponent("CrossIcon")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_SearchIcon, { class: "h-5 w-5 fill-text-alt" })
    ]),
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('update:modelValue', ''); _ctx.$emit('clearInput') })
        }, [
          _createVNode(_component_CrossIcon, { class: "h-5 w-5 stroke-text-alt" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "block bg-bg-0 w-full rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-text-alt focus:ring-text-alt focus:ring-1 sm:text-sm text-text-alt body-2 !leading-4 font-bold",
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      type: "text",
      name: "search",
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
    }, null, 40, _hoisted_3)
  ]))
}