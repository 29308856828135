
import ServicesFilterButton from '@/components/buttons/ServicesFilterButton.vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ServiceCardContent from '@/components/cards/ServiceCardContent.vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'
import PageTitle from '@/components/titles/PageTitle.vue'
import handleFilters from '@/core/composables/handleFilters.composable'
import * as CategoryManager from '@/core/managers/category.manager'
import {
  CategoriesListModel,
  CategoryModel
} from '@/core/model/category.model'
import store from '@/store'
import { computed, defineComponent, Ref, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    ServiceCardContent,
    ServicesFilterButton,
    GenericCardShell,
    RightArrowIcon,
    PageTitle
  },
  setup () {
    const toast = useToast()
    const loading = ref(true)
    const error = ref('')
    const list: Ref<CategoryModel[]> = ref([])
    const serviceDisplayed: Ref<boolean[]> = ref([])
    const filterHandler = handleFilters()
    const isVisible = filterHandler.isVisible
    const categories = computed(() => {
      return store.state.list
    })
    const isAllActive = ref(true)
    const toggleCat = (category: CategoryModel) => {
      const index = (categories.value as CategoryModel[]).findIndex(
        (item) => item.name === category.name
      )
      if (categories.value && typeof index === 'number' && index > -1) {
        categories.value[index].active = !categories.value[index].active
        serviceDisplayed.value[index] = !serviceDisplayed.value[index]
      }
      const atLeastOneActiveIndex = (categories.value as CategoryModel[]).findIndex(
        (item) => item.active === true
      )
      if (atLeastOneActiveIndex > -1) {
        isAllActive.value = false
      }
    }

    const selectAll = () => {
      for (const [index, category] of categories.value.entries()) {
        category.active = false
        serviceDisplayed.value[index] = true
      }
      isAllActive.value = true
    }

    return {
      toast,
      loading,
      error,
      list,
      serviceDisplayed,
      isVisible,
      categories,
      isAllActive,
      toggleCat,
      selectAll
    }
  },
  created () {
    store.dispatch('setFiltersList', [])
    store.dispatch('setServiceList', [])
    CategoryManager.Manager.list().then((response) => {
      if (response.isError) {
        this.error =
            'No services available at the moment. Please try again later'
        this.toast.error(this.error)
      } else {
        store.dispatch(
          'setServiceList',
          (response.data as CategoriesListModel).categories
        )
        for (let index = 0; index < this.categories.length; index++) {
          this.serviceDisplayed.push(false)
        }
      }
      this.loading = false
    })
  },
  mounted () {
    window.addEventListener('resize', this.displayArrowWrapper)
  },
  unmounted () {
    window.removeEventListener('resize', this.displayArrowWrapper)
  },
  methods: {
    displayArrowWrapper () {
      const category = this.$refs.category as HTMLElement[]
      const arrows = this.$refs.arrows as HTMLElement[]
      if (arrows && category.length && arrows.length) {
        for (let i = 0; i < category.length; i++) {
          if (
            category[i].scrollWidth <=
            (this.$refs.categoryWrapper as HTMLElement).offsetWidth
          ) {
            arrows[i].classList.remove('hidden', 'md:block')
            arrows[i].classList.add('hidden', 'md:hidden')
          } else {
            arrows[i].classList.remove('hidden', 'md:hidden')
            arrows[i].classList.add('hidden', 'md:block')
          }
        }
      }
    },
    scrollCategory (index: number, direction: 'right' | 'left') {
      const category = this.$refs.category as HTMLElement[]
      if (category.length) {
        if (direction === 'left') {
          category[index].scrollLeft += 100
        } else {
          category[index].scrollLeft -= 100
        }
      }
    },
    showOrHideArrows (index: number) {
      const category = this.$refs.category as HTMLElement[]
      const arrowLeft = this.$refs.arrowLeft as HTMLElement[]
      const arrowRight = this.$refs.arrowRight as HTMLElement[]
      if (category.length && arrowLeft.length && arrowRight.length) {
        if (category[index].scrollLeft > 0) {
          arrowRight[index].classList.remove('hidden')
          arrowRight[index].classList.add('flex')
        } else {
          arrowRight[index].classList.remove('flex')
          arrowRight[index].classList.add('hidden')
        }
        if (
          category[index].scrollWidth - category[index].offsetWidth <=
          category[index].scrollLeft
        ) {
          arrowLeft[index].classList.remove('flex')
          arrowLeft[index].classList.add('hidden')
        } else {
          arrowLeft[index].classList.remove('hidden')
          arrowLeft[index].classList.add('flex')
        }
      }
    }
  },
  watch: {
    categories: {
      immediate: true,
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.displayArrowWrapper()
        })
      }
    }
  }
})
