import { FiltersSettings } from '@/core/model/settings.model'
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ChartManager from '@/core/managers/chart.manager'
import * as KpiManager from '@/core/managers/kpi.manager'
import { ChartModel, ChartOptionsModel } from '@/core/model/chart.model'
import { FilterFormatted } from '@/core/model/filter.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import { Preview } from '@/core/model/preview.model'
import BarChart from '@/core/charts/bar.chart.service'
import UnstackBarChart from '@/core/charts/unstack-bar.chart.service'
import BarAndLineChart from '@/core/charts/barxline.chart.service'
import HorizontalBarChart from '@/core/charts/horizontal-bar.chart.service'
import LineChart from '@/core/charts/line.chart.service'
import PieChart from '@/core/charts/pie.chart.service'
import UndefinedChart from '@/core/charts/undefined.chart.service'
import GaugeChart from '@/core/charts/gauge.chart.service'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { Content } from '@/core/charts/content.model'
import KpiService from '@/core/services/kpi.service'
import { ApiResult } from '@/core/services/request.service'
import router from '@/router'
import store from '@/store'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import { computed, ComputedRef, PropType, Ref, ref } from 'vue'
import Tools from '@/core/services/tools'
import { previewHandlingPropsData } from '@/core/services/preview.service'

highchartsMore(Highcharts)
solidGauge(Highcharts)

export const previewHandlingProps = {
  id: Number,
  title: String,
  assetId: {
    type: Number,
    required: false
  },
  linkUrl: String,
  graphType: String,
  previewType: {
    type: String,
    validator: (value: string): boolean =>
      ['KPI', 'Chart', 'Custom', 'ChartAndKPI', 'None', 'Kpi'].includes(value)
  },
  previewInfos: [Object, String] as PropType<Preview | string | Preview[]>,
  size: {
    validator: (value: number): boolean => [1, 2].includes(value),
    type: Number
  },
  description: String,
  isPreview: { type: Boolean, default: true },
  kpiTitle: String,
  period: String
}

export default function previewHandling (
  props: previewHandlingPropsData,
  customDetails = '',
  error = '',
  graphDetails: ChartModel,
  loading = true,
  kpiDetails: KpiModel,
  detailed = true,
  kpiList: KpiExtended[],
  fleet?: boolean
)
:
{
  customDetailsRef : Ref<string>
  errorRef : Ref<string>
  graphDetailsRef : Ref<ChartModel>
  loadingRef : Ref<boolean>
  kpiDetailsRef : Ref<KpiModel>
  detailedRef : Ref<boolean>
  kpiListRef : Ref<KpiExtended[]>
  handleDetailsKpi : (kpis: Preview[], filter?: FilterFormatted[] | undefined) => Promise<void>
  handleKpi : (id: number, filter?: FilterFormatted[] | undefined, compatibleFilters?: number[] | undefined) => Promise<void>
  handleChart : (id: number, filter?: FilterFormatted[] | undefined, compatibleFilters?: number[] | undefined) => void
  displayPreview : (period?: string, assetId?: number) => void
  chartOptions : ComputedRef<Partial<ChartOptionsModel> | Record<string, never>>
  kpiToGauge : (title: string, value: string, unit: string, type: string) => Partial<ChartOptionsModel>
} {
  const customDetailsRef = ref(customDetails)
  const errorRef = ref(error)
  const graphDetailsRef = ref(graphDetails)
  const loadingRef = ref(loading)
  const kpiDetailsRef = ref({ ...kpiDetails })
  const detailedRef = ref(detailed)
  const kpiListRef = ref(kpiList)

  const chartOptions = computed((): Partial<ChartOptionsModel> | Record<string, never> => {
    const height = detailedRef.value
      ? '430px'
      : !detailedRef.value && !Tools.isEmpty(kpiDetailsRef.value) && props.graphType !== 'PIE' && !fleet
          ? '87px'
          : !detailedRef.value && !Tools.isEmpty(kpiDetailsRef.value) && props.graphType !== 'PIE' && fleet
              ? '87px'
              : !detailedRef.value && !Tools.isEmpty(kpiDetailsRef.value) && props.graphType === 'PIE'
                  ? '125px'
                  : !detailedRef.value && router.currentRoute.value.name === 'assetDetail'
                      ? '125px'
                      : '125px'

    const options: IGeneratorOption = {
      height,
      isDetailed: detailedRef.value,
      title: props.title,
      description: props.description,
      previewType: props.previewType
    }
    if (props.graphType === 'BAR') {
      return BarChart.createChart(graphDetailsRef.value as Content, options)
    } else if (props.graphType === 'UNSTACK-BAR') {
      return UnstackBarChart.createChart(graphDetailsRef.value as Content, options)
    } else if (props.graphType === 'BARXLINE') {
      return BarAndLineChart.createChart(graphDetailsRef.value as Content, options)
    } else if (props.graphType === 'H-BAR') {
      return HorizontalBarChart.createChart(graphDetailsRef.value as Content, options)
    } else if (props.graphType === 'LINE') {
      return LineChart.createChart(graphDetailsRef.value as Content, options)
    } else if (props.graphType === 'PIE') {
      return PieChart.createChart(graphDetailsRef.value as Content, options)
    } else {
      return UndefinedChart.createChart(graphDetailsRef.value as Content, options)
    }
  })

  function displayPreview (period?: string, assetId?:number) {
    const filters: FilterFormatted[] = []
    const compatibleFilters: number[] = []
    if (router.currentRoute.value.name === 'assetDetail') {
      filters.push(...[
        { id: (store.state.filtersSettings as FiltersSettings).id_PeriodFilter as number, members: period || 'L1W' },
        { id: (store.state.filtersSettings as FiltersSettings).id_AssetFilter as number, members: [+(router.currentRoute.value.params.assetId as string)] }
      ])
      const compFilters = props.previewInfos ? ((props.previewInfos as Preview).compatibleFilters as number[]) : []
      if (compFilters && compFilters.length) compatibleFilters.push(...compFilters)
    } else if (router.currentRoute.value.name === 'map' && store.state && store.state.filtersSettings) {
      filters.push(...[
        { id: (store.state.filtersSettings as FiltersSettings).id_PeriodFilter as number, members: period || 'L1W' },
        { id: (store.state.filtersSettings as FiltersSettings).id_AssetFilter as number, members: [assetId as number] }
      ])
      const compFilters = props.previewInfos ? ((props.previewInfos as Preview).compatibleFilters as number[]) : []
      if (compFilters && compFilters.length) compatibleFilters.push(...compFilters)
    }

    if (props.previewType === 'Chart' && props.previewInfos) {
      handleChart((props.previewInfos as Preview).id, filters, compatibleFilters)
    } else if (props.previewType === 'KPI' && props.previewInfos) {
      handleKpi(
        (props.previewInfos as Preview).id
          ? (props.previewInfos as Preview).id
          : (props.id as number)
        , filters, compatibleFilters)
    } else if (
      (props.previewType === 'Chart' || props.previewType === 'Kpi') &&
      !props.previewInfos
    ) {
      loadingRef.value = false
      errorRef.value = 'No data available at the moment. Please try again later'
    } else if (props.previewType === 'Custom') {
      loadingRef.value = false
      customDetailsRef.value = props.previewInfos as string
    } else if (props.previewType === 'ChartAndKPI') {
      const promises: [
        Promise<ApiResult<KpiModel>>,
        Promise<ApiResult<ChartModel>>
      ] = [
        KpiManager.Manager.detail((props.previewInfos as Preview[])[1].id, filters, compatibleFilters),
        ChartManager.Manager.detail((props.previewInfos as Preview[])[0].id, filters, compatibleFilters)
      ]

      Promise.all(promises)
        .then((response) => {
          loadingRef.value = false
          if (response[0].isError) {
            errorRef.value = response[0].Message
          } else if (response[1].isError) {
            errorRef.value = response[1].Message
          } else {
            graphDetailsRef.value = response[1].data as ChartModel
            kpiDetailsRef.value = response[0].data as KpiModel
          }
        })
        .catch((error) => {
          loadingRef.value = false
          errorRef.value = error.message
        })
    } else {
      loadingRef.value = false
    }
  }

  function kpiToGauge (title: string, value: string, unit: string, type: string) {
    return GaugeChart.createGaugeFromKpi(title, value, unit, type, false)
  }

  function handleChart (id: number, filter?: FilterFormatted[], compatibleFilters?: number[]) {
    loadingRef.value = true
    ChartManager.Manager.detail(id, filter || [], compatibleFilters || [])
      .then((response) => {
        loadingRef.value = false
        if (response.isError) {
          errorRef.value = response.Message
        } else {
          graphDetailsRef.value = response.data as ChartModel
        }
      })
      .catch((error) => {
        errorRef.value = error.message
        loadingRef.value = false
      })
  }

  async function handleKpi (id: number, filter?: FilterFormatted[], compatibleFilters?: number[]): Promise<void> {
    return KpiService.handleKpi(kpiListRef, errorRef, props, kpiDetailsRef, loadingRef, id, filter, compatibleFilters)
  }

  async function handleDetailsKpi (kpis: Preview[], filter?: FilterFormatted[]) {
    return KpiService.handleDetailsKpi(kpiListRef, errorRef, props, kpiDetailsRef, loadingRef, kpis, filter)
  }

  return {
    customDetailsRef,
    errorRef,
    graphDetailsRef,
    loadingRef,
    kpiDetailsRef,
    detailedRef,
    kpiListRef,
    handleDetailsKpi,
    handleKpi,
    handleChart,
    displayPreview,
    chartOptions,
    kpiToGauge
  }
}
