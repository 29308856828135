import { ApiClient } from '@/map/core/dal/apiClient'
import { AssetDetailModel, GetAssetDetailModel } from '@/map/core/bll/asset/assetDetail.model'
import { AssetDetailMapper } from '@/map/core/bll/asset/assetDetailMapper'
import { GlobalStore } from '@/main'

export const AssetDetailManager = {
  find (code: string): Promise<AssetDetailModel> {
    GlobalStore.mapError = ''
    return new Promise((resolve, reject) => {
      // /api/Asset/details/:id
      ApiClient.list<GetAssetDetailModel>('map/Asset/details/' + code).then(result => {
        resolve(AssetDetailMapper(result))
      }).catch((err:string) => {
        GlobalStore.mapError = err
        reject(err)
      })
    })
  }
}
