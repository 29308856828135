import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-screen text-gray-300 text-center text-xl p-6 flex flex-col" }
const _hoisted_2 = { class: "h-fit my-auto" }
const _hoisted_3 = {
  key: 0,
  class: "text-3xl mb-10"
}
const _hoisted_4 = {
  key: 1,
  class: "mb-8"
}
const _hoisted_5 = {
  key: 2,
  class: "text-3xl mb-10"
}
const _hoisted_6 = {
  key: 3,
  class: "mb-8"
}
const _hoisted_7 = {
  key: 4,
  class: "text-3xl mb-10"
}
const _hoisted_8 = {
  key: 5,
  class: "mb-8"
}
const _hoisted_9 = {
  class: "text-button-1 font-bold mb-8",
  dir: "ltr"
}
const _hoisted_10 = {
  key: 6,
  class: "mb-8"
}
const _hoisted_11 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_player = _resolveComponent("lottie-player")!
  const _component_TopButton = _resolveComponent("TopButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_lottie_player, {
        autoplay: "",
        loop: "",
        mode: "normal",
        src: "https://assets8.lottiefiles.com/packages/lf20_7e1tyd6b.json",
        speed: "0.25",
        class: "not-found-animation"
      }),
      (_ctx.type === 'notFound')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("error.notFound.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 'notFound')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("error.notFound.message1")), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 'unauthorized')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("error.unauthorized.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 'unauthorized')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("error.unauthorized.message1")), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 'serverError')
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("error.server.title")), 1))
        : _createCommentVNode("", true),
      (_ctx.type === 'serverError')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("error.server.message1")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("error.notFound.message2")), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("error.notFound.email")), 1),
      (_ctx.type !== 'unauthorized')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("error.notFound.message3")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.type !== 'unauthorized')
          ? (_openBlock(), _createBlock(_component_TopButton, {
              key: 0,
              title: _ctx.$t('home.title'),
              type: 'home',
              primary: true,
              routeName: 'home',
              style: {"width":"200px"}
            }, null, 8, ["title"]))
          : (_openBlock(), _createBlock(_component_TopButton, {
              key: 1,
              title: _ctx.$t('auth.logout'),
              type: 'logout',
              primary: true,
              style: {"width":"fit-content"},
              onClick: _ctx.logout,
              class: "m-auto"
            }, null, 8, ["title", "onClick"]))
      ])
    ])
  ]))
}