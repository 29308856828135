
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ServicesCardKpi',
  components: { GenericCardShell },
  props: {
    loading: Boolean,
    type: String,
    label: String,
    title: String,
    value: String,
    unit: String,
    description: String
  },
  setup () {
    const isHovering = ref(false)
    const hoverTitle = () => {
      isHovering.value = !isHovering.value
    }
    const getKpiColour = (label: string) => {
      return ColoursService.getColour(label)
    }

    const formatNumber = (num: number) => { return FormatService.formatNumber(num) }

    return { isHovering, hoverTitle, getKpiColour, formatNumber }
  }
})
