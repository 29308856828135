
import { defineComponent, inject } from 'vue'
import { IEntryManager } from '@/core/managers/entry/entryManager'
import { IEntry } from '@/core/managers/entry/entryModel'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'

export default defineComponent({
  name: 'timelinePopover',
  props: {
    contractId: {
      type: String,
      required: true
    },
    assetId: {
      type: String,
      required: true
    },
    periodId: {
      type: String,
      required: true
    },
    rangeTimeId: {
      type: String,
      required: true
    }
  },
  setup () {
    const controller = new AbortController()
    const timeLineHelper = inject('timelineHelper') as ITimeLineHelper
    const entryManager = inject('entryManager') as IEntryManager
    return { entryManager, controller, timeLineHelper }
  },
  data () {
    return { entry: {} as IEntry, loading: false }
  },
  async mounted () {
    this.loading = true
    this.entry = this.entryManager.init
    try {
      this.entry = await this.entryManager.find(this.contractId.toString(), this.assetId.toString(), this.periodId.toString(), this.controller.signal)
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  beforeUnmount () {
    this.controller.abort()
  }
})
