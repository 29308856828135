import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex w-full gap-4" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attribute, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createElementVNode("div", {
          onClick: ($event: any) => (_ctx.selectAttribute(i, attribute.id))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["cursor-pointer", { 'border-b-2 border-semantic-activate text-semantic-activate shadow-tab': _ctx.selectedAttributeTabIndex === i }])
          }, _toDisplayString(attribute.name), 3)
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}