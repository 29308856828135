import * as KpiManager from '@/core/managers/kpi.manager'
import { FilterFormatted } from '@/core/model/filter.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import { Preview } from '@/core/model/preview.model'
import { previewHandlingPropsData } from '@/core/services/preview.service'
import type { Ref } from 'vue'

export default class KpiService {
  public static handleDetailsKpi (
    kpiListRef: Ref<KpiExtended[]>,
    errorRef: Ref<string>,
    props: previewHandlingPropsData,
    kpiDetailsRef: Ref<KpiModel>,
    loadingRef: Ref<boolean>,
    kpis: Preview[],
    filter?: FilterFormatted[]
  ): void {
    if (!kpiListRef.value.length) {
      kpiListRef.value = []
    }
    loadingRef.value = true

    for (const kpi of kpis) {
      KpiManager.Manager.detail(kpi.id, filter || [], Object.values(kpi.compatibleFilters))
        .then((response) => {
          loadingRef.value = false
          if (response.isError) {
            errorRef.value = response.Message
          } else {
            if (props.isPreview) {
              kpiDetailsRef.value = response.data as KpiModel
            } else {
              const value: KpiExtended = response.data as KpiModel
              value.title = kpi.label
              value.type = kpi.type
              value.description = kpi.description
              const index = kpiListRef.value.findIndex((v) => v.title === value.title)
              if (index === -1) {
                kpiListRef.value.push(value)
              } else {
                kpiListRef.value[index] = value
              }
            }
          }
        })
        .catch((error) => {
          errorRef.value = error.message
          loadingRef.value = false
        })
    }
  }

  public static handleKpi (
    kpiListRef: Ref<KpiExtended[]>,
    errorRef: Ref<string>,
    props: previewHandlingPropsData,
    kpiDetailsRef: Ref<KpiModel>,
    loadingRef: Ref<boolean>,
    id: number,
    filter?: FilterFormatted[],
    compatibleFilters?: number[]
  ): void {
    KpiManager.Manager.detail(id, filter || [], compatibleFilters || [])
      .then((response) => {
        loadingRef.value = false
        if (response.isError) {
          errorRef.value = response.Message
        } else {
          if (props.isPreview) {
            kpiDetailsRef.value = response.data as KpiModel
          } else {
            kpiListRef.value.push(response.data as KpiModel)
          }
        }
      })
      .catch((error) => {
        errorRef.value = error.message
        loadingRef.value = false
      })
  }
}
