interface ReportInstances {
    id: number
    name: string
    instanceDate: string
}

export interface ReportVersion {
    version: number
    generationDate: string
}

export interface ReportModel {
    instances: ReportInstances[]
}

export interface ReportByInstanceModel {
    versions: ReportVersion[]
}

interface ReportServiceGroup {
    id: number
    title: string
    description?: string
    isPreview?: true
    type?: string
    tags?: string[]
    total?: number
}
export interface ReportService {
    name: string
    count: number
    groups: ReportServiceGroup[]
    active?: boolean
}

// keep it

export interface ReportAttributeItem {
    id: number
    name: string
    instanceDate: string
    fileFormat: string
    tinyGuid: string
    // used by component only - not by manager
    period?: string
    IsDownloading?: boolean
    // end - used by component only - not by manager
}

export interface ReportAttribute {
    pageIndex: number
    pageSize: number
    totalCount: number
    totalPages: number
    indexFrom: number
    items: ReportAttributeItem[]
    hasPreviousPage: boolean
    hasNextPage: boolean
}

export interface Instance {
    id: number
    name: string
    instanceDate: string
    fileFormat: string
    tinyGuid:string
}

export interface Attribute {
    id: number
    name: string
    instances: Instance[]
    count: number
    nextPage: boolean
    selected?: boolean
}

export interface ReportDetail {
    id: number
    name: string
    attributes: Attribute[]
    selected?: boolean
}

export interface Report {
    id: number
    title: string
    description: string
    total: number
    reports?: ReportDetail
    active?: boolean
    isAssetRelated?: boolean

}

export interface ServiceReportGroup {
    name: string
    count: number
    groups: Report[]
    active?: boolean
}

export interface ReportServiceCategory {
    name: string
    count: number
    groups: ServiceReportGroup[]
    active?: boolean
}

export interface ReportsListModel {
    serviceCategories: ReportServiceCategory[]
}

export class UploadableFile {
  public readonly file: File
  public readonly id: string
  public readonly url: string
  public status: string|boolean|null

  constructor (file: File) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}

export interface UploadServiceReportModel {
  reportId: number
  attributeId: number
  Title: string
  ClientDepartmentCode: string
  Date: Date
  // Format: string
  File: UploadableFile
}

export interface UploadAssetReportModel {
  reportId: number
  assetId: number
  attributeId: number
  Title: string
  ClientDepartmentCode: string
  Date: Date
  // Format: string
  File: UploadableFile
}
