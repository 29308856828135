
import { defineComponent } from 'vue'
import ReportManager from '@/core/managers/report.manager'
import { useToast } from 'vue-toastification'
import ReportAttributeTab from '@/components/reports/categories/services/reports/attributes/ReportAttributeTab.vue'
import ReportAttributeTabLoading
  from '@/components/reports/categories/services/reports/attributes/ReportAttributeTab.loading.vue'
import ReportInstanceRow from '@/components/reports/categories/services/reports/list/ReportRow.vue'
import ReportInstanceLoading from '@/components/reports/categories/services/reports/list/ReportInstanceLoading.vue'
import ServiceReportForm from '@/components/reports/categories/services/reports/form/ServiceReportForm.vue'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import Pagination from '@/components/common/pagination.vue'
import { ReportAttributeItem, ReportDetail } from '@/core/model/report.model'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import { GStoreModel } from '@/main'
import { Report2Manager } from '@/map/core/bll/report/reportManager'
import FormatService from '@/core/services/format.service'

export default defineComponent({
  inject: ['GStore'],
  name: 'ServiceReport',
  components: {
    ReportAttributeTab,
    ReportInstanceRow,
    ReportInstanceLoading,
    ReportAttributeTabLoading,
    ServiceReportForm,
    GenericCardShell,
    Pagination
  },
  props: {
    id: { type: Number, required: true },
    reportTitle: { type: String, required: true },
    showForm: { type: Boolean, default: false }
  },
  data () {
    return {
      attributeId: undefined as number|undefined,
      loading: true,
      loadingInstance: true,
      report: {} as ReportDetail,
      instances: [] as ReportAttributeItem[],
      totalPages: 0,
      currentPage: 0,
      toast: useToast()
    }
  },
  async created () {
    await this.retrieveReportDetail()
  },
  methods: {
    onDelete (args:{reportId: string, instanceId: number}) {
      Report2Manager.deleteReport(args.reportId, args.instanceId).then(() => {
        this.instances = this.instances.filter(item => item.id !== args.instanceId);
        (this.GStore as GStoreModel).msgSuccess = 'item remove'
        setTimeout(() => {
          (this.GStore as GStoreModel).msgSuccess = ''
        }, 4000)
      })
    },
    async retrieveReportDetail () {
      this.loading = true

      const res = await ReportManager.detail(this.id)
      if (res.data && !res.isError) {
        this.report = res.data
      }

      this.loading = false
    },
    async closeForm () {
      this.$emit('closeForm')
      await this.retrieveReportDetail()
    },
    async updateSelectedAttribute (selectedAttributeId: number|undefined) {
      this.attributeId = selectedAttributeId
      await this.fetchReports()
    },
    async download (item: ReportAttributeItem) {
      if (item.IsDownloading) return
      item.IsDownloading = true

      try {
        const filename = item.name + ' - ' + FormatService.formatDate(item.instanceDate) + '.' + item.fileFormat
        const displayFilename = this.reportTitle + '-' + item.name + '.' + item.fileFormat
        await ReportManager.DownloadInstance(displayFilename, filename, item.tinyGuid)
      } finally {
        item.IsDownloading = false
      }
    },
    async fetchReports (pageIndex = 0) {
      this.instances = []
      this.loadingInstance = true
      if (this.attributeId) {
        this.instances = await this.retrieveReportInstance(this.attributeId, pageIndex)
      } else {
        this.instances = await this.retrieveDefaultInstance()
      }
      this.loadingInstance = false
    },
    async retrieveReportInstance (attributeId: number, pageIndex = 0): Promise<ReportAttributeItem[]> {
      const numberOfElementPerPage = 5
      const response = await ReportManager.byAttribute(this.id, attributeId, pageIndex, numberOfElementPerPage)
      if (response.isError) {
        const error = this.$t('allReports.error', {
          error: response.Message
        })
        this.toast.error(error)
      }
      if (response.data === undefined) return []

      this.currentPage = response.data.pageIndex + 1 // pageIndex start at 0 - currentPage start at 1
      this.totalPages = response.data.totalPages
      return response.data.items
    },
    async retrieveDefaultInstance (): Promise<ReportAttributeItem[]> {
      let instances = [] as ReportAttributeItem[]
      for (const a of this.report.attributes) {
        instances = instances.concat(a.instances.map(i => {
          return {
            id: i.id,
            name: i.name,
            instanceDate: i.instanceDate,
            fileFormat: i.fileFormat,
            period: a.name,
            tinyGuid: i.tinyGuid
          } as ReportAttributeItem
        }))
      }
      this.currentPage = 0
      this.totalPages = 0
      // sort instance by instanceDate
      return instances.sort((a, b) => new Date(b.instanceDate).getTime() - new Date(a.instanceDate).getTime())
    },
    async changePageIndex (selectedPage: number) {
      await this.fetchReports(selectedPage - 1) // pageIndex start at 0 - selectedPage start at 1
    }
  }
})
