
import { defineComponent } from 'vue'

import toaster from '@/map/views/components/misc/toaster.vue'
import loader from '@/map/views/components/misc/loader.vue'
import ArrowForward from '@/components/icons/ArrowForward.vue'
import mapMaker from '@/map/core/service/map.maker'
import flyToComposable from '@/map/views/composables/flyTo.composable'
import mapEventDelegation from '@/map/core/service/map.eventDelegation'
import popoverAssetComposable from '@/map/views/composables/popoverAsset.composable'
import fetchStepComposable from '@/map/views/composables/fetchStep.composable'
import popoverStepComposable from '@/map/views/composables/popoverStep.composable'
import fetchConcessionComposable from '@/map/views/composables/fetchConcession.composable'
import fetchZoneComposable from '@/map/views/composables/fetchZone.composable'
import { tileLayer } from 'leaflet'
import tileStore from '@/map/core/bll/map/tile.store'
import popoverStaticComposable from '@/map/views/composables/popoverStatic.composable'
import fleetAssetComposable from '@/map/views/composables/fleetAsset.composable'
import selectedBlueComposable from '@/map/views/composables/selectedSetBlue.composable'

export default defineComponent({
  components: {
    toaster,
    loader,
    ArrowForward
  },
  props: ['code'],
  setup () {
    return { mapId: 'mapPage2' }
  },
  computed: {
    codeFormated ():string {
      return this.code.split('|')[1]
    }
  },
  mounted () {
    // todo get directly the assetCode instead the imo code from fleet
    // AssetDetailManager.find(this.code).then(asset => {
    this.$router.push({ query: { fleetAsset: this.code.split('|')[1], flyTo: this.code.split('|')[1], fetch: this.code.split('|')[1] } })
    //  })

    const map = mapMaker.init(this.mapId, { isScaleControl: false })

    mapEventDelegation.mounted((map.getPane('markerPane') as HTMLElement), this.$router)
    map.addLayer(tileLayer(tileStore.state.url))
    flyToComposable.mounted(map, this.$router)
    fetchConcessionComposable.mounted(map)
    fetchZoneComposable.mounted(map)
    fleetAssetComposable.mounted(map, this.$router)
    if (this.$route.params.categoryId === '3') {
      popoverStepComposable.mounted(map, this.$router)
      selectedBlueComposable.mounted(map, this.$router)
      fetchStepComposable.mounted(map, this.$router)
      popoverStaticComposable.mounted(map, this.$router)
    }

    popoverAssetComposable.mounted(map, this.$router)
  }

})
