

import zoneRow from '@/map/views/components/drawers/right/zone/zone.row.vue'
import { defineComponent } from 'vue'
import ToggleConcession from '@/map/views/components/drawers/right/zone/concession.toggle.vue'
import zoneStore from '@/map/core/bll/zone/zone.store'
import zoneManager from '@/map/core/bll/zone/zone.manager'
import { groupZoneLayers } from '@/map/core/bll/zone/zone.group'

export default defineComponent({
  components: {
    ToggleConcession,
    zoneRow
  },
  setup () {
    return { state: zoneStore.state }
  },
  computed: {
    noCategory: zoneStore.getter.noCategory,
    atLeastOneCategory: zoneStore.getter.atLeastOneCategory
  },
  methods: {
    update (args: { code: string, value : boolean }) {
      zoneStore.action.update(args)
      zoneManager.toggleLayer(groupZoneLayers, args.code, args.value)
    }
  }
})

