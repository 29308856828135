
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import { defineComponent } from 'vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'

export default defineComponent({
  components: {
    LeftArrowIcon,
    RightArrowIcon
  },
  props: {
    currentPage: { type: Number, required: true },
    totalPages: { type: Number, required: true },
    numberOfButton: { type: Number, required: false, default: 4 }
  },
  data () {
    return {
      paginationButton: 'h-10 w-10 bg-bg-2 rounded-full body-1 text-text-alt font-bold flex items-center justify-center cursor-pointer'
    }
  },
  methods: {
    click (index: number) {
      this.$emit('selectPage', index)
    }
  },
  computed: {
    innerPagination () {
      const pagination: number[] = []
      // insert button previous
      if (this.currentPage > 1) {
        pagination.push(this.currentPage - 1)
      }

      // insert button from currentPage to currentPage + numberOfButton (max value = totalPages)
      const maxPage = this.currentPage + this.numberOfButton < this.totalPages ? this.currentPage + this.numberOfButton : this.totalPages
      for (let x = this.currentPage; x <= maxPage; x++) {
        pagination.push(x)
      }
      return pagination
    }
  }
})
