import { Content } from '@/core/charts/content.model'
import { IChartGenerator, IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { ChartOptionsModel } from '@/core/model/chart.model'
import { textAlt, textPrim } from '@/core/services/colours.service'

class UndefinedChartGenerator implements IChartGenerator {
  createChart (content: Content, options: IGeneratorOption): Partial<ChartOptionsModel> | Record<string, never> {
    return {
      chart: {
        height: options.height,
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Arial'
        }
        // margin: [0, 0, 0, 0]
      },
      lang: { noData: 'Invalid Chart' },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: textAlt
        }
      },
      title: {
        text: options.isDetailed ? options.title : null,
        style: { color: textPrim, fontWeight: 'bold' },
        margin: 50
      },
      subtitle: {
        text: options.isDetailed ? options.description : null,
        style: { color: textAlt }
      },
      dataLabels: { enabled: options.isDetailed },
      series: [],
      credits: {
        enabled: false
      }
    }
  }
}

export default new UndefinedChartGenerator()
