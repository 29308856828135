import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-display mb-2.5 select-none" }
const _hoisted_2 = { class: "body body-1 mb-5 font-display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.subtitle), 1)
  ], 64))
}