import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-574a634c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-4/5" }
const _hoisted_2 = {
  key: 1,
  class: "skeleton"
}
const _hoisted_3 = {
  key: 2,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sectors.length)
      ? (_openBlock(), _createBlock(_component_CustomMultiSelect, {
          key: 0,
          placeholder: _ctx.modelValue.toString(),
          options: _ctx.sectors,
          default: _ctx.modelValue,
          "multiple-simple": true,
          class: "select w-45",
          onSelection: _ctx.departmentSelection
        }, null, 8, ["placeholder", "options", "default", "onSelection"]))
      : (_ctx.loading && !_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (!_ctx.loading && _ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true)
  ]))
}