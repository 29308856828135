import { reactive, readonly } from 'vue'
import stepManager from '@/map/core/bll/step/step.manager'
import { StepModel } from '@/map/core/bll/step/step.model'

const state = reactive({ assetCode: '', steps: [] as StepModel[], initialized: false })

const mutation = {
  reset: function () {
    state.assetCode = ''
    state.steps = []
    state.initialized = false
  },
  fetch_success: function (assetCode : string, steps : StepModel[]) {
    state.assetCode = assetCode
    state.steps = steps
    state.initialized = true
  }
}
const effect = {
  fetch: function (assetCode : string) {
    stepManager.fetch(assetCode).then(steps => {
      mutation.fetch_success(assetCode, steps)
    })
  }
}
const action = {
  fetch: function (assetCode : string): void {
    effect.fetch(assetCode)
  },
  reset: function (): void {
    mutation.reset()
  }
}

export default { action, state: readonly(state) }
