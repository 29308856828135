import { Activity, TimelineModel } from '@/core/model/timeline.model'

export interface EventsMapped extends Activity {
  location: string
  context: string
  minutesStart?: number
  minutesEnd?: number
  isOverlapping?: boolean
  isHoverable?: boolean
  hovered?: boolean
  height?: number
  heightIndex?: number
}

export interface EventsMappedForJourney {
  events: EventsMapped[]
  location: string
  context: string
}

export default class TimeLineService {
  public static getCutPeriods (events: EventsMapped[]): EventsMapped[][] {
    const stack: EventsMapped[][] = []
    let index = 0
    let lastStartDate; let lastEndDate; let newEvents
    events
      .sort((a, b) => {
        if (a.startTime > b.startTime) return 1
        if (a.startTime < b.startTime) return -1
        return 0
      })
      .forEach((interval) => {
        const start = new Date(interval.startTime)
        const end = new Date(interval.endTime)

        interval.minutesStart = start.getMinutes() + (start.getHours() * 60)
        interval.minutesEnd = end.getMinutes() + (end.getHours() * 60)
      })

    while (events.length > 0) {
      stack[index] = []
      newEvents = []
      stack[index].push(events[0])
      lastStartDate = events[0].startTime
      lastEndDate = events[0].endTime
      for (let i = 1; i < events.length; i++) {
        if (events[i].endTime < lastStartDate) {
          stack[index].push(events[i])
          lastStartDate = events[i].startTime
          delete events[i]
        } else if (events[i].startTime >= lastEndDate) {
          stack[index].push(events[i])
          lastEndDate = events[i].endTime
        } else {
          if (i === 1) {
            events[0].isOverlapping = true
          }
          if (events[i - 1].startTime === events[i].startTime) {
            events[i - 1].isOverlapping = true
          }
          events[i].isOverlapping = true
          newEvents.push(events[i])
        }
      }
      events = newEvents
      index++
    }

    return stack
  }

  public static getLocations (timeline: TimelineModel): { location: string, startTime: string, endTime: string, minutesStart: number, minutesEnd: number }[] {
    const locations = []
    for (const action of timeline.timeSheetContexts) {
      action.activities
        .sort(function (a, b) {
          if (a.startTime > b.startTime) return 1
          if (a.startTime < b.startTime) return -1
          return 0
        })
      const start = new Date(action.activities[0].startTime)
      const end = new Date(action.activities[action.activities.length - 1].endTime)

      const minutesStart = start.getMinutes() + (start.getHours() * 60)
      const minutesEnd = end.getMinutes() + (end.getHours() * 60)
      locations.push({
        location: action.location,
        context: action.context,
        startTime: action.activities[0].startTime,
        endTime: action.activities[action.activities.length - 1].endTime,
        minutesStart,
        minutesEnd
      })
    }
    return locations
  }

  public static partitionIntoOverlappingRanges (events: EventsMapped[], journey?: boolean): EventsMapped[][] | EventsMappedForJourney[]| undefined {
    if (events && events.length) {
      events
        .sort(function (a, b) {
          if (a.endTime > b.endTime) { return -1 }
          if (a.endTime < b.endTime) { return 1 }
          return 0
        })
        .forEach((event) => {
          const start = new Date(event.startTime)
          const end = new Date(event.endTime)
          event.minutesStart = start.getMinutes() + (start.getHours() * 60)
          event.minutesEnd = end.getMinutes() + (end.getHours() * 60)
          event.height = 1
          event.heightIndex = -1
        })
      const getMaxEnd = (events: EventsMapped[]) => {
        if (events.length === 0) return false
        events.sort(function (a, b) {
          if (a.startTime > b.startTime) { return 1 }
          if (a.startTime < b.startTime) { return -1 }
          return 0
        })
        return events[0].startTime
      }
      const rearrangedArray = []
      let eventIndex = 0
      rearrangedArray[eventIndex] = [events[0]]

      for (let i = 1, l = events.length; i < l; i++) {
        if ((events[i].endTime <= events[i - 1].endTime) &&
        (events[i].endTime > getMaxEnd(rearrangedArray[eventIndex]))
        ) {
          rearrangedArray[eventIndex].push(events[i])
        } else {
          eventIndex++
          rearrangedArray[eventIndex] = [events[i]]
        }
      }
      for (const array of rearrangedArray) {
        array
          .sort(function (a, b) {
            if (a.endTime > b.endTime) {
              return -1
            }
            if (a.endTime < b.endTime) { return 1 }
            return 0
          })
        if (array.length > 1 && journey) {
          for (const [index, interval] of array.entries()) {
            if (index === 0) {
              interval.heightIndex = 0
            }
            if (array[index + 1] && interval.endTime === array[index + 1].endTime) {
              array[index + 1].heightIndex = interval.heightIndex
            }
            if (array[index - 1] && interval.endTime < array[index - 1].endTime) {
              interval.heightIndex = (array[index - 1].heightIndex as number) + 1
            }
            if (array[index + 1] && array[index + 1].endTime < interval.endTime) {
              (interval.height as number) += 1
            }
            if (array[index - 1] && interval.startTime < array[index - 1].startTime) {
              (interval.height as number) += 1
            }
            if (array[index + 1] && array[index + 1].startTime > interval.startTime) {
              (interval.height as number) += 1
            }
            if (array[index + 1] && interval.startTime < array[index + 1].startTime && interval.endTime >= array[index + 1].endTime) {
              (interval.height as number) += 1
            }
            if (array[index + 1] && interval.startTime > array[index + 1].endTime) {
              (interval.height as number) -= 1
            }
            if (array[index - 1] && interval.endTime < array[index - 1].startTime) {
              (interval.heightIndex as number) += 1
            }
            if (array.length > 2 && array[index - 1] && interval.endTime < array[index - 1].endTime && interval.startTime < array[index - 1].startTime) {
              (interval.height as number) += 1
            }
            if (array.length > 2 && interval.startTime === array[0].startTime && index !== 0) {
              (interval.height as number) -= 1
            }

            if (array.length > 2 && interval.endTime < array[array.length - 1].endTime && interval.startTime > array[array.length - 1].startTime) {
              (interval.height as number) += 1
            }
          }

          const eventsWithIdenticalStartAndEnd = array.reduce((a, e) => {
            (a.set(e.startTime, (a.get(e.startTime) ?? 0) + 1)) && (a.set(e.endTime, (a.get(e.endTime) ?? 0) + 1))
            return a
          }, new Map())
          const arrayWithIdenticalStartAndEnd = array.filter(e => eventsWithIdenticalStartAndEnd.get(e.startTime) && eventsWithIdenticalStartAndEnd.get(e.endTime) > 1)
          const maxHeight = Math.max(...arrayWithIdenticalStartAndEnd.map(o => (o.height as number)))
          if (arrayWithIdenticalStartAndEnd.length) {
            for (const interval of array) {
              if (interval.startTime === arrayWithIdenticalStartAndEnd[0].startTime && interval.endTime === arrayWithIdenticalStartAndEnd[0].endTime) { interval.height = maxHeight }
            }
          }
        }
      }

      if (journey) {
        const journeyArray = []
        for (const array of rearrangedArray) {
          journeyArray.push({ events: array, location: array[0].location, context: array[0].context })
        }
        return journeyArray
      }

      return rearrangedArray
    }
  }
}
