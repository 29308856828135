
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Star',
  props: {
    fill: { type: String, default: 'none' },
    stroke: { type: String, default: 'none' }
  }
})
