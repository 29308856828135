
import { DateTime } from 'luxon'
import { defineComponent } from 'vue'
import { AssetDetailModel } from '@/map/core/bll/asset/assetDetail.model'
import { AssetDetailManager } from '@/map/core/bll/asset/assetDetail.manager'

import SpeedometerIcon from '@/components/icons/SpeedometerIcon.vue'
import Compass from '@/components/icons/Compass.vue'
import wifiIcon from '@/components/icons/WifiIcon.vue'
import VesselLengthIcon from '@/components/icons/VesselLengthIcon.vue'

import { duration } from '@/map/core/utilities'
import { KpiManager } from '@/map/core/bll/kpi/kpi.manager'

import SliderContainer from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider-container.vue'

import { MapKpiModel } from '@/map/core/bll/kpi/kpi.model'

export default defineComponent({
  components: {
    SpeedometerIcon,
    Compass,
    VesselLengthIcon,
    wifiIcon,
    SliderContainer
  },
  data () {
    return { loading: false, error: false, asset: {} as AssetDetailModel, kpiList: [] as MapKpiModel[] }
  },

  watch: {
    '$route.query.panelDetail': {
      immediate: true,
      handler (newValue: string) {
        if (newValue && newValue !== '') {
          this.loading = true
          this.error = false

          AssetDetailManager.find(newValue).then(result => {
            this.asset = result
            KpiManager.list(this.asset.categoryId, this.asset.assetId).then(result => {
              this.kpiList = result
            }).catch(() => {
              this.error = true
            })
              .finally(() => {
                this.loading = false
              })
          }).catch(() => {
            this.error = true
            this.loading = false
          })
        }
      }
    }
  },
  methods: {
    durationStr (lastPosition: string): string {
      return duration(lastPosition)
    },
    formatDate (date: string):string {
      return DateTime.fromISO(date, { zone: 'UTC' }).toFormat('LLL dd, yyyy, HH:mm ') + '(UTC)'
    }
  }

})

