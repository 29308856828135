
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import { defineComponent, ref } from 'vue'
import DashboardSmallCard from '@/components/cards/DashboardSmallCard.vue'
import { DOMEvent } from '@/core/model/event.model'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'DashboardCard',
  components: {
    GenericCardShell, DashboardSmallCard
  // FavouriteButton
  },
  props: { title: String, id: Number, loading: Boolean, service: String, description: String, previewImageUrl: String },
  setup (props) {
    const isFav = ref(false)
    const router = useRouter()
    const goToDashboard = (event: DOMEvent<HTMLElement>) => {
      if (!event.target.matches('svg') && !event.target.matches('path')) {
        router.push({
          name: 'dashboard',
          params: { dashboardId: props.id, title: props.title }
        })
      }
    }

    return { isFav, goToDashboard }
  }
})
