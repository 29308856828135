
import TopButton from '@/components/buttons/TopButton.vue'
import ServiceDetailTitle from '@/components/titles/ServiceDetailTitle.vue'
import * as DashboardManager from '@/core/managers/dashboard.manager'
import { DashboardModel } from '@/core/model/dashboard.model'
import { ILogger } from '@/core/services/loggers/ilogger.service'
import Tools from '@/core/services/tools'
import { PAGE_TITLE } from '@/router'
import * as pbi from 'powerbi-client'
import { defineComponent, inject } from 'vue'
import { useToast } from 'vue-toastification'

export default defineComponent({
  components: { ServiceDetailTitle, TopButton },
  setup () {
    const toast = useToast()
    return { toast, logger: inject('ILogger') as ILogger }
  },
  data (): {
    pbiInfos: DashboardModel;
    mounted: boolean;
    dashboardHeight: number;
    error: string;
    pageName: string;
    description: string;
    powerbi: pbi.service.Service;
    loading: boolean;
    linkParams: string;
    dashboardLoaded: boolean;
    } {
    return {
      pbiInfos: {} as DashboardModel,
      mounted: false,
      dashboardHeight: 0,
      error: '',
      pageName: '',
      description: '',
      powerbi: null as unknown as pbi.service.Service,
      loading: true,
      linkParams: '0',
      dashboardLoaded: false
    }
  },
  mounted () {
    this.mounted = true
    this.getDashBoardHeight()
    window.addEventListener('resize', this.getDashBoardHeight)
    DashboardManager.Manager.detail(
      parseInt(this.$route.params.dashboardId as string)
    ).then((response) => {
      this.loading = false

      if (response.isError) {
        this.error = `Unable to load report : ${response.Message}`
        this.toast.error(this.error)
      } else {
        if (response.data) {
          this.pbiInfos = response.data
          this.createDashboard()
          this.pageName = this.pbiInfos.dashboardTitle
          this.description = this.pbiInfos.name
          Tools.updatePageName(
            PAGE_TITLE[this.$route.name as string],
            this.pageName
          )
          this.linkParams = this.pbiInfos.id_Service.toString()

          this.logger.logEvent({
            name: 'UserNavigation',
            properties: {
              contextName: 'Dashboard',
              categoryName: '',
              subcategoryName: this.pbiInfos.dashboardTitle,
              ActionName: 'view'
            }
          })
        }
      }
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.getDashBoardHeight)
  },
  methods: {
    createDashboard () {
      const permissions = pbi.models.Permissions.All
      const filters = this.pbiInfos.filters.map((f) => {
        return {
          $schema: f.$schema,
          target: {
            table: f.target.table,
            column: f.target.column
          },
          operator: (f as any).operator,
          values: [...(f as any).values]
        }
      })

      const config = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: this.pbiInfos.token,
        embedUrl: this.pbiInfos.embedUrl,
        id: this.pbiInfos.id,
        pageView: 'actualSize',
        permissions,
        filters,
        settings: {
          filterPaneEnabled: false,
          layoutType:
            document.ontouchstart === undefined
              ? pbi.models.LayoutType.Master
              : pbi.models.LayoutType.MobilePortrait
        }
      } as pbi.IReportEmbedConfiguration
      this.powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      const dashboardContainer = this.$refs.dashboardContainer
      const dashboard = this.powerbi.embed(
        dashboardContainer as HTMLElement,
        config
      )
      this.dashboardLoaded = true

      dashboard.off('loaded')
      dashboard.off('rendered')
    },
    getDashBoardHeight () {
      // footer height is commented since footer is disabled for now
      const title = document.getElementById('title')
      const header = document.getElementById('header')
      const navBar = document.getElementById('navigationBar')
      const backLinkHeight = title ? title.offsetHeight : 0
      const windowHeight = window.innerHeight
      const headerHeight = header ? header.offsetHeight : 0
      const navBarHeight = navBar ? navBar.offsetHeight : 0
      // const footerHeight = document.getElementById('footer')?.offsetHeight
      this.dashboardHeight =
        windowHeight -
        headerHeight -
        navBarHeight -
        // footerHeight! -
        backLinkHeight -
        120
    },
    fullscreen () {
      if (this.$refs.dashboardContainer) {
        // Get a reference to the embedded report.
        const report = this.powerbi.get(
          this.$refs.dashboardContainer as HTMLElement
        )
        // Displays the report in full screen mode.
        report.fullscreen()
        return
      }
      const container = document.getElementById('dashboardContainer')
      if (container !== null) {
        const report = this.powerbi.get(container)
        // Displays the report in full screen mode.
        report.fullscreen()
      }
    }
  }
})
