
import { defineComponent, inject, Ref, ref } from 'vue'
import { Manager as UserManager } from '@/core/managers/user.manager'
import PageTitle from '@/components/titles/PageTitle.vue'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import DepartmentSelect from '@/components/Filters/DepartmentSelect.vue'
import SectorsSelect from '@/components/Filters/SectorsSelect.vue'
import { UserModel } from '@/core/model/user.model'
import { DepartmentModel } from '@/core/model/context.model'

export default defineComponent({
  components: {
    PageTitle,
    LogoutIcon,
    DepartmentSelect,
    SectorsSelect
  },
  name: 'Profile',
  inject: ['ILoginService'],
  setup () {
    const department = ref('')
    const sectors: Ref<string[]> = ref([])

    return {
      loginService: inject('ILoginService') as ILoginService,
      _userManager: UserManager,
      department,
      sectors
    }
  },
  data () {
    return { user: {} as UserModel }
  },
  async mounted () {
    const userResponse = await this._userManager.detail()
    this.user = userResponse.data as UserModel
  },
  methods: {
    async logout () {
      await this.loginService.SignOut()
      await this.loginService.SignIn()
    },
    departmentChanged ($event: DepartmentModel) {
      this.department = $event.name
    },
    changeSectors ($event: string[]) {
      this.sectors = $event
    }
  }
})
