
import { defineComponent, PropType } from 'vue'
import { AssetModel } from '@/map/core/bll/category/categoryModel'

export default defineComponent({
  props: {
    asset: {
      type: Object as PropType<AssetModel>,
      required: true
    }
  }
})
