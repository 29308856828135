
import { defineComponent, inject, PropType } from 'vue'

import { IAssetFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'

export default defineComponent({
  name: 'timeLineRowRight',
  components: {
    // timelineCol
  },
  props: {
    assetFilter: {
      type: Object as PropType<IAssetFilter>,
      required: true
    },
    contractId: {
      type: Number,
      required: true
    }
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  }
})
