import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-908e968a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["id", "onChange", "value"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["id", "onChange", "value"]
const _hoisted_10 = ["for"]
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative py-2.5 px-4 text-bg-2 font-bold bg-semantic-activate rounded-lg custom-select items-center",
    tabindex: _ctx.tabindex,
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.open = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected text-bg-2 font-semibold flex font-bold border-cyan-500 justify-between w-full items-center", { open: _ctx.open }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      (_ctx.selected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.selected), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.placeholder), 1)),
      _createElementVNode("div", {
        class: _normalizeClass(["arrow-container ml-2.5", {'rotate-x-180' : !_ctx.open}])
      }, null, 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["items py-2.5 px-4 body body-1 body-alt font-bold bg-bg-0 z-2", { hidden: !_ctx.open }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["py-2.5", { checked: _ctx.selection.includes(option.label) && _ctx.multiple }]),
          onClick: _withModifiers(($event: any) => (_ctx.selectOption(option)), ["prevent"])
        }, [
          (_ctx.multiple)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  class: "custom-checkbox",
                  id: `custom-checkbox-${i}`,
                  type: "checkbox",
                  onChange: ($event: any) => (_ctx.emitSelection(option)),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selection) = $event)),
                  value: option
                }, null, 40, _hoisted_6), [
                  [_vModelCheckbox, _ctx.selection]
                ]),
                _createElementVNode("label", {
                  for: `custom-checkbox-${i}`
                }, _toDisplayString(option.label), 9, _hoisted_7)
              ]))
            : (_ctx.multipleSimple)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    class: "custom-checkbox",
                    id: `custom-checkbox-${i}`,
                    type: "checkbox",
                    onChange: ($event: any) => (_ctx.emitSelection(option)),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selection) = $event)),
                    value: option
                  }, null, 40, _hoisted_9), [
                    [_vModelCheckbox, _ctx.selection]
                  ]),
                  _createElementVNode("label", {
                    for: `custom-checkbox-${i}`
                  }, _toDisplayString(option), 9, _hoisted_10)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(option.label), 1))
        ], 10, _hoisted_4))
      }), 128))
    ], 2)
  ], 40, _hoisted_1))
}