import { APIEntry, APIPostEntry } from '@/core/managers/entry/entryApi'
import { IEntry, IRobRow } from '@/core/managers/entry/entryModel'
import { timeFormat } from 'd3'

export interface IEntryMapper{
  getDecoupling: (input:APIEntry)=> IEntry
  postDecoupling: (type:string, input:IEntry)=>APIPostEntry
}

export const CreateEntryMapper = function (): IEntryMapper {
  return {

    getDecoupling: function (input:APIEntry) {
      const minStart = input.min_start_date ? new Date(input.min_start_date.slice(0, 19)) : null
      const maxEnd = input.max_end_date ? new Date(input.max_end_date.slice(0, 19)) : null
      const start = input.start_date ? new Date(input.start_date.slice(0, 19)) : null
      const end = input.end_date ? new Date(input.end_date.slice(0, 19)) : null

      const formatTime = timeFormat('%H:%M')

      return {
        id: input.id,
        status: input.status,
        isOff: input.status.toLowerCase().indexOf('off') > -1,
        isOn: input.status.toLowerCase().indexOf('on') > -1,
        isMissing: input.status.toLowerCase().indexOf('missing') > -1,
        canEditStartDate: input.can_edit_start_date,
        canEditEndDate: input.can_edit_end_date,
        minCalendarTime: minStart || null,
        maxCalendarTime: maxEnd || null,
        startDate: start || null,
        endDate: end || null,
        startTime: start ? formatTime(start) : '00:00',
        endTime: end ? formatTime(end) : '00:00',
        dailyRate: input.daily_rate,
        reasonId: input.reason_field.selected_id,
        projectId: input.project_field.selected_id || -2,
        clientId: input.client_field.selected_id || -2,
        projectName: input.project_field.values.find(x => x.id === input.project_field.selected_id)?.label,
        clientName: input.client_field.values.find(x => x.id === input.client_field.selected_id)?.label,
        reasonName: input.reason_field.values.find(x => x.id === input.reason_field.selected_id)?.label,
        reasonList: input.reason_field.values.reduce((list:{id: number, name:string, statusFilter: string}[], row) => {
          list.push(
            {
              id: row.id,
              name: row.label,
              statusFilter: row.on_status || 'on off'
            }
          )
          return list
        }, []),
        clientList: input.client_field.values.length > 0
          ? input.client_field.values.reduce((list:{id: number, name:string}[], row) => {
            if (!list.find(x => x.id === -2)) {
              list.push({ id: -2, name: 'None' })
            }
            list.push({ id: row.id, name: row.label })
            return list
          }, [])
          : [{ id: -2, name: 'None' }],
        projectList: input.project_field.values.length > 0
          ? input.project_field.values.reduce((list:{id: number, name:string}[], row) => {
            if (!list.find(x => x.id === -2)) {
              list.push({ id: -2, name: 'None' })
            }
            list.push({ id: row.id, name: row.label })
            return list
          }, [])
          : [{ id: -2, name: 'None' }],
        robList: input.rob.reduce((list:IRobRow[], row) => {
          list.push({ productId: row.product_id, product: row.product_name, unit: row.unit_label, unitId: row.unit_id, quantity: row.quantity } as IRobRow)
          return list
        }, []),
        description: input.comment
      }as IEntry
    },
    postDecoupling: function (type:string, input:IEntry):APIPostEntry {
      const _setDateTime = function (origin : Date | null, time :string): string {
        const month = (origin as Date).getMonth() + 1
        const date = (origin as Date).getDate()
        if (origin) {
          return `${origin.getFullYear()}-${month < 10 ? ('0' + month) : month}-${date < 10 ? ('0' + date) : date}T${time.slice(0, 2)}:${time.slice(3, 5)}:00.000Z`
        } else {
          return ''
        }
      }
      return {
        start_date: _setDateTime(input.startDate, input.startTime),
        end_date: _setDateTime(input.endDate, input.endTime),
        status: input.isOn ? 'ON' : 'OFF',
        daily_rate: input.isOn ? input.dailyRate : null,
        reason_id: input.reasonId as number,
        client_field_id: input.clientId === -2 ? null : input.clientId,
        project_field_id: input.projectId === -2 ? null : input.projectId,
        rob: input.robList.reduce((list: { product_id: number, quantity: number, unit_id: number }[], row) => {
          list.push({ product_id: row.productId, quantity: parseInt(row.quantity.toString()), unit_id: row.unitId })
          return list
        }, []),
        comment: input.description,
        attachments: type === 'post' ? [] : { unchanged: [], new: [] }
      }
    }
  }
}
