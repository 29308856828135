
import { defineComponent, inject } from 'vue'
import { ContextManager } from '@/core/managers/context.manager'
import { useToast } from 'vue-toastification'
import CustomMultiSelect from '@/components/dropdowns/CustomMultiSelect.vue'

export default defineComponent({
  name: 'DepartmentSelect',
  components: {
    CustomMultiSelect
  },
  setup () {
    const toast = useToast()
    return {
      toast,
      contextManager: inject(ContextManager.name) as ContextManager
    }
  },
  data () {
    return {
      sectors: [] as string[],
      modelValue: [] as string[],
      loading: true,
      error: ''
    }
  },
  created () {
    this.contextManager.activitySectorsList().then((response) => {
      this.loading = false
      if (!response.isError && response.data) {
        this.sectors = response.data
        this.contextManager.currentActivitySectors().then((res) => {
          this.modelValue = res.data as string[]
          this.$emit('changeSectors', this.modelValue)
        })
      } else {
        this.error = this.$t('allReports.error', {
          error: response.Message
        })
        this.toast.error(this.error)
      }
    })
  },

  methods: {
    departmentSelection ($event: string[]) {
      this.modelValue = $event
      if (this.modelValue.length) {
        this.contextManager.declareActivitySectors($event)
        this.$emit('changeSectors', this.modelValue)
      }
    },
    rotateArrow () {
      const arrowContainer = document.querySelectorAll(
        '.vue-treeselect__control-arrow-container'
      )
      arrowContainer[1].classList.toggle('rotate-x-180')
    }
  }
})
