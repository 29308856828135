
import ServicesCardKpi from '@/components/cards/ServicesCardKpi.vue'
import previewHandling, {
  previewHandlingProps
} from '@/core/composables/preview.composable'
import { ChartModel } from '@/core/model/chart.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import { Preview } from '@/core/model/preview.model'
import ColoursService from '@/core/services/colours.service'
import Tools from '@/core/services/tools'
import { defineComponent, PropType, ref } from 'vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'

interface KpiListItem {
  value: number;
  label: string;
  unit: string;
  title?: string;
  type?: string;
  description?: string;
}

export default defineComponent({
  name: 'ServicesCardKpiShell',
  props: {
    ...previewHandlingProps,
    id: Number,
    title: String,
    type: String,
    kpis: Array as PropType<Preview[]>
  },

  setup (props) {
    const prop = {
      id: props.id,
      title: props.title,
      linkUrl: props.linkUrl,
      graphType: props.graphType,
      previewType: props.previewType,
      previewInfos: props.previewInfos,
      size: props.size,
      description: props.description,
      isPreview: props.isPreview,
      kpiTitle: props.kpiTitle
    }
    const preview = previewHandling(
      prop,
      '',
      '',
      {} as ChartModel,
      true,
      {} as KpiModel,
      true,
      [] as KpiExtended[]
    )
    const loading = ref(preview.loadingRef)
    const error = ref(preview.errorRef)
    const detailed = ref(preview.detailedRef)
    const chartOptions = preview.chartOptions
    const kpiDetails = ref(preview.kpiDetailsRef)
    const kpiList = ref(preview.kpiListRef)

    return {
      loading,
      preview,
      error,
      kpiDetails,
      detailed,
      chartOptions,
      kpiList
    }
  },
  components: {
    ServicesCardKpi,
    RightArrowIcon
  },
  methods: {
    getKpiColour (label: string) {
      return ColoursService.getColour(label)
    },
    changeLoaderState () {
      this.loading = false
    },
    displayArrowWrapper () {
      const category = this.$refs.category as HTMLElement
      const arrows = this.$refs.arrows as HTMLElement
      if (arrows && category) {
        if (
          category.scrollWidth <=
          (this.$refs.categoryWrapper as HTMLElement).offsetWidth
        ) {
          arrows.classList.remove('hidden', 'md:block')
          arrows.classList.add('hidden', 'md:hidden')
        } else {
          arrows.classList.remove('hidden', 'md:hidden')
          arrows.classList.add('hidden', 'md:block')
        }
      }
    },
    scrollCategory (direction: 'right' | 'left') {
      const category = this.$refs.category as HTMLElement
      if (category) {
        if (direction === 'left') {
          category.scrollLeft += 100
        } else {
          category.scrollLeft -= 100
        }
      }
    },

    showOrHideArrows () {
      const category = this.$refs.category as HTMLElement
      const arrowLeft = this.$refs.arrowLeft as HTMLElement
      const arrowRight = this.$refs.arrowRight as HTMLElement
      if (category && arrowLeft && arrowRight) {
        if (category.scrollLeft > 0) {
          arrowRight.classList.remove('hidden')
          arrowRight.classList.add('flex')
        } else {
          arrowRight.classList.remove('flex')
          arrowRight.classList.add('hidden')
        }
        if (
          category.scrollWidth - category.offsetWidth <=
          category.scrollLeft
        ) {
          arrowLeft.classList.remove('flex')
          arrowLeft.classList.add('hidden')
        } else {
          arrowLeft.classList.remove('hidden')
          arrowLeft.classList.add('flex')
        }
      }
    }
  },
  computed: {
    kpiColour (): string {
      return ColoursService.getColour(this.title as string)
    },
    details (): KpiModel {
      return this.kpiDetails
    },
    filters () {
      return this.$store.state.filters
    },
    list () {
      const list: KpiListItem[][] = []
      for (const items of this.kpiList) {
        if (!list.includes(items.kpis)) {
          if (items.type !== 'MULTI') {
            for (const kpi of items.kpis as unknown as KpiExtended[]) {
              kpi.title = items.title
              kpi.type = items.type
              kpi.description = items.description
            }
          }
          list.push(items.kpis)
        }
      }

      const flattenedList = Tools.flattenArray(list)
      if (Object.keys(this.kpiList).length > 0) {
        this.changeLoaderState()
      }
      const kpiKeys:string[] = []
      if (this.kpis === undefined) throw new Error('No kpis to process.')
      for (const kpi of this.kpis) {
        kpiKeys.push(kpi.label)
      }

      const sortArray = flattenedList.sort((a, b) => {
        return kpiKeys.indexOf(a.label) - kpiKeys.indexOf(b.label)
      })

      return sortArray
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler (currentValue, oldValue) {
        if (oldValue && JSON.stringify(oldValue) !== JSON.stringify(currentValue) && Object.keys(currentValue).length) {
          this.preview.handleDetailsKpi(this.kpis as Preview[], currentValue)
        }
      }
    },
    list: {
      immediate: true,
      deep: true,
      handler () {
        if (this.list.length) {
          this.$nextTick(() => {
            this.displayArrowWrapper()
          })
        }
      }
    }
  }
})
