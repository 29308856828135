import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block cursor-pointer my-2" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex flex-col ml-2" }
const _hoisted_4 = { class: "text-md text-white text-ellipsis overflow-hidden w-60 inline-block whitespace-nowrap" }
const _hoisted_5 = { class: "text-xs text-slate-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = event => _ctx.$emit('update', { code : _ctx.asset.assetCode, value : !event.target.checked})),
      style: {"display":"none"}
    }, null, 32),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("i", {
        class: _normalizeClass(["fa-lg", {' fa-solid text-custom-blue fa-square-check' : _ctx.asset.checked, 'fa-regular text-slate-400 fa-square' : !_ctx.asset.checked}])
      }, null, 2),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.asset.assetName), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.asset.assetTypeName), 1)
      ])
    ])
  ]))
}