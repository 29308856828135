import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-text-alt pb-2 px-4 cursor-pointer fill-text-alt justify-center select-none" }
const _hoisted_2 = { class: "hidden xs:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "mr-2 w-5 h-5" })),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}