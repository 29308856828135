import { reactive } from 'vue'
import { ConcessionModel } from '@/map/core/bll/concession/concession.model'
import concessionManager from '@/map/core/bll/concession/concession.manager'

export const state = reactive({ list: null as ConcessionModel[] | null, toggle: false, loading: false, error: '', initialized: false })

const mutation = {
  fetch_success: (value : ConcessionModel[]) => {
    state.list = value
    state.initialized = true
  },
  loading: (value : boolean) => {
    state.loading = value
  },
  error: (value : string) => {
    state.error = value
  },
  toggle: (value : boolean) => {
    state.toggle = value
  }
}

const effect = {
  fetch: function () {
    mutation.error('')
    mutation.loading(true)
    concessionManager.fetch().then(result => {
      mutation.fetch_success(result)
    }).catch(err => {
      mutation.error(err)
    }).finally(() => {
      mutation.loading(false)
    })
  }
}

const action = {
  fetch: function (): void {
    effect.fetch()
  },
  toggle: function (value: boolean): void {
    mutation.toggle(value)
  }
}

export default {
  state,
  action
}
