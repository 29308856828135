import { Map } from 'leaflet'
import { onUnmounted } from 'vue'
import categoryStore from '@/map/core/bll/category/category.store'
import { groupAssetLayer } from '@/map/core/bll/category/category.group'

export default {

  mounted: function (map: Map): void {
    map.addLayer(groupAssetLayer)

    if (!categoryStore.state.initialized) {
      categoryStore.action.fetch()
    }

    onUnmounted(() => {
      groupAssetLayer.remove()
    })
  }

}
