import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IPlanningFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { ApiContractFilter } from '@/core/managers/planningFilter/planningFilterApi'
import { IPlanningFilterMapper } from '@/core/managers/planningFilter/planningFilterMapper'

export interface IPlanningFilterManager {
  fetch: (signal : AbortSignal)=> Promise<IPlanningFilter>
  init : IPlanningFilter
}

export const CreatePlanningFilterManager = function (httpProvider :IHttpProvider, periodFilterMapper: IPlanningFilterMapper): IPlanningFilterManager {
  return {
    init: {
      contracts: [],
      assets: []
    },
    fetch: async function (signal : AbortSignal) {
      const _result = await httpProvider.get<ApiContractFilter[]>(process.env.VUE_APP_URL + 'planning/contracts', signal)
      return periodFilterMapper.fetchDecoupling(_result)
    }
  }
}
