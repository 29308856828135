
import timelineStyle from '@/core/composables/timeline.composable'
import {
  TimelineModalContent,
  TimelineModel
} from '@/core/model/timeline.model'
import FormatService from '@/core/services/format.service'
import TimeLineService, {
  EventsMapped,
  EventsMappedForJourney
} from '@/core/services/timeline.service'
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'

export default defineComponent({
  name: 'FleetAssetTimelineDetail',
  props: {
    timeline: { type: Array as PropType<TimelineModel[]>, required: true },
    openModal: { type: Boolean, required: true, default: false },
    modalContent: {
      type: Object as PropType<TimelineModalContent>,
      required: true
    }
  },
  setup (props) {
    const timelineMethods = timelineStyle(props)
    const operationBackground = timelineMethods.operationBackground
    const operationBorder = timelineMethods.operationBorder
    const getHours = timelineMethods.getHours
    const showDetail = timelineMethods.showDetail

    const events: Ref<EventsMapped[][]> = ref([])
    watch(
      () => props.timeline,
      () => {
        events.value = []
        handleTimeline()
      })

    const handleTimeline = () => {
      for (const [index, day] of props.timeline.entries()) {
        day.timeSheetContexts = day.timeSheetContexts.reverse()
        events.value.push([])
        for (const action of day.timeSheetContexts) {
          action.activities = action.activities.reverse()
          const activities: EventsMapped[] = action.activities.map((activity) => {
            return {
              ...activity,
              location: action.location,
              context: action.context
            }
          })
          for (const activity of activities) {
            events.value[index].push(activity)
          }
        }
      }
    }

    const intervals = computed(() => {
      const intervals = []
      if (events.value && events.value.length) {
        for (const day of events.value) {
          intervals.push(
          TimeLineService.partitionIntoOverlappingRanges(
            day,
            true
          ) as EventsMappedForJourney[]
          )
        }
      }
      return intervals
    })

    const displayLocation = (i: number, j: number) => {
      return (
        (intervals.value[i - 1] &&
          intervals.value[i - 1][intervals.value[i - 1].length - 1].location !==
            intervals.value[i][0].location) ||
        (intervals.value[i][j - 1] &&
          intervals.value[i][j - 1].location !==
            intervals.value[i][j].location) ||
        (i === 0 && j === 0)
      )
    }

    const displayContext = (i: number, j: number) => {
      return (
        (intervals.value[i - 1] &&
          intervals.value[i - 1][intervals.value[i - 1].length - 1].context !==
            intervals.value[i][0].context) ||
        (intervals.value[i][j - 1] &&
          intervals.value[i][j - 1].context !== intervals.value[i][j].context &&
          intervals.value[i][j - 1].location ===
            intervals.value[i][j].location) ||
        (intervals.value[i][j - 1] &&
          intervals.value[i][j - 1].context === intervals.value[i][j].context &&
          intervals.value[i][j - 1].location !==
            intervals.value[i][j].location) ||
        (intervals.value[i][j - 1] &&
          intervals.value[i][j - 1].context !== intervals.value[i][j].context &&
          intervals.value[i][j - 1].location !==
            intervals.value[i][j].location) ||
        (i === 0 && j === 0)
      )
    }

    const getHeight = (i: number, j: number, k: number) => {
      const interval = intervals.value[i][j].events
      let intervalHeight = 1
      if (interval.length > 1) {
        if (
          interval[k + 1] &&
          interval[k + 1].startTime >= interval[k].startTime
        ) {
          intervalHeight += 1
        }
        if (interval[k - 1] && interval[k - 1].endTime <= interval[k].endTime) {
          intervalHeight += 1
        }
      }
      return intervalHeight
    }

    const topColour = () => {
      return '#A9B8DB'
    }

    const displayBorderBottom = (i: number, j: number, k: number) => {
      if (intervals.value[i][j].events[k - 1] && intervals.value[i][j].events[k + 1] && intervals.value[i][j].events[k].startTime > intervals.value[i][j].events[k - 1].startTime) {
        return true
      }
      if (intervals.value[i][j].events[k - 1] && intervals.value[i][j].events[k - 1].endTime === intervals.value[i][j].events[k].endTime) {
        return false
      }
      if (intervals.value[i][j].events[k + 1] && intervals.value[i][j].events[k].endTime > intervals.value[i][j].events[k + 1].endTime && intervals.value[i][j].events[k].startTime > intervals.value[i][j].events[k + 1].startTime) {
        return true
      }
      if (
        j === intervals.value[i].length - 1 &&
        intervals.value[i][j].events.length
      ) {
        const endTimesOfInterval = []
        if (intervals.value[i][j].events.length > 1) {
          for (const op of intervals.value[i][j].events) {
            endTimesOfInterval.push(op.endTime)
          }
          endTimesOfInterval.sort((a, b) => {
            if (a > b) return 1
            if (a < b) return -1
            return 0
          })
        } else {
          endTimesOfInterval.push(intervals.value[i][j].events[0].endTime)
        }
        for (const op of intervals.value[i][j].events) {
          if (
            intervals.value[i + 1] &&
            intervals.value[i + 1][0].context === op.context &&
            intervals.value[i + 1][0].location === op.location &&
            intervals.value[i + 1][0].events[0].name === op.name &&
            intervals.value[i][j].events[k].endTime ===
              endTimesOfInterval[endTimesOfInterval.length - 1]
          ) {
            return false
          }
        }
        return true
      }
      return (
        (intervals.value[i][j].events[k + 1] &&
          (intervals.value[i][j].events[k] as EventsMapped).endTime !==
            (intervals.value[i][j].events[k + 1] as EventsMapped).startTime) ||
        (intervals.value[i][j].events[k - 1] &&
          (intervals.value[i][j].events[k] as EventsMapped).endTime <
            (intervals.value[i][j].events[k - 1] as EventsMapped).endTime) ||
        (intervals.value[i][j + 1] &&
          intervals.value[i][j + 1].context !==
            intervals.value[i][j].context) ||
        (intervals.value[i][j + 1] &&
          intervals.value[i][j + 1].location !== intervals.value[i][j].location) ||
          (intervals.value[i][j].events[0].endTime > intervals.value[i][j].events[k].endTime)
      )
    }

    const displayBorderTop = (i: number, j: number, k:number) => {
      if (intervals.value[i][j].events[k - 1] && intervals.value[i][j].events[k - 1].startTime === intervals.value[i][j].events[k].startTime) {
        return false
      }

      if (k === 0 && j === 0 && i > 0) {
        for (const previousOperation of intervals.value[i - 1][
          intervals.value[i - 1].length - 1
        ].events) {
          for (const operationOfInterval of intervals.value[i][0].events) {
            if (
              operationOfInterval.name === previousOperation.name &&
              operationOfInterval.context === previousOperation.context &&
              operationOfInterval.location === previousOperation.location
            ) {
              return false
            }
          }
        }
      }
      return true
    }

    const formatDate = (date: string) => FormatService.formatDate(date)

    onMounted(() => handleTimeline())

    return {
      operationBackground,
      operationBorder,
      getHours,
      showDetail,
      intervals,
      displayLocation,
      displayContext,
      getHeight,
      topColour,
      displayBorderBottom,
      displayBorderTop,
      events,
      formatDate
    }
  }
})
