export const KeyboardDirective = {

  // ul is ul
  mounted (ul:HTMLElement):void {
    let indexColor = 0
    let translateHeight = 0

    const onKeyDown = (e:KeyboardEvent) => {
      ul.scrollTop = 0
      if ((e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
        const list = ul.querySelectorAll('li')
        const ulHeight = ul.getBoundingClientRect().height
        const liHeight = list[0].getBoundingClientRect().height
        const liLength = list.length

        if (e.key === 'ArrowDown') {
          if (indexColor > 0) {
            list[indexColor - 1].classList.remove('selectedAsset')
          }
          list[indexColor].classList.add('selectedAsset')
          if (indexColor < liLength - 1) {
            indexColor++
          }
          if (indexColor * liHeight > ulHeight && translateHeight < liHeight * liLength - ulHeight) {
            translateHeight += liHeight
            list.forEach(ul => {
              ul.style.transform = 'translateY(' + (translateHeight) * -1 + 'px)'
            })
          }
        }

        if (e.key === 'ArrowUp') {
          if (translateHeight > 0) {
            translateHeight -= liHeight
            list.forEach(ul => {
              ul.style.transform = 'translateY(' + translateHeight * -1 + 'px)'
            })
          }

          list[indexColor].classList.remove('selectedAsset')
          if (indexColor > 0) {
            list[indexColor - 1].classList.add('selectedAsset')
            indexColor--
          }
        }
      } else {
        const temp = document.querySelector('.selectedAsset') as HTMLElement
        if (temp) {
          temp.classList.remove('selectedAsset')
        }

        indexColor = 0
        translateHeight = 0
        ul.style.transform = 'translateY(0px)'
      }
    }
    window.addEventListener('keydown', onKeyDown)
  }
}
