import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-267e6df6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", {
    id: "footer",
    class: "flex justify-center py-2",
    onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('mouseleave'))),
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mouseover')))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.count, (n) => {
      return (_openBlock(), _createElementBlock("button", {
        key: n-1,
        onClick: ($event: any) => (_ctx.$emit('current', n-1)),
        class: _normalizeClass(["btn-square", {active : n-1 === _ctx.current}])
      }, null, 10, _hoisted_1))
    }), 128))
  ], 32))
}