import { GetZoneModel, ZoneModel } from '@/map/core/bll/zone/zone.model'
import { latLng, LatLng } from 'leaflet'

export function zoneMapper (zone : GetZoneModel): ZoneModel {
  return {
    code: zone.areaCode,
    name: zone.areaName,
    type: zone.areaType,
    geoJson: zone.geoJson,
    checked: true,
    coordinates: JSON.parse(zone.geoJson).coordinates.reduce((list:LatLng[], items : [number, number][]) => {
      items.forEach(item => {
        list.push(latLng([item[1], item[0]]))
      })
      return list
    }, [])
  }
}
