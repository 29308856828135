
import previewHandling, {
  previewHandlingProps
} from '@/core/composables/preview.composable'
import { ChartModel } from '@/core/model/chart.model'
import { KpiExtended, KpiModel } from '@/core/model/kpi.model'
import { Chart } from 'highcharts-vue'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ServicesCardFleet.vue',
  components: {
    Highcharts: Chart
  },
  props: previewHandlingProps,
  setup (props) {
    const prop = {
      id: props.id,
      title: props.title,
      linkUrl: props.linkUrl,
      graphType: props.graphType,
      previewType: props.previewType,
      previewInfos: props.previewInfos,
      size: props.size,
      description: props.description,
      isPreview: props.isPreview,
      kpiTitle: props.kpiTitle
    }
    const preview = previewHandling(
      prop,
      '',
      '',
      {} as ChartModel,
      true,
      {} as KpiModel,
      false,
      [] as KpiExtended[],
      true
    )
    const loading = ref(preview.loadingRef)
    const customDetails = ref(preview.customDetailsRef)
    const error = ref(preview.errorRef)
    const kpiDetails = ref(preview.kpiDetailsRef)
    const detailed = ref(preview.detailedRef)
    const kpiList = ref(preview.kpiListRef)
    const chartOptions = preview.chartOptions
    const mounted = onMounted(() => {
      detailed.value = false
      preview.displayPreview()
    })

    return {
      loading,
      customDetails,
      mounted,
      error,
      kpiDetails,
      detailed,
      kpiList,
      chartOptions
    }
  }
})
