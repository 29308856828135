

import { defineComponent } from 'vue'
import { IdNameModel, Report2Manager, ReportSubscriptionRowModel } from '@/map/core/bll/report/reportManager'
import assetRow from '@/views/popover/assetRowPopover.vue'
import attributeRow from '@/views/popover/attributeRowPopover.vue'

export default defineComponent({
  components: {
    assetRow,
    attributeRow
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler (value: string) {
        if (value) {
          const promises = []
          promises.push(Report2Manager.getSubscription(parseInt(value)))
          promises.push(Report2Manager.getAttributeAll())
          Promise.all(promises).then(results => {
            this.items = results[0] as ReportSubscriptionRowModel[]
            this.labels = results[1] as IdNameModel[]
            this.loading = false
          })
        }
      }
    }
  },
  data () {
    return { loading: true, items: [] as ReportSubscriptionRowModel[], labels: [] as IdNameModel[] }
  },
  methods: {
    getLabel (labels: IdNameModel[], id : number) {
      return labels.find(item => item.id === id) || { id: -1, name: 'not found' }
    }
  }
})

