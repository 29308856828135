

import { defineComponent } from 'vue'
import SearchBarPopover from '@/map/views/components/drawers/right/search/asset.typeAhead.vue'
import AdvancedFilterPopover from '@/map/views/components/drawers/right/category/category.panel.vue'
import MapLayersPopover from '@/map/views/components/drawers/right/switch/switchLayer.panel.vue'
import ZonePopover from '@/map/views/components/drawers/right/zone/zone.panel.vue'

export default defineComponent({
  components: {
    SearchBarPopover,
    MapLayersPopover,
    AdvancedFilterPopover,
    ZonePopover
  }
})
