
import { defineComponent, PropType } from 'vue'
import { ZoneModel } from '@/map/core/bll/zone/zone.model'

export default defineComponent({
  props: {
    zone: {
      type: Object as PropType<ZoneModel>,
      required: true
    }
  }
})
