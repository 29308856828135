import { reactive } from 'vue'

export interface IAppStore {
  action: {
    blockers : {
      increment : () => void
      decrement: ()=> void
    }
    toaster : {
      show : (msg: string, color: string)=> void
      hide : ()=> void
    },
    dialog : {
      show : (msgList: string[])=> void
      hide : ()=> void
    }
  },
  state : {
    blockers: number
    toaster : {
      isVisible: boolean
      msg: string
      color: string
    },
    dialog : {
      isVisible: boolean
      msgList: string[]
    }
  }
}

export const CreateAppStore = function (): IAppStore {
  let timeoutId: ReturnType<typeof setTimeout>
  const state = reactive({ blockers: 0, toaster: { isVisible: false, msg: 'error', color: '' }, dialog: { msgList: [] as string[], isVisible: false } })
  const mutation = {
    show: (list : string[]) => {
      state.dialog = {
        msgList: list,
        isVisible: true
      }
    },
    hide: () => {
      state.dialog = {
        msgList: [],
        isVisible: false
      }
    },
    increment: () => {
      state.blockers++
    },
    decrement: () => {
      state.blockers--
    },
    showToaster: (msg: string, color: string) => {
      state.toaster.isVisible = true
      state.toaster.msg = msg
      state.toaster.color = color
    },
    hideToaster: () => {
      state.toaster.isVisible = false
      state.toaster.msg = ''
      state.toaster.color = ''
    }
  }
  return {
    action: {
      dialog: {
        show: function (msgList: string[]) {
          mutation.show(msgList)
        },
        hide: function () {
          mutation.hide()
        }
      },
      toaster: {
        show: function (msg: string, color: string): void {
          mutation.showToaster(msg, color)
          clearTimeout(timeoutId)
          timeoutId = setTimeout(function () {
            mutation.hideToaster()
          }, 2000)
        },
        hide: function ():void {
          mutation.hideToaster()
        }
      },
      blockers: {
        increment: function (): void {
          mutation.increment()
        },
        decrement: function (): void {
          mutation.decrement()
        }
      }
    },
    state
  }
}
