import colors from 'vuetify/util/colors'

export const myCustomLightTheme = {
    dark: false,
    colors: {
        primary: colors.cyan.base,
        'on-primary':  colors.cyan.lighten5,
        secondary: colors.cyan.darken4,
        'surface-light': '#E0F7FA',
        'on-surface-light': '#0097A7'
    },
    variables: {
        'disabled-opacity': 0.6,
        'btn-size': '1rem',
        'icon-size-multiplier': 0.7
    }
}
