import { IAssetFilter } from '@/core/managers/planningFilter/planningFilterModel'

export interface IPlanningFilterHelper{
  findAssetById : (list : IAssetFilter[], assetId : number)=>IAssetFilter
}
export const CreatePlanningFilterHelper = function (): IPlanningFilterHelper {
  return {
    findAssetById: function (list : IAssetFilter[], assetId : number):IAssetFilter {
      return list.find(x => x.id === assetId) || {
        id: -1,
        name: 'not found',
        contractId: -1,
        contractName: '',
        status: '',
        status_color: '',
        image_url: ''
      } as IAssetFilter
    }
  }
}
