import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block cursor-pointer mt-2" }
const _hoisted_2 = ["data-code"]
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-ellipsis text-md overflow-hidden w-64 inline-block whitespace-nowrap ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = event => _ctx.$emit('update', { code : _ctx.zone.code, value : !event.target.checked})),
      "data-code": `${_ctx.zone.code}`,
      style: {"display":"none"}
    }, null, 40, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _createElementVNode("i", {
        class: _normalizeClass(["fa-lg", {' fa-solid text-custom-blue fa-square-check' : _ctx.zone.checked, 'fa-regular text-slate-400 fa-square' : !_ctx.zone.checked}])
      }, null, 2),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.zone.name), 1)
    ])
  ]))
}