import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowForward = _resolveComponent("ArrowForward")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["text-white inline-block rounded-md", { 'bg-bg-0 px-4 py-2.5 absolute bottom-2.5 left-6': _ctx.home }])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.btnText), 1),
    _createVNode(_component_ArrowForward, { class: "h-6 w-6 inline-block ml-2.5" })
  ], 2))
}