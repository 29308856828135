<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 96">
    <path
      class="st-0"
      d="M0 2v92c0 1.1.9 2 2 2h68c1.1 0 2-.9 2-2V28H50c-1.1 0-2-.9-2-2V0H2C.9 0 0 .9 0 2z"
    />
    <path class="st-0" d="M71.9 24c-.1-.4-.2-.7-.4-1L52 1.2V24h19.9z" />
    <path
      d="M6 41h60v49H6zm.6-34.6h4.2c3.7 0 5.9 2.6 5.9 7.1s-2.2 7.2-5.9 7.2H6.6V6.4zm4.1 11.4c1.7 0 2.6-1.5 2.6-4.3s-1-4.2-2.6-4.2h-.8v8.5h.8zM24.1 6.1c3.8 0 5.9 3.1 5.9 7.3 0 4.2-2.1 7.5-5.9 7.5s-5.8-3.2-5.8-7.5c0-4.1 2.1-7.3 5.8-7.3zm0 11.7c1.7 0 2.5-1.8 2.5-4.3s-.8-4.2-2.5-4.2-2.5 1.7-2.5 4.2.8 4.3 2.5 4.3zM38 6.1c1.3 0 2.6.4 3.6 1.2l-1.4 2.8c-.4-.5-1.3-.9-2.1-.9-2.1 0-3.2 1.9-3.2 4.2 0 2.3 1.2 4.4 3.2 4.4 1 0 1.7-.5 2.3-1l1.4 2.8c-.9.7-2 1.3-3.9 1.3-4 0-6.3-3.1-6.3-7.4-.1-4.2 2.3-7.4 6.4-7.4z"
      fill="#fff"
    />
    <path
      class="st0"
      d="M19.5 67.1l-4.8-14.8c-.2-.6-.3-.8-.4-.8-.1-.1-.2-.1-.3-.2l-1.7-.5-.1-2.1h9l.2 2.1-1.7.5v.2c0 .2.1.6.3 1l1.8 5.7 3.2-9.5 2.4-.1 2.9 9.5 2-5.7c.1-.4.3-.8.3-1v-.1l-1.5-.4-.1-2.2h7l.2 2.1-1.9.6c-.1 0-.2.1-.3.1 0 .1-.2.2-.3.7l-5.3 14.7-2.5.2-2.7-9.1-3.2 8.9-2.5.2z"
    />
    <path
      d="M42.4 48.6H60v2.8H42.4zm0 7.4H60v2.8H42.4zm0 7.7H60v2.8H42.4zM13 71.5h47v2.8H13zm0 7.6h47v2.8H13z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width=".5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
