
import FilesIcon from '@/components/icons/FilesIcon.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    FilesIcon,
    CrossIcon
  },
  props: {
    title: { type: String, required: true },
    ClientDepartment: { type: String, required: true }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
})
