import { ILoginService } from '@/core/services/authentication/ILogin.service'
import { LoginService } from '@/core/services/authentication/login.service'
import { AuthenticationSettings } from '@/core/model/settings.model'
import axios from 'axios'

class LoginServiceProvider {
  private _instance : LoginService | null
  // private _authSettings : AuthenticationSettings|null|undefined
  constructor () {
    this._instance = null
  }

  private async _getMetadata (url: string) : Promise<string> {
    const metadataResponce = await axios.get<string>(url)
    const metadata = JSON.stringify(metadataResponce.data)
    return metadata
  }

  private async _createInstance (authSettings: AuthenticationSettings) : Promise<void> {
    if (authSettings === null || authSettings === undefined) {
      return
    }
    let metadata: string|undefined
    if (authSettings.metadataAddress !== undefined) {
      metadata = await this._getMetadata(authSettings.metadataAddress)
    }
    this._instance = new LoginService(authSettings, metadata)
  }

  async GetInstance (authSettings: AuthenticationSettings) : Promise<ILoginService> {
    if (this._instance == null) { await this._createInstance(authSettings) }
    return this._instance as LoginService
  }
}

export const loginServiceProvider = new LoginServiceProvider()
