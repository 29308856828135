import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  id: "eventDelegate",
  class: "h-100"
}
const _hoisted_2 = {
  key: 0,
  class: "h-100 d-flex justify-center align-center"
}
const _hoisted_3 = { class: "w-25 d-flex" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "text-no-wrap text-uppercase" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-center align-center",
  style: {"height":"350px"}
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_time_line_row_left = _resolveComponent("time-line-row-left")!
  const _component_time_line_row_right = _resolveComponent("time-line-row-right")!
  const _component_timeline_popover = _resolveComponent("timeline-popover")!
  const _component_v_card = _resolveComponent("v-card")!
  const _directive_scroll = _resolveDirective("scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.pageLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              "model-value": "20"
            })
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_select, {
                    items: _ctx.planningFilter.contracts,
                    "hide-details": true,
                    "hide-no-data": true,
                    "model-value": parseInt(_ctx.$route.params.contractId),
                    "onUpdate:modelValue": _ctx.contractChange,
                    class: "me-1"
                  }, null, 8, ["items", "model-value", "onUpdate:modelValue"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_v_btn, {
                      icon: "mdi-chevron-left",
                      variant: "text",
                      density: "comfortable",
                      class: "me-1",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onIncrementMonth(-1)))
                    }),
                    _createVNode(_component_v_select, {
                      items: _ctx.timelineHelper.months,
                      "hide-details": true,
                      "hide-no-data": true,
                      "model-value": this.$route.params.rangeTimeId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = x=> this.$router.push({params : { rangeTimeId : x}, query: { scroll: 'duration' }}) )
                    }, null, 8, ["items", "model-value"]),
                    _createVNode(_component_v_btn, {
                      icon: "mdi-chevron-right",
                      variant: "text",
                      density: "comfortable",
                      class: "ms-1",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onIncrementMonth(1)))
                    })
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_tabs, {
              cols: "10",
              offset: "2",
              class: "mb-4 px-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tab, {
                  value: 1,
                  class: "text-capitalize"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("On/Off Hire")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createBlock(_component_v_col, {
                  id: "headerScroll",
                  cols: "10",
                  offset: "2",
                  style: {"overflow-x":"auto"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "d-flex flex-column",
                      style: _normalizeStyle({ width : _ctx.timelineHelper.wrapperWidth +'px'})
                    }, [
                      _createElementVNode("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.timelineHelper.html.generateTH(), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            style: _normalizeStyle([
                {width : item.width+'px'},
                {borderLeft : item.isBorderLeft ? 'white dotted 1px': '' }
              ])
                          }, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1)
                          ], 4))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.timelineHelper.html.generateCol(), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "border-e border-white text-center",
                            key: index,
                            style: _normalizeStyle({width : item.width+'px'})
                          }, [
                            _createElementVNode("small", null, _toDisplayString(item.title), 1)
                          ], 4))
                        }), 128))
                      ])
                    ], 4)
                  ]),
                  _: 1
                })), [
                  [_directive_scroll, {cb: _ctx.onScroll  }]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "2",
                  class: "mb-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      modelValue: _ctx.filterText,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterText) = $event)),
                      "hide-details": true,
                      "prepend-inner-icon": "mdi-magnify"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.assetLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_v_progress_circular, {
                    indeterminate: "",
                    "model-value": "20"
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "2",
                        class: "py-0"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planningFilter.assets.filter(a => a.contractId == parseInt(_ctx.$route.params.contractId)), (item, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (_ctx.filterText === '' || item.name.toLowerCase().indexOf(_ctx.filterText.toLowerCase()) > -1)
                                ? (_openBlock(), _createBlock(_component_time_line_row_left, {
                                    key: 0,
                                    "asset-filter": item,
                                    "contract-id": parseInt(_ctx.$route.params.contractId)
                                  }, null, 8, ["asset-filter", "contract-id"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "10",
                        class: "py-0",
                        style: {"overflow-x":"hidden"}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            style: _normalizeStyle([{ width : _ctx.timelineHelper.wrapperWidth +'px'},{ transform : `translateX(${_ctx.scrollLeft*-1}px)`}])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planningFilter.assets.filter(a => a.contractId == parseInt(_ctx.$route.params.contractId)), (item, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                (_ctx.filterText === '' || item.name.toLowerCase().indexOf(_ctx.filterText.toLowerCase()) > -1)
                                  ? (_openBlock(), _createBlock(_component_time_line_row_right, {
                                      key: 0,
                                      asset_id: item.id,
                                      entries: _ctx.assetList.filter(_ => _.id == item.id).flatMap(_ => _.entries)
                                    }, null, 8, ["asset_id", "entries"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ], 4)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
          ], 64))
    ]),
    (_ctx.toggle)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#end-of-body"
        }, [
          _createElementVNode("div", {
            style: {"position":"fixed","inset":"0","background":"rgba(25, 35, 57,.5)","z-index":"9999","cursor":"pointer"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggle =false))
          }, [
            _createVNode(_component_v_card, {
              class: "border border-white",
              style: _normalizeStyle([{"position":"absolute","width":"300px","color":"white","height":"300px"}, _ctx.position])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_timeline_popover, {
                  "period-id": _ctx.periodId,
                  "range-time-id": _ctx.$route.params.rangeTimeId,
                  "asset-id": _ctx.assetId,
                  "contract-id": _ctx.$route.params.contractId
                }, null, 8, ["period-id", "range-time-id", "asset-id", "contract-id"])
              ]),
              _: 1
            }, 8, ["style"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}