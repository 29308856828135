import { Manager as SettingsManager } from '@/core/managers/settings.manager'
import { AuthenticationSettings, PublicSettings } from '@/core/model/settings.model'
import { ILoginService } from '@/core/services/authentication/ILogin.service'
import { FakeLoginService } from '@/core/services/authentication/fakelogin.service'
import { loginServiceProvider } from '@/core/services/authentication/login.service.provider'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import 'flag-icons/css/flag-icons.css'
import { createApp, reactive } from 'vue'
import { createI18n } from 'vue-i18n'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import App from './App.vue'
import { AddHttpInterceptor } from './core/axios.plugin'
import router from './router'
import store from './store'

import '@/assets/css/fontawesome.min.css'
import '@/assets/css/solid.min.css'
import '@/assets/css/regular.min.css'
import '@/assets/css/light.min.css'
import '@/assets/css/duotone.min.css'
import '../node_modules/leaflet/dist/leaflet.css'
import '@/assets/css/leaflet.scss'
import { myCustomDarkTheme } from '@/plugins/themeDark.mjs'
import { myCustomLightTheme } from '@/plugins/themeLight.mjs'
import { FocusDirective } from '@/map/views/directives/focus.directives'
import { AccordionDirective } from '@/map/views/directives/accordion.directive'
import { KeyboardDirective } from '@/map/views/directives/keyboard.directive'
import { StickyDirective } from '@/map/views/directives/sticky.directive'
import { LoggerService } from '@/core/services/loggers/logger.service'
import { ILogger } from '@/core/services/loggers/ilogger.service'
import { ContextManager } from '@/core/managers/context.manager'
import * as LabelsManager from '@/core/managers/labels.manager'
import * as UserManager from '@/core/managers/user.manager'
import { http } from '@/core/services/request.service'

import { CreateHttpProvider } from '@/core/provider/httpApiProvider'

import { CreateAppStore } from '@/core/appStore'
import { CreatePlanningFilterMapper } from '@/core/managers/planningFilter/planningFilterMapper'
import { CreatePlanningFilterManager } from '@/core/managers/planningFilter/planningFilterManager'
import { CreateEntriesForMonthMapper } from '@/core/managers/entriesForMonth/entriesForMonthMapper'
import { CreateEntriesForMonthManager } from '@/core/managers/entriesForMonth/entriesForMonthManager'
import { CreateEntryMapper } from '@/core/managers/entry/entryMapper'
import { CreateEntryManager } from '@/core/managers/entry/entryManager'
import { CreatePlanningFilterHelper } from '@/core/managers/planningFilter/planningFilterHelper'
import { CreateAppRouter } from '@/core/appRouter'
import { CreateTimelineHelper } from '@/core/managers/timeLineHelper'
import { CreateEntriesForAssetMapper } from '@/core/managers/entriesForAsset/entriesForAssetMapper'
import { CreateEntriesForAssetManager } from '@/core/managers/entriesForAsset/entriesForAssetManager'

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: 'myCustomDarkTheme',
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 2
    },
    themes: {
      myCustomLightTheme,
      myCustomDarkTheme
    }
  },
  components: {
    ...components,
    VDateInput,
    VTimePicker,
    VNumberInput
  },
  defaults: {
    VCard: { color: 'danger' },
    VTabs: { color: 'primary' },
    VBtn: { flat: true, style: 'font-weight:normal;letter-spacing:0' },
    VSelect: { variant: 'outlined', density: 'compact', bgColor: 'surface-light', color: 'primary', itemValue: 'id', itemTitle: 'name', menuIcon: 'mdi-chevron-down', transition: 'scale-transition' },
    VTextField: { variant: 'outlined', density: 'compact', bgColor: 'surface-light', color: 'primary' }
  }
})

/*  */

export interface GStoreModel
{
  mapLoading : boolean
  mapError : string,
  msgSuccess: string
  msgError: string
}

export const GlobalStore = reactive({ mapError: '', mapLoading: false, msgSuccess: '', msgError: '' } as GStoreModel)
const sessionId = (crypto as any).randomUUID()

const toastOptions: PluginOptions = { position: POSITION.BOTTOM_RIGHT, timeout: 2000, hideProgressBar: true }
const messages = {
  en: require('./locale/en.json')
}
const i18n = createI18n({
  messages,
  legacy: false
})

const app = createApp(App)

RetrievePublicSettings()
  .then(async (settings) => {
    if (settings.authSetting === null) throw new Error('No Authentication config')
    const loginServiceInstance = await SetupLoginServiceAndPublicConfiguration(settings.authSetting)
    CreateAppRouter(router, loginServiceInstance)
    AddHttpInterceptor(loginServiceInstance, sessionId)

    const customTelemetries = await GenerateCutomsTelemetries(loginServiceInstance)
    const loggingServiceInstance = await SetupLoggerService(settings.applicationInsights?.connectionString as string, customTelemetries)

    const contextManagerInstance = new ContextManager(http, loggingServiceInstance)

    const _appStore = CreateAppStore()
    /* Period filter injection */
    const _httpProvider = CreateHttpProvider(_appStore, loginServiceInstance, sessionId)

    const _timelineHelper = CreateTimelineHelper(2, 2)
    const _periodFilterMapper = CreatePlanningFilterMapper()
    const _planningFilterHelper = CreatePlanningFilterHelper()
    const _periodFilterManager = CreatePlanningFilterManager(_httpProvider, _periodFilterMapper)
    /*  */
    const _entriesForMonthMapper = CreateEntriesForMonthMapper()
    const _entriesForMonthManager = CreateEntriesForMonthManager(_httpProvider, _entriesForMonthMapper)

    /*  */
    const _entriesForAssetMapper = CreateEntriesForAssetMapper(_timelineHelper)
    const _entriesForAssetManager = CreateEntriesForAssetManager(_httpProvider, _entriesForAssetMapper)

    /*  */
    const _entryMapper = CreateEntryMapper()
    const _entryManager = CreateEntryManager(_httpProvider, _entryMapper)

    /* Timeline factories  */

    LabelsManager.Manager.list()
    SettingsManager.fleetSettings()
    SettingsManager.filtersSettings()

    app.provide('entryManager', _entryManager)
    app.provide('planningFilterManager', _periodFilterManager)
    app.provide('planningFilterHelper', _planningFilterHelper)
    app.provide('entriesForMonthManager', _entriesForMonthManager)
    app.provide('entriesForAssetManager', _entriesForAssetManager)
    app.provide('timelineHelper', _timelineHelper)
    app.provide('appStore', _appStore)
    app.directive('focus', FocusDirective)
    app.directive('accordion', AccordionDirective)
    app.directive('keyboard', KeyboardDirective)
    app.directive('sticky', StickyDirective)
    app.use(store)
      .use(vuetify)
      .use(router)
      .use(i18n)
      .use(Toast, toastOptions)
      .provide('ILoginService', loginServiceInstance)
      .provide('ILogger', loggingServiceInstance)
      .provide(ContextManager.name, contextManagerInstance)
      .provide('GStore', GlobalStore)
      .mount('#app')
  })

/* =======    END of main setup    ========== */

async function RetrievePublicSettings () : Promise<PublicSettings> {
  const setting = await SettingsManager.getPublicSettings()
  if (setting.data === undefined) throw new Error('No application config retrieved.')
  return setting.data
}

async function SetupLoginServiceAndPublicConfiguration (settings : AuthenticationSettings): Promise<ILoginService> {
  if (process.env.VUE_APP_MSAL_ACTIVE === 'true') {
    const loginService = await loginServiceProvider.GetInstance(settings)
    try {
      await loginService.HandleUserAlreadyLogin()
    } catch (err) {
      console.error('handle reponse failed ! Exception: ' + err)
    }
    if (!await loginService.IsConnected()) {
      await loginService.SignIn()
    }
    return loginService
  } else {
    // set a fake login service when we use fake api
    const loginService = new FakeLoginService()
    return loginService
  }
}

async function SetupLoggerService (connectionString: string, customTelemetries: Record<string, string>) : Promise<ILogger> {
  const appInsights = new LoggerService(connectionString, customTelemetries)
  return appInsights
}

async function GenerateCutomsTelemetries (loginService: ILoginService) : Promise<Record<string, string>> {
  const customTelemetries: Record<string, string> = {}
  customTelemetries.sessionId = sessionId

  const userResponce = await UserManager.Manager.detail()
  if (userResponce.status === 403 || userResponce.data === undefined) {
    router.push('/unauthorized')
    return customTelemetries
  }
  const user = userResponce.data
  customTelemetries.userEmail = user.email
  if (loginService.AccountInfo) {
    customTelemetries.userOid = loginService.AccountInfo.idTokenClaims?.oid as string
  }
  // const contextResponse = await ContextManager.currentDepartment()
  // customTelemetries.context = contextResponse.data!
  return customTelemetries
}
