import { ConcessionModel, GetResult } from '@/map/core/bll/concession/concession.model'
import { ApiClient } from '@/map/core/dal/apiClient'
import { ConcessionMapper } from '@/map/core/bll/concession/concession.mapper'
import { canvas, polygon } from 'leaflet'
import { groupConcessionLayers } from '@/map/core/bll/concession/concession.group'

const renderer = canvas({ pane: 'concessionPane' })

const fetch = function (): Promise<ConcessionModel[]> {
  return new Promise((resolve, reject) => {
    ApiClient.list<GetResult>('map/area/concessions').then(result => {
      resolve(result.areas.reduce(function (list: ConcessionModel[], row) {
        const concession = ConcessionMapper(row) as ConcessionModel
        list.push(concession)
        groupConcessionLayers.addLayer(polygon(concession.multiPolygon, { color: 'white', weight: 1, dashArray: [4, 4], renderer }).bindTooltip(concession.name, { direction: 'top' }))
        return list
      }, []))
    }).catch((err:string) => {
      reject(err)
    })
  })
}

export default {
  fetch
}
