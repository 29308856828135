import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownChevronIcon = _resolveComponent("DownChevronIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "fixed z-1001 bottom-16 lg:bottom-5 right-5 p-2 rounded-full bg-bg-3 hidden",
    ref: "scrollTopButton",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToTop && _ctx.scrollToTop(...args)))
  }, [
    _createVNode(_component_DownChevronIcon, {
      class: "w-8 h-8 fill-text-alt",
      btt: true
    })
  ], 512))
}