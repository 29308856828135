import { BaseChartGenerator } from '@/core/charts/baseChart.service'
import { Content } from '@/core/charts/content.model'
import { IChartGenerator } from '@/core/charts/ichartgenerator.model'
import { SerieOptions } from '@/core/model/chart.model'
import ColoursService from '@/core/services/colours.service'

class BarAndLineChart extends BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return '' }

  /**
   * @override
  */
  protected createSeries (content: Content) : Partial<SerieOptions>[] {
    const series = []
    let categoryIndex = 0
    for (const category of content.categories) {
      for (const serie of category.series) {
        const values: number[] = []
        for (const label of content.xAxis) {
          values.push(parseFloat(serie.values.find(v => v.label === label)?.value ?? '0'))
        }
        const color = ColoursService.getColour(serie.name ?? '')
        series.push({
          name: serie.name ?? '',
          type: categoryIndex === 0 ? 'column' : 'spline',
          data: values,
          yAxis: categoryIndex,
          color
        })
      }
      categoryIndex++
    }
    return series
  }
}

export default new BarAndLineChart()
