
import CrossIcon from '@/components/icons/CrossIcon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CloseModal',
  components: {
    CrossIcon
  }
})
