
import { defineComponent, PropType } from 'vue'

interface MultiSelectOption {
  id: string | number;
  label: string;
}
export default defineComponent({
  props: {
    options: {
      type: Array as PropType<string[]>,
      required: true
    },
    default: {
      type: Object as PropType<MultiSelectOption>,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    multiple: {
      type: Boolean,
      default: false
    },
    multipleSimple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    }
  },
  data () {
    return {
      selected: '',
      open: false,
      selection: [] as string[]
    }
  },
  mounted () {
    this.emitSelection()
  },
  watch: {
    default: {
      handler () {
        this.selectOption(this.default)
      }
    }
  },
  methods: {
    selectOption (option: MultiSelectOption | string) {
      if (this.multiple && typeof option === 'string') {
        if (!this.selection.includes(option)) {
          this.selection.push(option)
          this.emitSelection()
        } else {
          const index = this.selection.findIndex(
            (element) => element === option
          )
          this.selection.splice(index, 1)
          this.emitSelection()
        }
      } else if (this.multipleSimple && typeof option === 'string') {
        if (!this.selection.includes(option)) {
          this.selection.push(option)
          this.emitSelection(true)
        } else {
          const index = this.selection.findIndex(
            (element) => element === option
          )
          this.selection.splice(index, 1)
          this.emitSelection(true)
        }
      } else {
        this.selected = (option as MultiSelectOption).label
        this.$emit('input', option as MultiSelectOption)
      }
    },
    emitSelection (simple = false) {
      if (simple) {
        this.$emit('selection', this.selection)
      } else {
        this.$emit('selection', { selection: this.selection })
      }
    }
  }
})
