export default class FormatService {
  public static formatNumber (number: number): string {
    let formattedNumber = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ')
    if (+formattedNumber.charAt(formattedNumber.length - 1) === 0 && +formattedNumber.charAt(formattedNumber.length - 2) === 0 && formattedNumber.charAt(formattedNumber.length - 3) === '.') {
      formattedNumber = formattedNumber.substring(0, formattedNumber.length - 3)
    }
    return formattedNumber
  }

  public static formatDate (date: string, minutes = false): string {
    if (date && !this.isValidDate(date)) {
      return date
    }
    if (date) {
      const day = new Date(date).toLocaleDateString('en-us', { day: '2-digit' })
      const month = new Date(date).toLocaleDateString('en-us', { month: 'short' })
      const year = new Date(date).toLocaleDateString('en-us', { year: '2-digit' })
      if (minutes) {
        const d = new Date(date)
        let hour = d.getHours().toString()
        if (+hour < 10) {
          hour = `0${hour}`
        }
        let minute = d.getMinutes().toString()
        if (+minute < 10) {
          minute = `0${minute}`
        }
        return `${month} ${day}, '${year} ${hour}:${minute}`
      }
      return `${month} ${day}, '${year}`
    }
    return ''
  }

  public static isValidDate (date: string): boolean {
    // eslint-disable-next-line eqeqeq
    if (date.toString() == parseInt(date).toString()) return false
    const tryDate = new Date(date)
    // eslint-disable-next-line eqeqeq
    return (tryDate && tryDate.toString() !== 'NaN' && tryDate.toString() != 'Invalid Date')
  }

  public static formatFileFormat (fileFormat: string): string {
    const knownFormats = ['txt', 'xlsx', 'xls', 'tsv', 'pdf', 'docx', 'doc', 'csv']
    if (knownFormats.includes(fileFormat)) {
      return `${fileFormat}.svg`
    }
    return 'blank.svg'
  }

  public static preciseNumberRound (value?: number, precision?: number): string|undefined {
    if (value || value === 0) {
      const multiplier = Math.pow(10, precision || 0)
      const roundedNumber = Math.round(value * multiplier) / multiplier
      return roundedNumber.toString()
    }
    return undefined
  }
}
