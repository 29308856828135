
import { AxiosError } from 'axios'
import { apiClient } from '@/core/axios.plugin'

export const ApiClient = {
  slice<T> (url:string, pageIndex: number, pageSize: number): Promise<{ slice : T, total : number }> {
    return new Promise((resolve, reject) => {
      apiClient.get(`${url}&indexOfCurrentPage=${pageIndex}&itemsPerPage=${pageSize}`).then((rep) => {
        resolve({ slice: (rep.data as T), total: parseInt(rep.headers['x-total-count']) || 0 })
      }).catch((err:AxiosError) => {
        reject(err.message)
      })
    })
  },
  list<T> (url:string): Promise<T> {
    return new Promise((resolve, reject) => {
      apiClient.get(url).then((rep) => {
        resolve((rep.data as T))
      }).catch((err:AxiosError) => {
        reject(err.message)
      })
    })
  },
  find<T> (url:string, id : string): Promise<T> {
    return new Promise((resolve, reject) => {
      apiClient.get(url + '/' + id).then((rep) => {
        resolve((rep.data as T))
      }).catch((err:AxiosError) => {
        reject(err.message)
      })
    })
  },
  post <T> (url: string, body: T): Promise<number> {
    return new Promise((resolve, reject) => {
      apiClient.post(url, body).then(rep => {
        resolve((rep.data as { newId: number}).newId)
      }).catch(err => {
        reject(err)
      })
    })
  },
  put <T> (url: string, body: T): Promise<void> {
    return new Promise((resolve, reject) => {
      apiClient.put(url, body).then(() => {
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },
  delete (url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      apiClient.delete(url)
        .then(() => resolve())
        .catch(err => {
          reject(err)
        })
    })
  }
}
